import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";
import ICON from "../../../../../assets/Images/corrupted-file.png";
import { cancelProcess, revertStageStep } from "@Services/support/candidate";
import { useState } from "react";
import Spinner from "@Atom/Spinner";

const RevertStageModal = ({
  id,
  onClose,
  setTrigger,
  isRevertStep = false,
  data,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  // console.log(data, "test");

  const handleNext = async () => {
    try {
      setIsLoading(true);
      isRevertStep
        ? await revertStageStep({
            candidateID: data?.data?.id,
            status: data?.step
              ? data?.step?.includes("Rekrutmen")
                ? "Rekrutmen"
                : data?.step?.includes("Verifikasi Pertama")
                ? "Verifikasi Pertama"
                : data?.step?.includes("Generate Kontrak")
                ? "Generate Kontrak"
                : data?.step?.includes("TTD Kontrak")
                ? "TTD Kontrak"
                : ""
              : "",
          })
        : await cancelProcess(id);
      setIsLoading(false);
      setTrigger(Math.random());
      onClose();
      setError("");
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setError(err?.response?.data?.error);
      // setTimeout(() => {
      //   setError("");
      // }, 3000);
    }
  };

  if (isRevertStep) {
    return (
      <div className={Styles.container}>
        <div className={Styles.iconWrapper}>
          <div className={Styles.iconQuestionMark}>
            <Icon
              icon={"Question-Mark-Yellow-2"}
              color={"#E9B62F"}
              size={141}
            />
          </div>
          <div className={Styles.text}>
            Apakah anda yakin ingin melakukan memundurkan karyawan atas nama{" "}
            <span>{data?.data?.name || "-"}</span> ke {data?.step || "-"}
          </div>
        </div>
        <div className={Styles.buttonSection}>
          {error && <span>{error}</span>}
          <div className={Styles.buttons}>
            <div
              className={`${Styles.secondaryButton} ${
                isLoading && Styles.disabled
              }`}
              onClick={() => {
                if (!isLoading) {
                  onClose();
                  setError("");
                }
              }}
            >
              <div className={Styles.text}>Batal</div>
            </div>
            <div
              className={`${Styles.primaryButton} ${
                isLoading && Styles.disabled
              }`}
              onClick={isLoading ? null : handleNext}
            >
              {isLoading ? <Spinner /> : <div className={Styles.text}>Ya</div>}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.title}>Batalkan Proses</div>
        <Icon
          size="24"
          icon="cross"
          className={Styles.icon}
          onClick={() => {
            if (!isLoading) {
              onClose();
              setError("");
            }
          }}
        />
      </div>
      <div className={Styles.iconWrapper}>
        <img src={ICON} alt="" height={120} width={120} />
        <div className={Styles.text}>
          Membatalkan proses akan menghentikan seluruh proses yang dilakukan
          pada proses rekrutmen kandidat, aksi ini tidak dapat dibatalkan dan
          kandidat harus melamar ulang bila ingin diproses kembali
        </div>
      </div>
      <div className={Styles.buttonSection}>
        {error && <span>{error}</span>}
        <div className={Styles.buttons}>
          <div
            className={`${Styles.secondaryButton} ${
              isLoading && Styles.disabled
            }`}
            onClick={() => {
              if (!isLoading) {
                onClose();
                setError("");
              }
            }}
          >
            <div className={Styles.text}>Batal</div>
          </div>
          <div
            className={`${Styles.primaryButton} ${
              isLoading && Styles.disabled
            }`}
            onClick={isLoading ? null : handleNext}
          >
            {isLoading ? <Spinner /> : <div className={Styles.text}>Ya</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevertStageModal;
