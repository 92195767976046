/* eslint-disable no-unused-vars */
import convertHtmlToPdf from "@Helpers/convertHtmlToPdf";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PdfView from "./ContractView";
import SignContract from "./SignContract";
import Styles from "./style.module.scss";
import { isMobile } from "react-device-detect";
import SignPreview from "./SignPreview";
import { signContract, signContractExtend } from "@Services/contract/contract";
import SuccessSign from "./SuccessSign";
import ApprovedContract from "./ApprovedContract";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import LocationContract from "./LocationContract";
import { getAddress } from "@Hooks/getAddress";
import useWindowSize from "@Hooks/useWindowSize";
import DesktopMode from "./DesktopMode";
import OnProgress from "./Onprogress";
import {
  getCandidateExtendSync,
  getCandidateSync,
  sendOTP,
} from "@Services/officer/recruitment";
import useQuery from "@Hooks/useQuery";
import ModalWrapper from "@Atom/ModalWrapper";
import OTPConfirmationModal from "@Molecule/_modal/OTPConfirmationModal";
import LoadingModal from "@Molecule/_modal/LoadingModal";
import { allFilledObjChecker } from "@Helpers/allFilledObjChecker";
import { validateEmail } from "@Helpers/validateEmail";
import { fileBaseUrl, fileBaseUrlC2C } from "@Config/api";

const PDFJSCV = require("pdfjs-dist/webpack");

const PDFJS = window.pdfjsLib;

export default function CandidateContractLayout({ data }) {
  const [dataToSend, setDataToSend] = useState({
    longitude: 0,
    latitude: 0,
    signature: "",
    initials: "",
    image: "",
    longitudeImage: 0,
    latitudeImage: 0,
    addressImage: "",
    imagesIdCard: "",
    longitudeImageIdCard: 0,
    latitudeImageIdCard: 0,
    addressImageIdCard: "",
    // longitudeSubmit: 0,
    // latitudeSubmit: 0,
    // addressSubmit: "",
  });
  const [pdf, setPdf] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [images, setImages] = useState([]);
  const getStep = sessionStorage?.getItem("stepContractCandidate") || 1;
  const [step, setStep] = useState(+getStep || 1);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const query = useQuery();
  const { width } = useWindowSize();
  const getCurrentStep =
    sessionStorage?.getItem("currentStepContractCandidate") || 1;
  const [currentStep, setCurrentStep] = useState(+getCurrentStep || 1);
  const [isLoadingMap, setIsLoadingMap] = useState(false);
  const [isLoadingDataSync, setIsLoadingDataSync] = useState(false);
  const [syncData, setSyncData] = useState(null);
  const [isLoadingCandidateExtendSync, setIsLoadingCandidateExtendSync] =
    useState(false);
  const [syncCandidateExtend, setSyncCandidateExtend] = useState(null);
  const { hash } = useParams();
  const getVerifyPhone = sessionStorage?.getItem(`isPhoneVerify_${hash}`);
  const [isPhoneVerified, setIsPhoneVerified] = useState(
    getVerifyPhone ? (getVerifyPhone === "true" ? true : false) : true
  );
  const [loadingPhoneVerify, setLoadingPhoneVerify] = useState(false);
  const hasInitialized = useRef(false);

  const isFetched = JSON.parse(sessionStorage.getItem(`isFetched`) || "false");

  const defaultStepOne = JSON.parse(
    sessionStorage.getItem(`personalData_${hash}`)
  );

  const getValue = (defaultVal, syncVal, fallback = "") =>
    defaultVal ?? (syncVal?.toLowerCase() !== "null" ? syncVal : fallback);

  const parseMaritalStatus = (status) => {
    const statuses = {
      TK: "Tidak Kawin",
      K0: "Kawin (0 anak)",
      K1: "Kawin (1 anak)",
      K2: "Kawin (2 anak)",
      K3: "Kawin (>= 3 anak)",
    };
    return statuses[status] ?? (status?.toLowerCase() !== "null" ? status : "");
  };

  const parseFamilyMember = (members, index, key) =>
    members?.filter((el) => el?.address !== null)[index]?.[key] || "";

  const [dataStepOne, setDataStepone] = useState({
    name: getValue(
      defaultStepOne?.name,
      syncCandidateExtend?.candidate?.name?.toUpperCase()
    ),
    birthPlace: getValue(
      defaultStepOne?.birthPlace,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.placeOfBirth
    ),
    gender: getValue(
      defaultStepOne?.gender,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.gender
    ),
    religion: getValue(
      defaultStepOne?.religion,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.religion
    ),
    bloodType: getValue(
      defaultStepOne?.bloodType,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.bloodType
    ),
    birthDateTemp: getValue(
      defaultStepOne?.birthDateTemp,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.dateOfBirth
    ),
    height: getValue(
      defaultStepOne?.height,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.bodyHeight
    ),
    weight: getValue(
      defaultStepOne?.weight,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.bodyWeight
    ),
    maritalStatus: parseMaritalStatus(
      defaultStepOne?.maritalStatus ||
        syncCandidateExtend?.candidate?.CandidateComprehensive?.maritalStatus
    ),
    KK: getValue(
      defaultStepOne?.KK,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.familyId
    ),
    NIK: getValue(
      defaultStepOne?.NIK,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.personId
    ),
    lifetimeIdCard:
      defaultStepOne?.lifetimeIdCard ||
      syncCandidateExtend?.candidate?.CandidateComprehensive?.personIdPeriod ===
        "SEUMUR HIDUP"
        ? true
        : false || false,
    idCardPeriodTemp: getValue(
      defaultStepOne?.idCardPeriodTemp,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.personIdPeriod,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.personIdPeriod ===
        "SEUMUR HIDUP"
        ? ""
        : ""
    ),
    noTaxNumber:
      defaultStepOne?.noTaxNumber ||
      syncCandidateExtend?.candidate?.CandidateComprehensive?.taxId ===
        "TIDAK MEMILIKI NPWP"
        ? true
        : false || false,
    taxNumber: getValue(
      defaultStepOne?.taxNumber,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.taxId,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.taxId ===
        "TIDAK MEMILIKI NPWP"
        ? ""
        : ""
    ),
    email: getValue(
      defaultStepOne?.email,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.email
        ?.toLowerCase()
        ?.trim()
    ),
    phoneNumber: getValue(
      defaultStepOne?.phoneNumber,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.phoneNumber?.slice(
        2
      )
    ),
    biologicalMotherName: getValue(
      defaultStepOne?.biologicalMotherName,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.motherName
    ),
    idCardAddress: getValue(
      defaultStepOne?.idCardAddress,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.address
    ),
    idCardRt: getValue(
      defaultStepOne?.idCardRt,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.neighborhood?.split(
        "/"
      )[0]
    ),
    idCardRw: getValue(
      defaultStepOne?.idCardRw,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.neighborhood?.split(
        "/"
      )[1]
    ),
    idCardWard: getValue(
      defaultStepOne?.idCardWard,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.subDistrict
    ),
    idCardSubdistrict: getValue(
      defaultStepOne?.idCardSubdistrict,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.district
    ),

    idCardRegencyTemp: getValue(
      defaultStepOne?.idCardRegencyTemp,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.city
    ),

    idCardProvinceTemp: getValue(
      defaultStepOne?.idCardProvinceTemp,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.province
    ),

    idCardPostal: getValue(
      defaultStepOne?.idCardPostal,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.postal
    ),
    isDomicileTheSame: defaultStepOne?.isDomicileTheSame || false,
    domicileAddress: getValue(
      defaultStepOne?.domicileAddress,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.domicileAddress
    ),
    domicileRt: getValue(
      defaultStepOne?.domicileRt,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.domicileNeighbourhood?.split(
        "/"
      )[0]
    ),
    domicileRw: getValue(
      defaultStepOne?.domicileRw,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.domicileNeighbourhood?.split(
        "/"
      )[1]
    ),

    domicileWard: getValue(
      defaultStepOne?.domicileWard,
      syncCandidateExtend?.candidate?.CandidateComprehensive
        ?.domicileSubDistrict
    ),

    domicileSubdistrict: getValue(
      defaultStepOne?.domicileSubdistrict,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.domicileDistrict
    ),

    domicileRegencyTemp: getValue(
      defaultStepOne?.domicileRegencyTemp,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.domicileCity
    ),
    domicileProvinceTemp: getValue(
      defaultStepOne?.domicileProvinceTemp,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.domicileProvince
    ),
    domicilePostal: getValue(
      defaultStepOne?.domicilePostal,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.domicilePostal
    ),
    educationTemp: getValue(
      defaultStepOne?.educationTemp,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.lastEducationType
    ),
    institution: getValue(
      defaultStepOne?.institution,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.lastEducationName
    ),
    faculty: getValue(
      defaultStepOne?.faculty,
      syncCandidateExtend?.candidate?.CandidateComprehensive
        ?.lastEducationFaculty
    ),
    gpa: getValue(
      defaultStepOne?.gpa,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.lastEducationGrade
    ),
    emergencyContactName: getValue(
      defaultStepOne?.emergencyContactName,
      syncCandidateExtend?.candidate?.CandidateComprehensive
        ?.emergencyContactName
    ),
    emergencyContactRelationshipTemp: getValue(
      defaultStepOne?.emergencyContactRelationshipTemp,
      syncCandidateExtend?.candidate?.CandidateComprehensive
        ?.emergencyContactRelation
    ),
    emergencyPhoneNumber: getValue(
      defaultStepOne?.emergencyPhoneNumber,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.emergencyContactNumber?.slice(
        2
      )
    ),
    emergencyAddress: getValue(
      defaultStepOne?.emergencyAddress,
      syncCandidateExtend?.candidate?.CandidateComprehensive
        ?.emergencyContactAddress
    ),
    sameHouseFamilyContactName: defaultStepOne?.sameHouseFamilyContactName
      ? defaultStepOne?.sameHouseFamilyContactName
      : parseFamilyMember(
          syncCandidateExtend?.candidate?.CandidateFamilyMembers,
          1,
          "name"
        ) &&
        parseFamilyMember(
          syncCandidateExtend?.candidate?.CandidateFamilyMembers,
          1,
          "name"
        )?.toLowerCase() !== "null"
      ? parseFamilyMember(
          syncCandidateExtend?.candidate?.CandidateFamilyMembers,
          1,
          "name"
        )
      : "",
    sameHouseFamilyContactRelationshipTemp:
      defaultStepOne?.sameHouseFamilyContactRelationshipTemp
        ? defaultStepOne?.sameHouseFamilyContactRelationshipTemp
        : parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            1,
            "relation"
          ) &&
          parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            1,
            "relation"
          )?.toLowerCase() !== "null"
        ? parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            1,
            "relation"
          )
        : "",
    sameHouseFamilyContactPhoneNumber:
      defaultStepOne?.sameHouseFamilyContactPhoneNumber
        ? defaultStepOne?.sameHouseFamilyContactPhoneNumber
        : parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            1,
            "phoneNumber"
          ) &&
          parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            1,
            "phoneNumber"
          )?.toLowerCase() !== "null"
        ? parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            1,
            "phoneNumber"
          )?.slice(2)
        : "",
    sameHouseFamilyContactAddress: defaultStepOne?.sameHouseFamilyContactAddress
      ? defaultStepOne?.sameHouseFamilyContactAddress
      : parseFamilyMember(
          syncCandidateExtend?.candidate?.CandidateFamilyMembers,
          1,
          "address"
        ) &&
        parseFamilyMember(
          syncCandidateExtend?.candidate?.CandidateFamilyMembers,
          1,
          "address"
        )?.toLowerCase() !== "null"
      ? parseFamilyMember(
          syncCandidateExtend?.candidate?.CandidateFamilyMembers,
          1,
          "address"
        )
      : "",
    differentHouseFamilyContactName:
      defaultStepOne?.differentHouseFamilyContactName
        ? defaultStepOne?.differentHouseFamilyContactName
        : parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            2,
            "name"
          ) &&
          parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            2,
            "name"
          )?.toLowerCase() !== "null"
        ? parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            2,
            "name"
          )
        : "",
    differentHouseFamilyContactRelationshipTemp:
      defaultStepOne?.differentHouseFamilyContactRelationshipTemp
        ? defaultStepOne?.differentHouseFamilyContactRelationshipTemp
        : parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            2,
            "relation"
          ) &&
          parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            2,
            "relation"
          )?.toLowerCase() !== "null"
        ? parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            2,
            "relation"
          )
        : "",
    differentHouseFamilyContactPhoneNumber:
      defaultStepOne?.differentHouseFamilyContactPhoneNumber
        ? defaultStepOne?.differentHouseFamilyContactPhoneNumber
        : parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            2,
            "phoneNumber"
          ) &&
          parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            2,
            "phoneNumber"
          )?.toLowerCase() !== "null"
        ? parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            2,
            "phoneNumber"
          )?.slice(2)
        : "",
    differentHouseFamilyContactAddress:
      defaultStepOne?.differentHouseFamilyContactAddress
        ? defaultStepOne?.differentHouseFamilyContactAddress
        : parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            2,
            "address"
          ) &&
          parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            2,
            "address"
          )?.toLowerCase() !== "null"
        ? parseFamilyMember(
            syncCandidateExtend?.candidate?.CandidateFamilyMembers,
            2,
            "address"
          )
        : "",
    bankNameTemp: getValue(
      defaultStepOne?.bankNameTemp,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.BankName
    ),
    bankAccountNumber: getValue(
      defaultStepOne?.bankAccountNumber,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.BankNumber
    ),
    roCode: defaultStepOne?.roCode || "-",
    nearestHealthFacility: getValue(
      defaultStepOne?.nearestHealthFacility,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.nearestClinic
    ),
    fbUsername: getValue(
      defaultStepOne?.fbUsername,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.facebook
    ),
    igUsername: getValue(
      defaultStepOne?.igUsername,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.instagram
    ),
    twitterUsername: getValue(
      defaultStepOne?.twitterUsername,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.twitter
    ),
    linkedinUsername: getValue(
      defaultStepOne?.linkedinUsername,
      syncCandidateExtend?.candidate?.CandidateComprehensive?.linkedin
    ),
  });

  // console.log(syncCandidateExtend, "tets", dataStepOne);

  const defaultFamilyMembers = JSON.parse(
    sessionStorage.getItem(`familyMembers_${hash}`)
  );
  const [familyMembers, setFamilyMembers] = useState(
    defaultFamilyMembers || []
  );
  useEffect(() => {
    switch (dataStepOne?.maritalStatus?.toLowerCase()) {
      case "tidak kawin":
        setFamilyMembers([
          {
            id: Math.random(),
            name: dataStepOne?.name,
            birthPlace: dataStepOne?.birthPlace,
            birthDate: dataStepOne?.birthDateTemp,
            nik: dataStepOne?.NIK,
            gender: dataStepOne?.gender,
            isSelf: true,
          },
        ]);
        break;
      case "kawin (0 anak)":
        setFamilyMembers([
          {
            id: Math.random(),
            name: dataStepOne?.name,
            birthPlace: dataStepOne?.birthPlace,
            birthDate: dataStepOne?.birthDateTemp,
            nik: dataStepOne?.NIK,
            gender: dataStepOne?.gender,
            isSelf: true,
          },
          {
            id:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )?.map((_, index) => index)[1] || Math.random()
                : Math.random(),
            relationship:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.relation
                : null,
            name:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.name
                : null,
            birthPlace:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.placeOfBirth
                : null,
            birthDate:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.dateOfBirth
                : null,
            nik:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.nik
                : null,
            gender:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.gender
                : null,
          },
        ]);
        break;
      case "kawin (1 anak)":
        setFamilyMembers([
          {
            id: Math.random(),
            name: dataStepOne?.name,
            birthPlace: dataStepOne?.birthPlace,
            birthDate: dataStepOne?.birthDateTemp,
            nik: dataStepOne?.NIK,
            gender: dataStepOne?.gender,
            isSelf: true,
          },
          {
            id:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )?.map((_, index) => index)[1] || Math.random()
                : Math.random(),
            relationship:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.relation
                : null,
            name:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.name
                : null,
            birthPlace:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.placeOfBirth
                : null,
            birthDate:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.dateOfBirth
                : null,
            nik:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.nik
                : null,
            gender:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.gender
                : null,
          },
          {
            id:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )?.map((_, index) => index)[2] || Math.random()
                : Math.random(),
            relationship:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.relation
                : null,
            name:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.name
                : null,
            birthPlace:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.placeOfBirth
                : null,
            birthDate:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.dateOfBirth
                : null,
            nik:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.nik
                : null,
            gender:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.gender
                : null,
          },
        ]);
        break;
      case "kawin (2 anak)":
        setFamilyMembers([
          {
            id: Math.random(),
            name: dataStepOne?.name,
            birthPlace: dataStepOne?.birthPlace,
            birthDate: dataStepOne?.birthDateTemp,
            nik: dataStepOne?.NIK,
            gender: dataStepOne?.gender,
            isSelf: true,
          },
          {
            id:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )?.map((_, index) => index)[1] || Math.random()
                : Math.random(),
            relationship:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.relation
                : null,
            name:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.name
                : null,
            birthPlace:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.placeOfBirth
                : null,
            birthDate:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.dateOfBirth
                : null,
            nik:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.nik
                : null,
            gender:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.gender
                : null,
          },
          {
            id:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )?.map((_, index) => index)[2] || Math.random()
                : Math.random(),
            relationship:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.relation
                : null,
            name:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.name
                : null,
            birthPlace:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.placeOfBirth
                : null,
            birthDate:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.dateOfBirth
                : null,
            nik:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.nik
                : null,
            gender:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.gender
                : null,
          },
          {
            id:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )?.map((_, index) => index)[3] || Math.random()
                : Math.random(),
            relationship:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[3]?.relation
                : null,
            name:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[3]?.name
                : null,
            birthPlace:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[3]?.placeOfBirth
                : null,
            birthDate:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[3]?.dateOfBirth
                : null,
            nik:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[3]?.nik
                : null,
            gender:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[3]?.gender
                : null,
          },
        ]);
        break;
      case "kawin (>= 3 anak)":
        setFamilyMembers([
          {
            id: Math.random(),
            name: dataStepOne?.name,
            birthPlace: dataStepOne?.birthPlace,
            birthDate: dataStepOne?.birthDateTemp,
            nik: dataStepOne?.NIK,
            gender: dataStepOne?.gender,
            isSelf: true,
          },
          {
            id:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )?.map((_, index) => index)[1] || Math.random()
                : Math.random(),
            relationship:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.relation
                : null,
            name:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.name
                : null,
            birthPlace:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.placeOfBirth
                : null,
            birthDate:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.dateOfBirth
                : null,
            nik:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.nik
                : null,
            gender:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[1]?.gender
                : null,
          },
          {
            id:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )?.map((_, index) => index)[2] || Math.random()
                : Math.random(),
            relationship:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.relation
                : null,
            name:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.name
                : null,
            birthPlace:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.placeOfBirth
                : null,
            birthDate:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.dateOfBirth
                : null,
            nik:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.nik
                : null,
            gender:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[2]?.gender
                : null,
          },
          {
            id:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )?.map((_, index) => index)[3] || Math.random()
                : Math.random(),
            relationship:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[3]?.relation
                : null,
            name:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[3]?.name
                : null,
            birthPlace:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[3]?.placeOfBirth
                : null,
            birthDate:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[3]?.dateOfBirth
                : null,
            nik:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[3]?.nik
                : null,
            gender:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[3]?.gender
                : null,
          },
          {
            id:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )?.map((_, index) => index)[4] || Math.random()
                : Math.random(),
            relationship:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[4]?.relation
                : null,
            name:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[4]?.name
                : null,
            birthPlace:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[4]?.placeOfBirth
                : null,
            birthDate:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[4]?.dateOfBirth
                : null,
            nik:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[4]?.nik
                : null,
            gender:
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.length >
                0 &&
              syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                (el) => el?.address === null
              )?.length > 0
                ? syncCandidateExtend?.candidate?.CandidateFamilyMembers?.filter(
                    (el) => el?.address === null
                  )[4]?.gender
                : null,
          },
        ]);
        break;
      default:
        return;
    }
  }, [
    // syncCandidateExtend?.candidate?.CandidateFamilyMembers,
    dataStepOne?.birthDateTemp,
    dataStepOne?.birthPlace,
    dataStepOne?.gender,
    dataStepOne?.maritalStatus,
    dataStepOne?.name,
    dataStepOne?.NIK,
    syncCandidateExtend,
  ]);

  const defaultEducationHistory = JSON.parse(
    sessionStorage.getItem(`educationHistory_${hash}`)
  );

  const [educationHistory, setEducationHistory] = useState([]);

  const defaultJobHistory = JSON.parse(
    sessionStorage.getItem(`jobHistory_${hash}`)
  );
  const [jobHistory, setJobHistory] = useState([]);

  const defaultPerformanceHistory = JSON.parse(
    sessionStorage.getItem(`performanceHistory_${hash}`)
  );
  const [performanceHistory, setPerformanceHistory] = useState([]);

  const [imagesCV, setImagesCV] = useState();

  const doc = syncCandidateExtend?.candidate?.CandidateDocuments?.find(
    (d) => d?.type === "CV"
  );

  const docURL = useMemo(() => {
    if (!doc || !doc?.url) return undefined;

    const isC2CSource = doc?.source?.toLowerCase() === "c2c";
    const baseUrl = isC2CSource ? fileBaseUrlC2C : fileBaseUrl;

    return baseUrl + doc.url;
  }, [doc, fileBaseUrlC2C, fileBaseUrl]);

  useEffect(() => {
    if (docURL) {
      const loadDefaultImages = async () => {
        const imagesArr = [];
        if (docURL === undefined) {
          imagesArr.push(null);
        } else {
          const uri = docURL;
          var pdf = await PDFJSCV.getDocument({ url: uri }).promise;
          const canvas = document.createElement("canvas");
          for (let i = 0; i < pdf.numPages; i++) {
            const page = await pdf.getPage(i + 1);
            const viewport = page.getViewport({ scale: 1 });
            const context = canvas.getContext("2d");
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            await page.render({ canvasContext: context, viewport: viewport })
              .promise;
            imagesArr.push(canvas.toDataURL());
          }
          canvas.remove();
        }

        setImagesCV(imagesArr[0]);
      };
      loadDefaultImages();
    } else {
      setImagesCV(null);
    }
  }, [docURL]);

  const docSelfPhoto = syncCandidateExtend?.candidate?.CandidateDocuments?.find(
    (d) => ["SELF PICTURE", "FOTO SELFIE"].includes(d?.type)
  );

  const docURLSelfPhoto = useMemo(() => {
    if (!docSelfPhoto || !docSelfPhoto?.url) return undefined;

    const baseUrl =
      docSelfPhoto?.source?.toLowerCase() === "c2c"
        ? fileBaseUrlC2C
        : fileBaseUrl;

    return baseUrl + docSelfPhoto.url;
  }, [docSelfPhoto, fileBaseUrlC2C, fileBaseUrl]);

  const docPersonID = syncCandidateExtend?.candidate?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "ktp"
  );

  const docURLPersonID = useMemo(() => {
    if (!docPersonID || !docPersonID?.url) return undefined;

    const baseUrl =
      docPersonID?.source?.toLowerCase() === "c2c"
        ? fileBaseUrlC2C
        : fileBaseUrl;

    return baseUrl + docPersonID.url;
  }, [docPersonID, fileBaseUrlC2C, fileBaseUrl]);

  const docLamaran = syncCandidateExtend?.candidate?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "surat lamaran"
  );

  const docURLLamaran = useMemo(() => {
    if (!docLamaran || !docLamaran?.url) return undefined;

    const baseUrl =
      docLamaran?.source?.toLowerCase() === "c2c"
        ? fileBaseUrlC2C
        : fileBaseUrl;

    return baseUrl + docLamaran.url;
  }, [docLamaran, fileBaseUrlC2C, fileBaseUrl]);

  const docCerificate =
    syncCandidateExtend?.candidate?.CandidateDocuments?.find(
      (d) => d?.type?.toLowerCase() === "ijazah"
    );

  const docURLCerificate = useMemo(() => {
    if (!docCerificate || !docCerificate?.url) return undefined;

    const baseUrl =
      docCerificate?.source?.toLowerCase() === "c2c"
        ? fileBaseUrlC2C
        : fileBaseUrl;

    return baseUrl + docCerificate.url;
  }, [docCerificate, fileBaseUrlC2C, fileBaseUrl]);

  const docTranskipNilai =
    syncCandidateExtend?.candidate?.CandidateDocuments?.find(
      (d) => d?.type?.toLowerCase() === "transkrip nilai"
    );

  const docURLTranskipNilai = useMemo(() => {
    if (!docTranskipNilai || !docTranskipNilai?.url) return undefined;

    const baseUrl =
      docTranskipNilai?.source?.toLowerCase() === "c2c"
        ? fileBaseUrlC2C
        : fileBaseUrl;

    return baseUrl + docTranskipNilai.url;
  }, [docTranskipNilai, fileBaseUrlC2C, fileBaseUrl]);

  const docKK = syncCandidateExtend?.candidate?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "kartu keluarga"
  );

  const docURLKK = useMemo(() => {
    if (!docKK || !docKK?.url) return undefined;

    const baseUrl =
      docKK?.source?.toLowerCase() === "c2c" ? fileBaseUrlC2C : fileBaseUrl;

    return baseUrl + docKK.url;
  }, [docKK, fileBaseUrlC2C, fileBaseUrl]);

  const docRekening = syncCandidateExtend?.candidate?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "rekening"
  );

  const docURLRekening = useMemo(() => {
    if (!docRekening || !docRekening?.url) return undefined;

    const baseUrl =
      docRekening?.source?.toLowerCase() === "c2c"
        ? fileBaseUrlC2C
        : fileBaseUrl;

    return baseUrl + docRekening.url;
  }, [docRekening, fileBaseUrlC2C, fileBaseUrl]);

  const docNPWP = syncCandidateExtend?.candidate?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "npwp"
  );

  const docURLNPWP = useMemo(() => {
    if (!docNPWP || !docNPWP?.url) return undefined;

    const baseUrl =
      docNPWP?.source?.toLowerCase() === "c2c" ? fileBaseUrlC2C : fileBaseUrl;

    return baseUrl + docNPWP.url;
  }, [docNPWP, fileBaseUrlC2C, fileBaseUrl]);

  const docJamsostek = syncCandidateExtend?.candidate?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "jamsostek"
  );

  const docURLJamsostek = useMemo(() => {
    if (!docJamsostek || !docJamsostek?.url) return undefined;

    const baseUrl =
      docJamsostek?.source?.toLowerCase() === "c2c"
        ? fileBaseUrlC2C
        : fileBaseUrl;

    return baseUrl + docJamsostek.url;
  }, [docJamsostek, fileBaseUrlC2C, fileBaseUrl]);

  const docReferensi = syncCandidateExtend?.candidate?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "surat referensi kerja"
  );

  const docURLReferensi = useMemo(() => {
    if (!docReferensi || !docReferensi?.url) return undefined;

    const baseUrl =
      docReferensi?.source?.toLowerCase() === "c2c"
        ? fileBaseUrlC2C
        : fileBaseUrl;

    return baseUrl + docReferensi.url;
  }, [docReferensi, fileBaseUrlC2C, fileBaseUrl]);

  const docNikah = syncCandidateExtend?.candidate?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "surat nikah"
  );

  const docURLNikah = useMemo(() => {
    if (!docNikah || !docNikah?.url) return undefined;

    const baseUrl =
      docNikah?.source?.toLowerCase() === "c2c" ? fileBaseUrlC2C : fileBaseUrl;

    return baseUrl + docNikah.url;
  }, [docNikah, fileBaseUrlC2C, fileBaseUrl]);

  const docAkta = syncCandidateExtend?.candidate?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "akta lahir"
  );

  const docURLAkta = useMemo(() => {
    if (!docAkta || !docAkta?.url) return undefined;

    const baseUrl =
      docAkta?.source?.toLowerCase() === "c2c" ? fileBaseUrlC2C : fileBaseUrl;

    return baseUrl + docAkta.url;
  }, [docAkta, fileBaseUrlC2C, fileBaseUrl]);

  const docRestuFamily =
    syncCandidateExtend?.candidate?.CandidateDocuments?.find(
      (d) => d?.type?.toLowerCase() === "surat persetujuan keluarga"
    );

  const docURLRestuFamily = useMemo(() => {
    if (!docRestuFamily || !docRestuFamily?.url) return undefined;

    const baseUrl =
      docRestuFamily?.source?.toLowerCase() === "c2c"
        ? fileBaseUrlC2C
        : fileBaseUrl;

    return baseUrl + docRestuFamily.url;
  }, [docRestuFamily, fileBaseUrlC2C, fileBaseUrl]);

  const docSKCK = syncCandidateExtend?.candidate?.CandidateDocuments?.find(
    (d) => d?.type?.toLowerCase() === "skck"
  );

  const docURLSKCK = useMemo(() => {
    if (!docSKCK || !docSKCK?.url) return undefined;

    const baseUrl =
      docSKCK?.source?.toLowerCase() === "c2c" ? fileBaseUrlC2C : fileBaseUrl;

    return baseUrl + docSKCK.url;
  }, [docSKCK, fileBaseUrlC2C, fileBaseUrl]);

  const docSuratSehat =
    syncCandidateExtend?.candidate?.CandidateDocuments?.find(
      (d) => d?.type?.toLowerCase() === "surat keterangan berbadan sehat"
    );

  const docURLSuratSehat = useMemo(() => {
    if (!docSuratSehat || !docSuratSehat?.url) return undefined;

    const baseUrl =
      docSuratSehat?.source?.toLowerCase() === "c2c"
        ? fileBaseUrlC2C
        : fileBaseUrl;

    return baseUrl + docSuratSehat.url;
  }, [docSuratSehat, fileBaseUrlC2C, fileBaseUrl]);

  const [documents, setDocuments] = useState({
    mandatory: [],
    nonMandatory: [],
    additional: [],
  });

  const refetchDocuments = useCallback(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (
      data?.response?.candidateContract?.contractType?.toLowerCase() === "pkwt"
    ) {
      if (dataStepOne?.noTaxNumber) {
        setDocuments((prev) => {
          return {
            ...prev,
            nonMandatory: [
              {
                title: "Jamsostek",
                fileType: "jpg",
                file: null,
                preview: docURLJamsostek ? docURLJamsostek : null,
                name: docJamsostek ? docJamsostek?.url : null,
              },
              {
                title: "Surat Referensi Kerja",
                fileType: "jpg",
                file: null,
                preview: docURLReferensi ? docURLReferensi : null,
                name: docReferensi ? docReferensi?.url : null,
              },
              {
                title: "Surat Nikah",
                fileType: "jpg",
                file: null,
                preview: docURLNikah ? docURLNikah : null,
                name: docNikah ? docNikah?.url : null,
              },
              {
                title: "Akta Lahir",
                fileType: "jpg",
                file: null,
                preview: docURLAkta ? docURLAkta : null,
                name: docAkta ? docAkta?.url : null,
              },
              {
                title: "Surat Persetujuan Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLRestuFamily ? docURLRestuFamily : null,
                name: docRestuFamily ? docRestuFamily?.url : null,
              },
              {
                title: "SKCK",
                fileType: "jpg",
                file: null,
                preview: docURLSKCK ? docURLSKCK : null,
                name: docSKCK ? docSKCK?.url : null,
              },
              {
                title: "Surat Keterangan Berbadan Sehat",
                fileType: "jpg",
                file: null,
                preview: docURLSuratSehat ? docURLSuratSehat : null,
                name: docSuratSehat ? docSuratSehat?.url : null,
              },
            ],
            mandatory: [
              {
                title: "CV",
                fileType: "pdf",
                file: null,
                preview: docURL ? imagesCV : null,
                name: doc ? doc.url : null,
              },
              {
                title: "Foto Selfie",
                fileType: "jpg",
                file: null,
                preview: docURLSelfPhoto ? docURLSelfPhoto : null,
                name: docSelfPhoto ? docSelfPhoto?.url : null,
              },
              {
                title: "Surat Lamaran",
                fileType: "jpg",
                file: null,
                preview: docURLLamaran ? docURLLamaran : null,
                name: docLamaran ? docLamaran?.url : null,
              },
              {
                title: "KTP",
                fileType: "jpg",
                file: null,
                preview: docURLPersonID ? docURLPersonID : null,
                name: docPersonID ? docPersonID?.url : null,
              },
              {
                title: "Ijazah",
                fileType: "jpg",
                file: null,
                preview: docURLCerificate ? docURLCerificate : null,
                name: docCerificate ? docCerificate?.url : null,
              },
              {
                title: "Transkrip Nilai",
                fileType: "jpg",
                file: null,
                preview: docURLTranskipNilai ? docURLTranskipNilai : null,
                name: docTranskipNilai ? docTranskipNilai?.url : null,
              },
              {
                title: "Kartu Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLKK ? docURLKK : null,
                name: docKK ? docKK?.url : null,
              },
              {
                title: "Rekening",
                fileType: "jpg",
                file: null,
                preview: docURLRekening ? docURLRekening : null,
                name: docRekening ? docRekening?.url : null,
              },
            ],
          };
        });
      } else if (!dataStepOne?.noTaxNumber) {
        setDocuments((prev) => {
          return {
            ...prev,
            nonMandatory: [
              {
                title: "Jamsostek",
                fileType: "jpg",
                file: null,
                preview: docURLJamsostek ? docURLJamsostek : null,
                name: docJamsostek ? docJamsostek?.url : null,
              },
              {
                title: "Surat Referensi Kerja",
                fileType: "jpg",
                file: null,
                preview: docURLReferensi ? docURLReferensi : null,
                name: docReferensi ? docReferensi?.url : null,
              },
              {
                title: "Surat Nikah",
                fileType: "jpg",
                file: null,
                preview: docURLNikah ? docURLNikah : null,
                name: docNikah ? docNikah?.url : null,
              },
              {
                title: "Akta Lahir",
                fileType: "jpg",
                file: null,
                preview: docURLAkta ? docURLAkta : null,
                name: docAkta ? docAkta?.url : null,
              },
              {
                title: "Surat Persetujuan Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLRestuFamily ? docURLRestuFamily : null,
                name: docRestuFamily ? docRestuFamily?.url : null,
              },
              {
                title: "SKCK",
                fileType: "jpg",
                file: null,
                preview: docURLSKCK ? docURLSKCK : null,
                name: docSKCK ? docSKCK?.url : null,
              },
              {
                title: "Surat Keterangan Berbadan Sehat",
                fileType: "jpg",
                file: null,
                preview: docURLSuratSehat ? docURLSuratSehat : null,
                name: docSuratSehat ? docSuratSehat?.url : null,
              },
            ],
            mandatory: [
              {
                title: "CV",
                fileType: "pdf",
                file: null,
                preview: docURL ? imagesCV : null,
                name: doc ? doc.url : null,
              },
              {
                title: "Foto Selfie",
                fileType: "jpg",
                file: null,
                preview: docURLSelfPhoto ? docURLSelfPhoto : null,
                name: docSelfPhoto ? docSelfPhoto?.url : null,
              },
              {
                title: "Surat Lamaran",
                fileType: "jpg",
                file: null,
                preview: docURLLamaran ? docURLLamaran : null,
                name: docLamaran ? docLamaran?.url : null,
              },
              {
                title: "KTP",
                fileType: "jpg",
                file: null,
                preview: docURLPersonID ? docURLPersonID : null,
                name: docPersonID ? docPersonID?.url : null,
              },
              {
                title: "Ijazah",
                fileType: "jpg",
                file: null,
                preview: docURLCerificate ? docURLCerificate : null,
                name: docCerificate ? docCerificate?.url : null,
              },
              {
                title: "Transkrip Nilai",
                fileType: "jpg",
                file: null,
                preview: docURLTranskipNilai ? docURLTranskipNilai : null,
                name: docTranskipNilai ? docTranskipNilai?.url : null,
              },
              {
                title: "Kartu Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLKK ? docURLKK : null,
                name: docKK ? docKK?.url : null,
              },
              {
                title: "Rekening",
                fileType: "jpg",
                file: null,
                preview: docURLRekening ? docURLRekening : null,
                name: docRekening ? docRekening?.url : null,
              },
              {
                title: "NPWP",
                fileType: "jpg",
                file: null,
                preview: docURLNPWP ? docURLNPWP : null,
                name: docNPWP ? docNPWP?.url : null,
              },
            ],
          };
        });
      }
    } else if (
      data?.response?.candidateContract?.contractType?.toLowerCase() === "pkm"
    ) {
      if (dataStepOne?.noTaxNumber) {
        setDocuments((prev) => {
          return {
            ...prev,
            nonMandatory: [
              {
                title: "Jamsostek",
                fileType: "jpg",
                file: null,
                preview: docURLJamsostek ? docURLJamsostek : null,
                name: docJamsostek ? docJamsostek?.url : null,
              },
              {
                title: "Surat Referensi Kerja",
                fileType: "jpg",
                file: null,
                preview: docURLReferensi ? docURLReferensi : null,
                name: docReferensi ? docReferensi?.url : null,
              },
              {
                title: "Surat Nikah",
                fileType: "jpg",
                file: null,
                preview: docURLNikah ? docURLNikah : null,
                name: docNikah ? docNikah?.url : null,
              },
              {
                title: "Akta Lahir",
                fileType: "jpg",
                file: null,
                preview: docURLAkta ? docURLAkta : null,
                name: docAkta ? docAkta?.url : null,
              },
              {
                title: "Surat Persetujuan Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLRestuFamily ? docURLRestuFamily : null,
                name: docRestuFamily ? docRestuFamily?.url : null,
              },
              {
                title: "SKCK",
                fileType: "jpg",
                file: null,
                preview: docURLSKCK ? docURLSKCK : null,
                name: docSKCK ? docSKCK?.url : null,
              },
              {
                title: "Surat Keterangan Berbadan Sehat",
                fileType: "jpg",
                file: null,
                preview: docURLSuratSehat ? docURLSuratSehat : null,
                name: docSuratSehat ? docSuratSehat?.url : null,
              },
            ],
            mandatory: [
              {
                title: "CV",
                fileType: "pdf",
                file: null,
                preview: docURL ? imagesCV : null,
                name: doc ? doc.url : null,
              },
              {
                title: "Foto Selfie",
                fileType: "jpg",
                file: null,
                preview: docURLSelfPhoto ? docURLSelfPhoto : null,
                name: docSelfPhoto ? docSelfPhoto?.url : null,
              },
              {
                title: "KTP",
                fileType: "jpg",
                file: null,
                preview: docURLPersonID ? docURLPersonID : null,
                name: docPersonID ? docPersonID?.url : null,
              },
              {
                title: "Kartu Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLKK ? docURLKK : null,
                name: docKK ? docKK?.url : null,
              },
              {
                title: "Rekening",
                fileType: "jpg",
                file: null,
                preview: docURLRekening ? docURLRekening : null,
                name: docRekening ? docRekening?.url : null,
              },
            ],
          };
        });
      } else if (!dataStepOne?.noTaxNumber) {
        setDocuments((prev) => {
          return {
            ...prev,
            nonMandatory: [
              {
                title: "Jamsostek",
                fileType: "jpg",
                file: null,
                preview: docURLJamsostek ? docURLJamsostek : null,
                name: docJamsostek ? docJamsostek?.url : null,
              },
              {
                title: "Surat Referensi Kerja",
                fileType: "jpg",
                file: null,
                preview: docURLReferensi ? docURLReferensi : null,
                name: docReferensi ? docReferensi?.url : null,
              },
              {
                title: "Surat Nikah",
                fileType: "jpg",
                file: null,
                preview: docURLNikah ? docURLNikah : null,
                name: docNikah ? docNikah?.url : null,
              },
              {
                title: "Akta Lahir",
                fileType: "jpg",
                file: null,
                preview: docURLAkta ? docURLAkta : null,
                name: docAkta ? docAkta?.url : null,
              },
              {
                title: "Surat Persetujuan Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLRestuFamily ? docURLRestuFamily : null,
                name: docRestuFamily ? docRestuFamily?.url : null,
              },
              {
                title: "SKCK",
                fileType: "jpg",
                file: null,
                preview: docURLSKCK ? docURLSKCK : null,
                name: docSKCK ? docSKCK?.url : null,
              },
              {
                title: "Surat Keterangan Berbadan Sehat",
                fileType: "jpg",
                file: null,
                preview: docURLSuratSehat ? docURLSuratSehat : null,
                name: docSuratSehat ? docSuratSehat?.url : null,
              },
            ],
            mandatory: [
              {
                title: "CV",
                fileType: "pdf",
                file: null,
                preview: docURL ? imagesCV : null,
                name: doc ? doc.url : null,
              },
              {
                title: "Foto Selfie",
                fileType: "jpg",
                file: null,
                preview: docURLSelfPhoto ? docURLSelfPhoto : null,
                name: docSelfPhoto ? docSelfPhoto?.url : null,
              },
              {
                title: "KTP",
                fileType: "jpg",
                file: null,
                preview: docURLPersonID ? docURLPersonID : null,
                name: docPersonID ? docPersonID?.url : null,
              },
              {
                title: "Kartu Keluarga",
                fileType: "jpg",
                file: null,
                preview: docURLKK ? docURLKK : null,
                name: docKK ? docKK?.url : null,
              },
              {
                title: "Rekening",
                fileType: "jpg",
                file: null,
                preview: docURLRekening ? docURLRekening : null,
                name: docRekening ? docRekening?.url : null,
              },
              {
                title: "NPWP",
                fileType: "jpg",
                file: null,
                preview: docURLNPWP ? docURLNPWP : null,
                name: docNPWP ? docNPWP?.url : null,
              },
            ],
          };
        });
      }
    }
  }, [
    dataStepOne,
    data,

    imagesCV,
    // docURLJamsostek,
    // docJamsostek,
    // docURL,
    // docURLReferensi,
    // docURLNikah,
    // docURLAkta,
    // docURLRestuFamily,
    // docURLSKCK,
    // docURLSuratSehat,
    // docSelfPhoto,
    // docURLLamaran,
    // docURLPersonID,
    // docURLCerificate,
    // docURLTranskipNilai,
    // docURLKK,
    // docURLRekening,
    // docURLNPWP,
  ]);

  useEffect(() => {
    if (
      data?.response?.candidate?.employeementStatus?.toLowerCase() === "extend"
    ) {
      refetchDocuments();
    }
  }, [refetchDocuments, data]);

  useEffect(() => {
    if (syncCandidateExtend && !hasInitialized.current) {
      const dataDefault = {
        name: getValue(
          defaultStepOne?.name,
          syncCandidateExtend?.candidate?.name?.toUpperCase()
        ),
        birthPlace: getValue(
          defaultStepOne?.birthPlace,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.placeOfBirth
        ),
        gender: getValue(
          defaultStepOne?.gender,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.gender
        ),
        religion: getValue(
          defaultStepOne?.religion,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.religion
        ),
        bloodType: getValue(
          defaultStepOne?.bloodType,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.bloodType
        ),
        birthDateTemp: getValue(
          defaultStepOne?.birthDateTemp,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.dateOfBirth
        ),
        height: getValue(
          defaultStepOne?.height,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.bodyHeight
        ),
        weight: getValue(
          defaultStepOne?.weight,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.bodyWeight
        ),
        maritalStatus: parseMaritalStatus(
          defaultStepOne?.maritalStatus ||
            syncCandidateExtend?.candidate?.CandidateComprehensive
              ?.maritalStatus
        ),
        KK: getValue(
          defaultStepOne?.KK,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.familyId
        ),
        NIK: getValue(
          defaultStepOne?.NIK,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.personId
        ),
        lifetimeIdCard:
          defaultStepOne?.lifetimeIdCard ||
          syncCandidateExtend?.candidate?.CandidateComprehensive
            ?.personIdPeriod === "SEUMUR HIDUP"
            ? true
            : false || false,
        idCardPeriodTemp: getValue(
          defaultStepOne?.idCardPeriodTemp,
          syncCandidateExtend?.candidate?.CandidateComprehensive
            ?.personIdPeriod,
          syncCandidateExtend?.candidate?.CandidateComprehensive
            ?.personIdPeriod === "SEUMUR HIDUP"
            ? ""
            : ""
        ),
        noTaxNumber:
          defaultStepOne?.noTaxNumber ||
          syncCandidateExtend?.candidate?.CandidateComprehensive?.taxId ===
            "TIDAK MEMILIKI NPWP"
            ? true
            : false || false,
        taxNumber: getValue(
          defaultStepOne?.taxNumber,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.taxId,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.taxId ===
            "TIDAK MEMILIKI NPWP"
            ? ""
            : ""
        ),
        email: getValue(
          defaultStepOne?.email,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.email
            ?.toLowerCase()
            ?.trim()
        ),
        phoneNumber: getValue(
          defaultStepOne?.phoneNumber,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.phoneNumber?.slice(
            2
          )
        ),
        biologicalMotherName: getValue(
          defaultStepOne?.biologicalMotherName,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.motherName
        ),
        idCardAddress: getValue(
          defaultStepOne?.idCardAddress,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.address
        ),
        idCardRt: getValue(
          defaultStepOne?.idCardRt,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.neighborhood?.split(
            "/"
          )[0]
        ),
        idCardRw: getValue(
          defaultStepOne?.idCardRw,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.neighborhood?.split(
            "/"
          )[1]
        ),
        idCardWard: getValue(
          defaultStepOne?.idCardWard,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.subDistrict
        ),
        idCardSubdistrict: getValue(
          defaultStepOne?.idCardSubdistrict,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.district
        ),

        idCardRegencyTemp: getValue(
          defaultStepOne?.idCardRegencyTemp,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.city
        ),

        idCardProvinceTemp: getValue(
          defaultStepOne?.idCardProvinceTemp,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.province
        ),

        idCardPostal: getValue(
          defaultStepOne?.idCardPostal,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.postal
        ),
        isDomicileTheSame: defaultStepOne?.isDomicileTheSame || false,
        domicileAddress: getValue(
          defaultStepOne?.domicileAddress,
          syncCandidateExtend?.candidate?.CandidateComprehensive
            ?.domicileAddress
        ),
        domicileRt: getValue(
          defaultStepOne?.domicileRt,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.domicileNeighbourhood?.split(
            "/"
          )[0]
        ),
        domicileRw: getValue(
          defaultStepOne?.domicileRw,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.domicileNeighbourhood?.split(
            "/"
          )[1]
        ),

        domicileWard: getValue(
          defaultStepOne?.domicileWard,
          syncCandidateExtend?.candidate?.CandidateComprehensive
            ?.domicileSubDistrict
        ),

        domicileSubdistrict: getValue(
          defaultStepOne?.domicileSubdistrict,
          syncCandidateExtend?.candidate?.CandidateComprehensive
            ?.domicileDistrict
        ),

        domicileRegencyTemp: getValue(
          defaultStepOne?.domicileRegencyTemp,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.domicileCity
        ),
        domicileProvinceTemp: getValue(
          defaultStepOne?.domicileProvinceTemp,
          syncCandidateExtend?.candidate?.CandidateComprehensive
            ?.domicileProvince
        ),
        domicilePostal: getValue(
          defaultStepOne?.domicilePostal,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.domicilePostal
        ),
        educationTemp: getValue(
          defaultStepOne?.educationTemp,
          syncCandidateExtend?.candidate?.CandidateComprehensive
            ?.lastEducationType
        ),
        institution: getValue(
          defaultStepOne?.institution,
          syncCandidateExtend?.candidate?.CandidateComprehensive
            ?.lastEducationName
        ),
        faculty: getValue(
          defaultStepOne?.faculty,
          syncCandidateExtend?.candidate?.CandidateComprehensive
            ?.lastEducationFaculty
        ),
        gpa: getValue(
          defaultStepOne?.gpa,
          syncCandidateExtend?.candidate?.CandidateComprehensive
            ?.lastEducationGrade
        ),
        emergencyContactName: getValue(
          defaultStepOne?.emergencyContactName,
          syncCandidateExtend?.candidate?.CandidateComprehensive
            ?.emergencyContactName
        ),
        emergencyContactRelationshipTemp: getValue(
          defaultStepOne?.emergencyContactRelationshipTemp,
          syncCandidateExtend?.candidate?.CandidateComprehensive
            ?.emergencyContactRelation
        ),
        emergencyPhoneNumber: getValue(
          defaultStepOne?.emergencyPhoneNumber,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.emergencyContactNumber?.slice(
            2
          )
        ),
        emergencyAddress: getValue(
          defaultStepOne?.emergencyAddress,
          syncCandidateExtend?.candidate?.CandidateComprehensive
            ?.emergencyContactAddress
        ),
        sameHouseFamilyContactName: defaultStepOne?.sameHouseFamilyContactName
          ? defaultStepOne?.sameHouseFamilyContactName
          : parseFamilyMember(
              syncCandidateExtend?.candidate?.CandidateFamilyMembers,
              1,
              "name"
            ) &&
            parseFamilyMember(
              syncCandidateExtend?.candidate?.CandidateFamilyMembers,
              1,
              "name"
            )?.toLowerCase() !== "null"
          ? parseFamilyMember(
              syncCandidateExtend?.candidate?.CandidateFamilyMembers,
              1,
              "name"
            )
          : "",
        sameHouseFamilyContactRelationshipTemp:
          defaultStepOne?.sameHouseFamilyContactRelationshipTemp
            ? defaultStepOne?.sameHouseFamilyContactRelationshipTemp
            : parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                1,
                "relation"
              ) &&
              parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                1,
                "relation"
              )?.toLowerCase() !== "null"
            ? parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                1,
                "relation"
              )
            : "",
        sameHouseFamilyContactPhoneNumber:
          defaultStepOne?.sameHouseFamilyContactPhoneNumber
            ? defaultStepOne?.sameHouseFamilyContactPhoneNumber
            : parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                1,
                "phoneNumber"
              ) &&
              parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                1,
                "phoneNumber"
              )?.toLowerCase() !== "null"
            ? parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                1,
                "phoneNumber"
              )?.slice(2)
            : "",
        sameHouseFamilyContactAddress:
          defaultStepOne?.sameHouseFamilyContactAddress
            ? defaultStepOne?.sameHouseFamilyContactAddress
            : parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                1,
                "address"
              ) &&
              parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                1,
                "address"
              )?.toLowerCase() !== "null"
            ? parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                1,
                "address"
              )
            : "",
        differentHouseFamilyContactName:
          defaultStepOne?.differentHouseFamilyContactName
            ? defaultStepOne?.differentHouseFamilyContactName
            : parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                2,
                "name"
              ) &&
              parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                2,
                "name"
              )?.toLowerCase() !== "null"
            ? parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                2,
                "name"
              )
            : "",
        differentHouseFamilyContactRelationshipTemp:
          defaultStepOne?.differentHouseFamilyContactRelationshipTemp
            ? defaultStepOne?.differentHouseFamilyContactRelationshipTemp
            : parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                2,
                "relation"
              ) &&
              parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                2,
                "relation"
              )?.toLowerCase() !== "null"
            ? parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                2,
                "relation"
              )
            : "",
        differentHouseFamilyContactPhoneNumber:
          defaultStepOne?.differentHouseFamilyContactPhoneNumber
            ? defaultStepOne?.differentHouseFamilyContactPhoneNumber
            : parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                2,
                "phoneNumber"
              ) &&
              parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                2,
                "phoneNumber"
              )?.toLowerCase() !== "null"
            ? parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                2,
                "phoneNumber"
              )?.slice(2)
            : "",
        differentHouseFamilyContactAddress:
          defaultStepOne?.differentHouseFamilyContactAddress
            ? defaultStepOne?.differentHouseFamilyContactAddress
            : parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                2,
                "address"
              ) &&
              parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                2,
                "address"
              )?.toLowerCase() !== "null"
            ? parseFamilyMember(
                syncCandidateExtend?.candidate?.CandidateFamilyMembers,
                2,
                "address"
              )
            : "",
        bankNameTemp: getValue(
          defaultStepOne?.bankNameTemp,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.BankName
        ),
        bankAccountNumber: getValue(
          defaultStepOne?.bankAccountNumber,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.BankNumber
        ),
        roCode: defaultStepOne?.roCode || "-",
        nearestHealthFacility: getValue(
          defaultStepOne?.nearestHealthFacility,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.nearestClinic
        ),
        fbUsername: getValue(
          defaultStepOne?.fbUsername,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.facebook
        ),
        igUsername: getValue(
          defaultStepOne?.igUsername,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.instagram
        ),
        twitterUsername: getValue(
          defaultStepOne?.twitterUsername,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.twitter
        ),
        linkedinUsername: getValue(
          defaultStepOne?.linkedinUsername,
          syncCandidateExtend?.candidate?.CandidateComprehensive?.linkedin
        ),
      };

      setDataStepone(dataDefault);
      hasInitialized.current = true; // Mark initialization as done
    }
  }, [
    syncCandidateExtend,
    defaultStepOne,
    getValue,
    parseMaritalStatus,
    parseFamilyMember,
  ]);

  useEffect(() => {
    if (!isFetched && syncCandidateExtend) {
      console.log("true");
      setEducationHistory(syncCandidateExtend?.candidate?.CandidateEducations);
      setJobHistory(
        syncCandidateExtend?.candidate?.CandidateWorkExperiences?.filter(
          (el) =>
            el?.startYear ||
            el?.endYear ||
            el?.companyName ||
            el?.position ||
            el?.description ||
            el?.remark
        )
      );
      setPerformanceHistory(
        syncCandidateExtend?.candidate?.CandidateAchievements?.filter(
          (el) => el?.year || el?.achievement || el?.remark
        )
      );
      sessionStorage.setItem("isFetched", true);
    } else {
      setEducationHistory(defaultEducationHistory);
      setJobHistory(defaultJobHistory);
      setPerformanceHistory(defaultPerformanceHistory);
    }
  }, [syncCandidateExtend]);

  const disableInput = useMemo(() => {
    return dataStepOne?.NIK?.length !== 16 || !isPhoneVerified;
  }, [dataStepOne, isPhoneVerified]);

  const disablePhoneVerification = useMemo(() => {
    return dataStepOne.NIK?.length !== 16;
  }, [dataStepOne]);

  const [errorVerify, setErrorsetErrorVerify] = useState("");
  const [showModalOTP, setShowModalOTP] = useState(null);

  const onClickPhoneVerification = useCallback(async () => {
    try {
      setLoadingPhoneVerify(true);
      const { response } = await sendOTP("62" + dataStepOne?.phoneNumber);
      // navigate(`${pathname}?h=${response?.hash}&v=true`, { replace: true });
      setShowModalOTP(response?.hash);
      setLoadingPhoneVerify(false);
    } catch (error) {
      console.log("in error", error);
      const errorMessage =
        error?.response?.data?.error || "something went wrong";
      setErrorsetErrorVerify(errorMessage);
      setLoadingPhoneVerify(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, dataStepOne?.phoneNumber]);

  const handlePhoneVerification = () => {
    setLoadingPhoneVerify(false);
    setIsPhoneVerified(true);
    // navigate(`${pathname}?h=${query.get("h")}&v=false`, {
    //   replace: true,
    // });
    setShowModalOTP(null);
  };

  const refetchDataSync = useCallback(async () => {
    try {
      setIsLoadingDataSync(true);
      const { response } = await getCandidateSync();
      setSyncData(response);
      setIsLoadingDataSync(false);
    } catch (error) {
      setIsLoadingDataSync(false);
      console.log(error);
    }
  }, []);

  const refetchDataExtendSync = useCallback(async () => {
    try {
      setIsLoadingCandidateExtendSync(true);
      const { response } = await getCandidateExtendSync(hash);
      setSyncCandidateExtend(response);
      setIsLoadingCandidateExtendSync(false);
    } catch (error) {
      setIsLoadingCandidateExtendSync(false);
      console.log(error);
    }
  }, [hash]);

  useEffect(() => {
    if (
      data?.response?.candidate?.employeementStatus?.toLowerCase() === "extend"
    ) {
      refetchDataSync();
      refetchDataExtendSync();
    }
  }, [data, refetchDataSync, refetchDataExtendSync]);

  useEffect(() => {
    sessionStorage.setItem("stepContractCandidate", step || 1);
    sessionStorage.setItem("currentStepContractCandidate", currentStep || 1);
    sessionStorage.setItem(`personalData_${hash}`, JSON.stringify(dataStepOne));
    sessionStorage.setItem(`isPhoneVerify_${hash}`, isPhoneVerified);
    sessionStorage.setItem(
      `familyMembers_${hash}`,
      JSON.stringify(familyMembers)
    );
    sessionStorage.setItem(
      `educationHistory_${hash}`,
      JSON.stringify(educationHistory)
    );
    sessionStorage.setItem(`jobHistory_${hash}`, JSON.stringify(jobHistory));
    sessionStorage.setItem(
      `performanceHistory_${hash}`,
      JSON.stringify(performanceHistory)
    );
  }, [
    step,
    currentStep,
    dataStepOne,
    hash,
    isPhoneVerified,
    familyMembers,
    educationHistory,
    jobHistory,
    performanceHistory,
  ]);

  useEffect(() => {
    if (step === 4) {
      sessionStorage?.removeItem("stepContractCandidate");
      if (
        data?.response?.candidate?.employeementStatus?.toLowerCase() ===
        "extend"
      ) {
        sessionStorage.removeItem("currentStepContractCandidate");
      }
    }
  }, [step, data]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setDataToSend((prev) => ({
        ...prev,
        latitude: position.coords.latitude,
        longitude: position.coords.longitude,
      }));
    });
  }, []);

  const handleSubmitSign = async () => {
    try {
      setIsSubmitting(true);

      navigator.geolocation.getCurrentPosition(async (position) => {
        try {
          const updatedLatitude = position.coords.latitude;
          const updatedLongitude = position.coords.longitude;

          // Fetch the address based on the new location
          const addressResult = await getAddress(
            updatedLatitude,
            updatedLongitude,
            setIsLoadingMap
          );

          const updatedDataToSend = {
            ...dataToSend,
            latitudeSubmit: updatedLatitude,
            longitudeSubmit: updatedLongitude,
            addressSubmit: addressResult,
            dataStepOne: dataStepOne || {},
            familyMembers: familyMembers || [],
            educationHistory: educationHistory || [],
            jobHistory: jobHistory || [],
            performanceHistory: performanceHistory || [],
            documents: documents,
            positionID: syncCandidateExtend?.candidate?.positionID || "",
            recruiterID: syncCandidateExtend?.candidate?.recruiterID || "",
            jobPostingID: syncCandidateExtend?.candidate?.jobPostingID || "",
          };

          if (
            updatedDataToSend?.latitudeSubmit !== 0 &&
            updatedDataToSend?.longitudeSubmit !== 0 &&
            updatedDataToSend?.addressSubmit
          ) {
            await signContractAndNavigate(updatedDataToSend);
          }
        } catch (error) {
          console.error(error);
        }
      });
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const signContractAndNavigate = async (updatedDataToSend) => {
    try {
      const res =
        data?.response?.candidate?.employeementStatus?.toLowerCase() ===
        "extend"
          ? await signContractExtend(
              data.response.candidateContract.id,
              updatedDataToSend
            )
          : await signContract(
              data.response.candidateContract.id,
              updatedDataToSend
            );
      if (res.response.candidate.tag === "Accepted") {
        navigate(".", { replace: true });
        setStep(5);
      }

      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
      setError(error?.response?.data?.error);
    }
  };

  useEffect(() => {
    if (
      data.response.candidate.stage === "SIGN-CONTRACT" &&
      data.response.candidate.tag === "Accepted"
    ) {
      setStep(5);
    }
    if (
      data.response.candidate.stage === "DONE" ||
      data.response.candidate.stage === "IOS-SUCCESS"
    ) {
      setStep(6);
    }
    // console.log(data.response.candidate.stage === "SIGN-CONTRACT");
  }, [data]);

  useEffect(() => {
    const getPdfUrl = async () => {
      const convertedHtml = await convertHtmlToPdf(
        data.response.candidateContract?.template,
        "contract",
        {
          ...data.response.candidate,
          ...data.response.candidateContract,
          // ...dataToSend,
          // initials:

          imageLongitude: dataToSend?.longitudeImage || 0,
          imageLatitude: dataToSend?.latitudeImage || 0,
          imageAddress: dataToSend?.addressImage || "",
          longitude: dataToSend?.longitudeSubmit || 0,
          latitude: dataToSend?.latitudeSubmit || 0,
          address: dataToSend?.addressSubmit || "",
          identityCardLatitude: dataToSend?.latitudeImageIdCard || 0,
          identityCardLongitude: dataToSend?.longitudeImageIdCard || 0,
          identityCardAddress: dataToSend?.addressImageIdCard || "",
          position: data.response?.expectedPosition?.name,
          contractType: data.response?.expectedPosition?.contractType,
          image: dataToSend?.image
            ? URL.createObjectURL(dataToSend?.image)
            : "",
          initials: dataToSend?.initials ? dataToSend?.initials : "",
          signature: dataToSend?.signature ? dataToSend?.signature : "",
          identityCardImage: dataToSend?.imagesIdCard
            ? URL.createObjectURL(dataToSend?.imagesIdCard)
            : "",
        }
      );
      setPdfUrl(convertedHtml);
    };
    getPdfUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.response.candidateContract?.template, step, dataToSend]);

  async function showPdf(url) {
    try {
      var _PDF_DOC = await PDFJS.getDocument({
        url: url,
      });
      setPdf(_PDF_DOC);
    } catch (error) {
      alert(error.message);
    }
  }

  useEffect(() => {
    isMobile && pdf && renderPage();
    // eslint-disable-next-line
  }, [pdf]);

  useEffect(() => {
    isMobile && pdfUrl && showPdf(pdfUrl);
  }, [pdfUrl]);

  async function renderPage() {
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");
    // let canv = document.querySelector(".canv");

    for (let i = 1; i <= pdf.numPages; i++) {
      var page = await pdf.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };

      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages(imagesList);
  }

  const isDoneChecker = useMemo(() => {
    return {
      first: () => {
        const newDataStepOne = { ...dataStepOne };
        delete newDataStepOne?.fbUsername;
        delete newDataStepOne?.igUsername;
        delete newDataStepOne?.twitterUsername;
        delete newDataStepOne?.linkedinUsername;
        delete newDataStepOne?.idCardPeriodTemp;
        delete newDataStepOne?.taxNumber;
        delete newDataStepOne?.isDomicileTheSame;
        delete newDataStepOne?.domicileAddress;
        delete newDataStepOne?.domicileRt;
        delete newDataStepOne?.domicileRw;
        delete newDataStepOne?.domicileWard;
        delete newDataStepOne?.domicileSubdistrict;
        delete newDataStepOne?.domicileRegencyTemp;
        delete newDataStepOne?.domicileProvinceTemp;
        delete newDataStepOne?.domicilePostal;
        delete newDataStepOne?.noTaxNumber;
        delete newDataStepOne?.lifetimeIdCard;

        const domiciles = {
          domicileAddress: dataStepOne?.domicileAddress,
          domicileRt: dataStepOne?.domicileRt,
          domicileRw: dataStepOne?.domicileRw,
          domicileWard: dataStepOne?.domicileWard,
          domicileSubdistrict: dataStepOne?.domicileSubdistrict,
          domicileRegencyTemp: dataStepOne?.domicileRegencyTemp,
          domicileProvinceTemp: dataStepOne?.domicileProvinceTemp,
          domicilePostal: dataStepOne?.domicilePostal,
        };

        if (
          !allFilledObjChecker(newDataStepOne) ||
          !(dataStepOne?.idCardPeriodTemp || dataStepOne?.lifetimeIdCard) ||
          !(dataStepOne?.taxNumber || dataStepOne?.noTaxNumber) ||
          dataStepOne?.NIK?.length < 16 ||
          dataStepOne?.KK?.length < 16 ||
          !isPhoneVerified ||
          !validateEmail(dataStepOne?.email) ||
          !(dataStepOne?.isDomicileTheSame || allFilledObjChecker(domiciles))
        ) {
          return false;
        }
        return true;
      },
      second: () => {
        if (familyMembers?.length) {
          if (familyMembers?.length > 1) {
            for (let i = 0; i < familyMembers.length; i++) {
              if (!allFilledObjChecker(familyMembers[i])) {
                return false;
              }
            }
          } else {
            return true;
          }

          return true;
        } else {
          return false;
        }
      },
      third: () => {
        if (educationHistory?.length) {
          for (let i = 0; i < educationHistory.length; i++) {
            if (!allFilledObjChecker(educationHistory[i])) {
              return false;
            }
          }
          return true;
        } else {
          return false;
        }
      },
      fourth: () => {
        if (jobHistory?.length) {
          for (let i = 0; i < jobHistory.length; i++) {
            if (!jobHistory[i].description) {
              delete jobHistory[i].description;
            }
            if (!jobHistory[i].startMonth) {
              delete jobHistory[i].startMonth;
            }
            if (!jobHistory[i].endMonth) {
              delete jobHistory[i].endMonth;
            }
            if (!allFilledObjChecker(jobHistory[i])) {
              return false;
            }
          }
          return true;
        } else {
          if (currentStep >= 4) {
            return true;
          } else {
            return false;
          }
        }
      },
      fifth: () => {
        if (performanceHistory?.length) {
          for (let i = 0; i < performanceHistory.length; i++) {
            if (!allFilledObjChecker(performanceHistory[i])) {
              return false;
            }
          }
          return true;
        } else {
          if (currentStep >= 5) {
            return true;
          } else {
            return false;
          }
        }
      },
      sixth: () => {
        const filtered = documents?.mandatory?.filter(
          (obj) => obj?.title !== "CV"
        );
        for (let i = 0; i < filtered?.length; i++) {
          const doc = filtered[i];
          if (!doc?.preview) {
            return false;
          }
        }
        const filteredCV = documents?.mandatory?.filter(
          (obj) => obj?.title === "CV"
        );

        for (let i = 0; i < filteredCV?.length; i++) {
          const doc = filteredCV[i];
          if (!doc?.name) {
            return false;
          }
        }
        return true;
      },
      seventh: () => {
        if (
          dataToSend.image === "" ||
          dataToSend.longitude === 0 ||
          dataToSend.latitude === 0 ||
          dataToSend.initials === "" ||
          dataToSend.signature === "" ||
          dataToSend?.longitudeImage === 0 ||
          dataToSend?.latitudeImage === 0 ||
          dataToSend?.imagesIdCard === "" ||
          dataToSend?.longitudeImageIdCard === 0 ||
          dataToSend?.latitudeImageIdCard === 0 ||
          dataToSend?.addressImage === "" ||
          dataToSend?.addressImageIdCard === ""
        ) {
          return false;
        }
        return true;
      },
    };
  }, [
    dataToSend,
    dataStepOne,
    isPhoneVerified,
    familyMembers,
    educationHistory,
    jobHistory,
    currentStep,
    performanceHistory,
    documents,
  ]);

  return data?.response?.candidate?.employeementStatus?.toLowerCase() ===
    "extend" ? (
    <div
      className={`${Styles.Container} ${step > 2 && Styles.ContainerExtend}`}
    >
      {step === 1 ? (
        <PdfView pdf={pdfUrl} images={images} setStep={setStep} data={data} />
      ) : step === 2 ? (
        <LocationContract state={dataToSend} setStep={setStep} />
      ) : step === 3 ? (
        <OnProgress
          isDoneChecker={isDoneChecker}
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          setStep={setStep}
          isLoadingDataSync={isLoadingDataSync}
          syncData={syncData}
          dataStepOne={dataStepOne}
          setDataStepone={setDataStepone}
          disableInput={disableInput}
          isPhoneVerified={isPhoneVerified}
          setIsPhoneVerified={setIsPhoneVerified}
          loadingPhoneVerify={loadingPhoneVerify}
          onClickPhoneVerification={onClickPhoneVerification}
          disablePhoneVerification={disablePhoneVerification}
          familyMembers={familyMembers}
          setFamilyMembers={setFamilyMembers}
          educationHistory={educationHistory}
          setEducationHistory={setEducationHistory}
          jobHistory={jobHistory}
          setJobHistory={setJobHistory}
          performanceHistory={performanceHistory}
          setPerformanceHistory={setPerformanceHistory}
          setDocuments={setDocuments}
          documents={documents}
          dataToSend={dataToSend}
          isLoadingCandidateExtendSync={isLoadingCandidateExtendSync}
          setDataToSend={setDataToSend}
        />
      ) : step === 4 ? (
        <SignPreview
          documents={documents}
          jobHistory={jobHistory}
          educationHistory={educationHistory}
          familyMembers={familyMembers}
          performanceHistory={performanceHistory}
          data={dataToSend}
          setStep={setStep}
          pdf={pdfUrl}
          images={images}
          handleSubmit={handleSubmitSign}
          isSubmitting={isSubmitting}
          error={error}
          dataStepOne={dataStepOne}
          isExtend={
            data?.response?.candidate?.employeementStatus?.toLowerCase() ===
            "extend"
          }
        />
      ) : step === 5 ? (
        <SuccessSign data={data.response} pdf={pdfUrl} />
      ) : (
        <ApprovedContract data={data.response} pdf={pdfUrl} />
      )}
      {showModalOTP ? (
        <ModalWrapper show={showModalOTP} forceCenter>
          <div
            style={{
              visibility: showModalOTP ? "visible" : "hidden",
            }}
          >
            <OTPConfirmationModal
              handleSubmit={handlePhoneVerification}
              data={dataStepOne}
              errorApi={errorVerify}
              loading={loadingPhoneVerify}
              hashID={showModalOTP}
            />
          </div>
        </ModalWrapper>
      ) : (
        loadingPhoneVerify && <LoadingModal />
      )}
    </div>
  ) : width > 768 ? (
    <div className={Styles.Container}>
      <DesktopMode />
    </div>
  ) : (
    <div className={Styles.Container}>
      {step === 1 && (
        <PdfView pdf={pdfUrl} images={images} setStep={setStep} data={data} />
      )}
      {step === 2 && <LocationContract state={dataToSend} setStep={setStep} />}
      {step === 3 && (
        <SignContract
          state={dataToSend}
          setState={setDataToSend}
          setStep={setStep}
        />
      )}
      {step === 4 && (
        <SignPreview
          data={dataToSend}
          setStep={setStep}
          pdf={pdfUrl}
          images={images}
          handleSubmit={handleSubmitSign}
          isSubmitting={isSubmitting}
          error={error}
        />
      )}
      {step === 5 && <SuccessSign data={data.response} pdf={pdfUrl} />}
      {step === 6 && <ApprovedContract data={data.response} pdf={pdfUrl} />}
    </div>
  );
}
