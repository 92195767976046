import { Helmet } from "react-helmet-async";
import Styles from "./styles.module.scss";
import CancelProcessList from "@Organism/BusinessSupport/CancelProcessList";
import { Suspense } from "react";
import { Await, defer, useLoaderData } from "react-router-dom";
import {
  getCancelProcessList,
  getCancelProcessListSync,
} from "@Services/support/candidate";
import LoadingAnimation from "@Atom/LoadingAnimation";
import AsyncErrorBoundary from "@Organism/Utils/AsyncErrorBoundary";

export async function loader(route) {
  const url = new URL(route?.request?.url);
  const limit = +url.searchParams.get("l") || 10;
  const page = +url.searchParams.get("p") || 1;
  const id = +url.searchParams.get("id") || "";
  const name = url.searchParams.get("n") || "";

  const cancelProcessListPromise = getCancelProcessList(page, limit, id);
  const getCancelProcessListSyncPromise = getCancelProcessListSync(name);

  return defer({
    packageCancelProcessList: cancelProcessListPromise,
    packageCancelProcessListSync: getCancelProcessListSyncPromise,
  });
}

export default function CancelProcessListPage() {
  const { packageCancelProcessList, packageCancelProcessListSync } =
    useLoaderData();
  return (
    <div className={Styles.container}>
      <Helmet>
        <title>Kerja 365 | Proses Dibatalkan</title>
      </Helmet>
      <div className={Styles.contentWrapper}>
        <Suspense
          fallback={
            <div className={Styles.loadingWrapper}>
              <LoadingAnimation />
            </div>
          }
        >
          <Await
            resolve={packageCancelProcessList}
            errorElement={
              <div style={{ height: "calc(100vh - 220px)" }}>
                <AsyncErrorBoundary />
              </div>
            }
          >
            {(packageCancelProcessList) => (
              <Suspense
                fallback={
                  <div className={Styles.loadingWrapper}>
                    <LoadingAnimation />
                  </div>
                }
              >
                <Await
                  resolve={packageCancelProcessListSync}
                  errorElement={
                    <div style={{ height: "calc(100vh - 220px)" }}>
                      <AsyncErrorBoundary />
                    </div>
                  }
                >
                  {(packageCancelProcessListSync) => (
                    <CancelProcessList
                      sync={packageCancelProcessListSync?.candidates || []}
                      data={packageCancelProcessList}
                    />
                  )}
                </Await>
              </Suspense>
            )}
          </Await>
        </Suspense>
      </div>
    </div>
  );
}
