import CompletionIndicatorIcons from "@Atom/CompletionIndicatorIcons";
import Icon from "@Atom/Icon";
import LoadingAnimation from "@Atom/LoadingAnimation";
import ModalWrapper from "@Atom/ModalWrapper";
import OutlinedSearchBar from "@Atom/OutlinedSearchBar";
import Spinner from "@Atom/Spinner";
import StackedProfile from "@Atom/StackedProfile";
import convertHtmlToPdf from "@Helpers/convertHtmlToPdf";
import useWindowSize from "@Hooks/useWindowSize";
// import SentContractModal from '@Molecule/_modal/BusinessSupport/SentContractModal';
import Stepper from "@Molecule/Stepper";
import { fetchBSDelegateOrderByClientCode } from "@Services/client/order";
import {
  fetchBMDelegatedByOrder,
  generateContractExtend,
  getContractSync,
  getExtendCandidateDetail,
  requestApprovalBm,
} from "@Services/support/candidate";
import Images from "@Theme/Images";
import { useCallback, useEffect, useMemo, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import FirstStep from "./First";
import FourthStep from "./Fourth";
import SecondStep from "./Second";
import Styles from "./styles.module.scss";
import ThirdStep from "./Third";
import SentContractExtendContractToBmModal from "@Molecule/_modal/BusinessSupport/SentContractExtendContractToBmModal";
import RequestApprovalExtendContractBmModal from "@Molecule/_modal/BusinessSupport/RequestApprovalExtendContractBmModal";
import moment from "moment";

const PDFJS = window.pdfjsLib;

export default function ExtendContractLayout({
  trigger,
  setTrigger,
  defaultData,
  contractList,
  clientList,
}) {
  const { employeeId } = useParams();
  const [data, setData] = useState(defaultData);
  const [sync, setSync] = useState();
  const [additionals, setAdditionals] = useState([]);
  const defaultStoredDataForm = sessionStorage.getItem("extendDataForm")
    ? JSON.parse(sessionStorage.getItem("extendDataForm"))
    : null;
  const defaultDataForm =
    defaultStoredDataForm?.employeeId === employeeId
      ? defaultStoredDataForm
      : null;
  const [selectedContractId, setSelectedContractId] = useState(
    defaultDataForm?.selectedContractId || null
  );

  const [selectedBM, setSelectedBM] = useState(
    defaultData?.businessManager?.employeeId || ""
  );
  const [selectedContractType, setSelectedContractType] = useState(
    defaultData?.ExpectedPosition?.contractType || ""
  );
  const [selectedClient, setSelectedClient] = useState(
    defaultData?.ExpectedPosition?.OrderScheme?.Client || {}
  );
  const [selectedOrder, setSelectedOrder] = useState({});
  const [selectedPosition, setSelectedPosition] = useState({});
  const [orderList, setOrderList] = useState([]);
  const [positionList, setPositionList] = useState([]);
  const [bmList, setBmList] = useState([]);
  const [selectedRequestBM, setSelectedRequestBM] = useState(
    defaultData?.businessManager?.employeeId || ""
  );

  useEffect(() => {
    const fetchOrder = async (clientCode) => {
      try {
        const { response } = await fetchBSDelegateOrderByClientCode(clientCode);
        setOrderList(response);
      } catch (error) {
        console.log(error);
        setOrderList([]);
        setPositionList([]);
        setSelectedOrder({});
        setSelectedPosition({});
        setBmList([]);
      }
    };

    if (selectedClient?.code) {
      fetchOrder(selectedClient?.code);
    } else {
      const getClient = clientList.find(
        (f) => f.abbreviation === selectedClient?.abbreviation
      );
      if (getClient) {
        fetchOrder(getClient?.code);
      } else {
        setSelectedOrder({});
        setSelectedPosition({});
      }
    }
  }, [selectedClient, clientList]);

  useEffect(() => {
    const fetchBmList = async (schemeId) => {
      try {
        const { response } = await fetchBMDelegatedByOrder(schemeId);
        setBmList(response?.bmDelegations || []);
      } catch (error) {
        console.log(error);
        setBmList([]);
      }
    };

    if (selectedOrder) {
      const position = selectedOrder?.manPowers;
      if (position?.length) {
        const fixedPosition = position.map((value) => {
          return {
            ...value,
            namePosition: `${value?.name} - ${value?.City?.name}`,
          };
        });
        setPositionList(fixedPosition);
        fetchBmList(selectedOrder?.id);
      }
    }
  }, [selectedOrder]);

  useEffect(() => {
    setAdditionals(contractList?.additionalTemplates);
  }, [contractList?.additionalTemplates]);

  const [selectedAdditionalsTemplates, setSelectedAdditionalsTemplates] =
    useState([]);

  const refetchData = useCallback(async () => {
    if (employeeId) {
      //   const { response } = await getCandidateDetails(id);
      const { response } = await getExtendCandidateDetail(employeeId);
      setData(response);
    }
  }, [employeeId]);

  const refetchSync = useCallback(async () => {
    try {
      if (
        data?.id &&
        selectedAdditionalsTemplates &&
        selectedContractId &&
        selectedBM &&
        selectedContractType
      ) {
        const { response } = await getContractSync(
          data?.id,
          selectedAdditionalsTemplates,
          {
            ...selectedContractId,
            businessManagerID: selectedBM,
            contractType: selectedContractType,
          }
        );
        setSync(response);
      }
    } catch (err) {
      console.log(err, "error on refetch sync");
    }
  }, [
    data?.id,
    selectedAdditionalsTemplates,
    selectedContractId,
    selectedBM,
    selectedContractType,
  ]);

  useEffect(() => {
    refetchData();
  }, [refetchData, trigger]);

  useEffect(() => {
    refetchSync();
  }, [refetchSync]);

  return (
    <ContractMode
      data={data}
      sync={sync}
      clientList={clientList}
      orderList={orderList}
      positionList={positionList}
      additionals={additionals}
      setAdditionals={setAdditionals}
      selectedAdditionalsTemplates={selectedAdditionalsTemplates}
      setSelectedAdditionalsTemplates={setSelectedAdditionalsTemplates}
      setSelectedContractId={setSelectedContractId}
      selectedContractId={selectedContractId}
      contractList={contractList}
      defaultDataForm={defaultDataForm}
      selectedBM={selectedBM}
      setSelectedBM={setSelectedBM}
      selectedContractType={selectedContractType}
      setSelectedContractType={setSelectedContractType}
      selectedClient={selectedClient}
      setSelectedClient={setSelectedClient}
      selectedOrder={selectedOrder}
      setSelectedOrder={setSelectedOrder}
      selectedPosition={selectedPosition}
      setSelectedPosition={setSelectedPosition}
      bmList={bmList}
      setBmList={setBmList}
      selectedRequestBM={selectedRequestBM}
      setSelectedRequestBM={setSelectedRequestBM}
    />
  );
}

const ContractMode = ({
  data,
  sync,
  clientList,
  orderList,
  positionList,
  selectedAdditionals,
  setSelectedAdditionals,
  additionals,
  setAdditionals,
  selectedAdditionalsTemplates,
  setSelectedAdditionalsTemplates,
  setGenerateContractMode,
  setTriggerSync,
  selectedContractId,
  setSelectedContractId,
  contractList,
  defaultDataForm,
  selectedBM,
  setSelectedBM,
  selectedContractType,
  setSelectedContractType,
  selectedClient,
  setSelectedClient,
  selectedOrder,
  setSelectedOrder,
  selectedPosition,
  setSelectedPosition,
  bmList,
  setBmList,
  selectedRequestBM,
  setSelectedRequestBM,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [stepsOptions, setStepOptions] = useState([
    {
      id: 1,
      label: "Informasi Kontrak",
      isDone: false,
    },
    {
      id: 2,
      label: "Tanggal Kontrak",
      isDone: false,
    },
    {
      id: 3,
      label: "Tunjangan",
      isDone: false,
    },
    {
      id: 4,
      label: "Kontrak Tambahan",
      isDone: false,
    },
  ]);

  const navigate = useNavigate();
  const [pdf, setPdf] = useState("");
  const [pdfUrl, setPdfUrl] = useState("");
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [images, setImages] = useState([]);
  const { width } = useWindowSize();

  const [htmlString, setHtmlString] = useState("");

  const [preview, setPreview] = useState(false);

  const [previewing, setPreviewing] = useState(false);
  const [saving, setSaving] = useState(false);

  const [openModalRequestApproval, setOpenModalRequestApproval] =
    useState(false);

  useEffect(() => {
    const getPdfUrl = async () => {
      setLoadingPdf(true);
      const data = await convertHtmlToPdf(htmlString);
      setPdfUrl(data);
      setLoadingPdf(false);
    };
    if (htmlString) {
      getPdfUrl();
    }
  }, [htmlString]);

  async function showPdf(url) {
    try {
      var _PDF_DOC = await PDFJS.getDocument({
        url: url,
      }).promise;
      setPdf(_PDF_DOC);
    } catch (error) {
      alert(error.message);
    }
  }

  const renderPage = useCallback(async () => {
    const imagesList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");
    // let canv = document.querySelector(".canv");
    for (let i = 1; i <= pdf.numPages; i++) {
      var page = await pdf.getPage(i);
      var viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };
      // setWidth(viewport.width);
      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagesList.push(img);
    }
    setImages(imagesList);
  }, [pdf]);

  useEffect(() => {
    isMobile && pdf && preview && renderPage();
  }, [pdf, renderPage, preview]);

  useEffect(() => {
    isMobile && pdfUrl && preview && showPdf(pdfUrl);
  }, [pdfUrl, preview]);

  const [currentStep, setCurrentStep] = useState(1);

  const [maxStep, setMaxStep] = useState(1);

  const handleClickStep = (step) => {
    if (maxStep >= step) {
      setCurrentStep(step);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("generateStep", currentStep);
  }, [currentStep]);

  const onClickPrevious = useCallback(() => {
    setCurrentStep(currentStep - 1);
  }, [currentStep]);

  const onClickNext = useCallback(() => {
    setCurrentStep(currentStep + 1);
    if (maxStep === currentStep) {
      setMaxStep(currentStep + 1);
    }
  }, [currentStep, maxStep]);

  const [form, setForm] = useState({
    vendor: "",
    salesCode: "",
    client: "",
    pks: "",
    position: "",
    jobTitle: "",
    umk: "",
    location: "",
    template: "",
    placement: "",
    salary: "",
    allowances: [],
    contractIncludes: [],
    contractDate: "",
    contractStartDate: "",
    contractEndDate: "",
    remark: "",
  });

  const [values, setValues] = useState(
    defaultDataForm?.candidateContractValues || []
  );

  const startDate = values?.find(
    (obj) => obj?.code === "{CONTRACT_STARTDATE}"
  )?.value;
  const endDate = values?.find(
    (obj) => obj?.code === "{CONTRACT_ENDDATE}"
  )?.value;

  useEffect(() => {
    if (!form?.contractStartDate || !form?.contractEndDate) {
      setForm((prev) => {
        return {
          ...prev,
          contractStartDate: new Date(startDate),
          contractEndDate: new Date(endDate),
        };
      });
    }
  }, [endDate, form?.contractEndDate, form?.contractStartDate, startDate]);

  const defaultOtherData = sessionStorage.getItem("c_allowances");

  const [allowances, setAllowances] = useState(
    defaultOtherData || defaultDataForm?.candidateAllowances || []
  );

  // useEffect(() => {
  //   sessionStorage.setItem("c_allowances", JSON.stringify(allowances || []));
  // }, [allowances]);

  const [additionalValues, setAdditionalValues] = useState([]);
  const [c2cAdditionals, setC2cAdditionals] = useState([]);

  // const valuesToSet = useMemo(() => {
  //   if (
  //     defaultDataForm &&
  //     defaultDataForm?.candidateContractValues?.length &&
  //     defaultDataForm?.candidateContractValues[0]?.contractTemplateID ===
  //       selectedContractId?.id
  //   ) {
  //     return defaultDataForm?.candidateContractValues;
  //   } else {
  //     return sync?.candidateContractValues;
  //   }
  // }, [defaultDataForm, selectedContractId?.id, sync?.candidateContractValues]);

  // const allowancesToSet = useMemo(() => {
  //   if (
  //     defaultDataForm &&
  //     defaultDataForm?.candidateAllowances?.length &&
  //     defaultDataForm?.candidateAllowances[0]?.contractTemplateID ===
  //       selectedContractId?.id
  //   ) {
  //     return defaultDataForm?.candidateAllowances;
  //   } else {
  //     return sync?.candidateAllowances;
  //   }
  // }, [defaultDataForm, selectedContractId?.id, sync?.candidateAllowances]);

  useEffect(() => {
    if (
      sync?.candidateAllowances !== null ||
      sync?.candidateAllowances?.length > 0
    ) {
      const matched = sync?.candidateAllowances?.every((obj) =>
        allowances?.some((el) => obj?.code === el?.code)
      );
      if (!matched) {
        setAllowances(sync?.candidateAllowances);
      }
    } else {
      setAllowances([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sync?.candidateAllowances]);

  useEffect(() => {
    setAdditionalValues(sync?.additionalContractValues || []);
  }, [sync?.additionalContractValues]);

  useEffect(() => {
    if (sync?.c2cAdditionalValues?.length > 0) {
      const checkLastValue = sync?.c2cAdditionalValues?.map((item) => {
        return {
          ...item,
          params: item?.params.map((el) => {
            if (el?.code === "{CANDIDATE_POSITION}") {
              if (selectedPosition?.name) {
                return {
                  ...el,
                  value: selectedPosition?.name,
                };
              }
            } else if (el?.code === "{CLIENT_NAME}") {
              if (selectedClient?.name) {
                return {
                  ...el,
                  value: selectedClient?.name,
                };
              }
            }
            return el;
          }),
        };
      });
      setC2cAdditionals(checkLastValue);
    } else {
      setC2cAdditionals([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sync?.c2cAdditionalValues]);

  useEffect(() => {
    if (
      sync?.candidateContractValues > 0 ||
      sync?.candidateContractValues !== null
    ) {
      const matched = sync?.candidateContractValues?.every((obj) =>
        values?.some((el) => {
          if (
            el?.value === null ||
            el?.value === "" ||
            el?.value === undefined
          ) {
            return false;
          }
          return obj?.code === el?.code;
        })
      );
      if (!matched) {
        // setValues(sync?.candidateContractValues);
        const checkLastValue = sync?.candidateContractValues?.map((item) => {
          if (item?.code === "{CANDIDATE_POSITION}") {
            if (selectedPosition?.name) {
              return {
                ...item,
                value: selectedPosition?.name,
              };
            }
          } else if (item?.code === "{CLIENT_NAME}") {
            if (selectedClient?.name) {
              return {
                ...item,
                value: selectedClient?.name,
              };
            }
          }
          return item;
        });
        setValues(checkLastValue);
      }
    } else {
      setValues([]);
    }
    // setValues(valuesToSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sync?.candidateContractValues]);

  const [searchTerm, setSearchTerm] = useState("");

  const AdditionalFilterType = useMemo(() => {
    return additionals?.filter(
      // (el) => el?.contractType === data?.jobPost?.contractType
      (el) => el?.contractType === selectedContractType
    );
    // }, [additionals, data?.jobPost?.contractType]);
  }, [additionals, selectedContractType]);

  function toTitleCase(str) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  const newData = useMemo(() => {
    if (values?.length > 0) {
      return values?.map((item) => {
        if (
          item?.label === "Bulan Tanggal Kontrak ROMAWI" ||
          item?.label === "Posisi" ||
          item?.label === "Jabatan PIC"
        ) {
          return {
            ...item,
            value:
              typeof item.value === "string"
                ? item.value.toUpperCase()
                : item.value,
          };
        } else {
          return {
            ...item,
            value:
              typeof item.value === "string"
                ? toTitleCase(item.value)
                : item.value,
          };
        }
      });
    }
  }, [values]);

  const C2CData = useMemo(() => {
    if (c2cAdditionals?.length > 0) {
      return c2cAdditionals?.map((item) => {
        return {
          ...item,
          params: item?.params?.map((el) => {
            if (
              el?.codeLocal === "{POSITION_SGF}" ||
              el?.codeLocal === "{AUTO_MONTH_ROMAWI}" ||
              el?.codeLocal === "{BM_POSITION}" ||
              el?.codeLocal === "{POSITION}"
            ) {
              return {
                ...el,
                value:
                  typeof el.value === "string"
                    ? el.value.toUpperCase()
                    : el.value,
              };
            } else {
              return {
                ...el,
                value:
                  typeof el.value === "string"
                    ? toTitleCase(el.value)
                    : el.value,
              };
            }
          }),
        };
      });
    }
  }, [c2cAdditionals]);

  const [selectedItemAllowance, setSelectedItemAllowances] = useState([]);

  const filterSalary = useMemo(() => {
    if (values?.length > 0) {
      return values?.find((el) => el?.code === "{SALARY}");
    }
  }, [values]);

  const [valueVariableCost, setValueVariableCost] = useState(
    defaultDataForm?.variableCost || []
  );

  useEffect(() => {
    if (sync?.variableCost !== null) {
      const matched = sync?.variableCost?.every((obj) =>
        valueVariableCost?.some((el) => obj?.label === el?.label)
      );
      if (!matched) {
        setValueVariableCost(sync?.variableCost);
      }
    } else {
      setValueVariableCost([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sync?.variableCost]);

  useEffect(() => {
    if (c2cAdditionals?.length > 0) {
      setC2cAdditionals((prev) =>
        prev?.map((item) => {
          return {
            ...item,
            params: item?.params?.map((el) => {
              if (el?.codeLocal === "{SALARY}") {
                return {
                  ...el,
                  value: Number(
                    filterSalary?.value.split(".").join("")
                  ).toLocaleString("id"),
                };
              } else {
                return el;
              }
            }),
          };
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSalary]);

  // useEffect(() => {
  //   // Avoid modifying valueVariableCost here
  // }, [filterSalary, c2cAdditionals]);

  // useEffect(() => {
  //   if (data?.ExpectedPosition?.contractType === "PKM") {
  //     setValues((prev) =>
  //       prev?.map((el) => {
  //         if (el?.code === "{CONTRACT_ENDDATE}") {
  //           return {
  //             ...el,
  //             value: "30 Desember 2099",
  //           };
  //         } else {
  //           return el;
  //         }
  //       })
  //     );
  //   }
  // }, [data?.ExpectedPosition?.contractType]);
  const [errorDate, setErrorDate] = useState("");
  console.log(selectedOrder, "test");

  const content = useMemo(() => {
    switch (currentStep) {
      case 1:
        return (
          <FirstStep
            values={newData}
            setValues={setValues}
            sync={sync}
            clientList={clientList}
            orderList={orderList}
            positionList={positionList}
            setSelectedContractId={setSelectedContractId}
            selectedContractId={selectedContractId}
            contractList={contractList}
            selectedBM={selectedBM}
            setSelectedBM={setSelectedBM}
            setSelectedRequestBM={setSelectedRequestBM}
            selectedContractType={selectedContractType}
            setSelectedContractType={setSelectedContractType}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
          />
        );
      case 3:
        return (
          <SecondStep
            values={newData}
            setValues={setValues}
            allowances={allowances}
            // allowances={[]}
            setAllowances={setAllowances}
            sync={sync}
            contractList={contractList}
            setSelectedItemAllowances={setSelectedItemAllowances}
            selectedItemAllowance={selectedItemAllowance}
            data={data}
            valueVariableCost={valueVariableCost}
            setValueVariableCost={setValueVariableCost}
          />
        );
      case 2:
        return (
          <FourthStep
            form={form}
            setForm={setForm}
            values={values}
            setValues={setValues}
            contractType={selectedContractType}
            data={selectedOrder}
            setErrorDate={setErrorDate}
            errorDate={errorDate}
            // contractType={data?.jobPost?.contractType}
          />
        );
      case 4:
        return (
          <ThirdStep
            additionals={AdditionalFilterType}
            setAdditionals={setAdditionals}
            selectedAdditionalsTemplates={selectedAdditionalsTemplates}
            setSelectedAdditionalsTemplates={setSelectedAdditionalsTemplates}
            additionalValues={additionalValues}
            setAdditionalValues={setAdditionalValues}
            setC2cAdditionals={setC2cAdditionals}
            c2cAdditionals={c2cAdditionals}
            searchTerm={searchTerm}
            selectedContractType={selectedContractType}
          />
        );
      default:
        return (
          <FirstStep
            form={form}
            setForm={setForm}
            selectedBM={selectedBM}
            setSelectedBM={setSelectedBM}
            setSelectedRequestBM={setSelectedRequestBM}
            selectedContractType={selectedContractType}
            setSelectedContractType={setSelectedContractType}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            selectedOrder={selectedOrder}
            setSelectedOrder={setSelectedOrder}
            selectedPosition={selectedPosition}
            setSelectedPosition={setSelectedPosition}
          />
        );
    }
  }, [
    // setSelectedItemAllowances,
    currentStep,
    values,
    sync,
    allowances,
    setAdditionals,
    selectedAdditionalsTemplates,
    setSelectedAdditionalsTemplates,
    form,
    additionalValues,
    c2cAdditionals,
    // additionals,
    searchTerm,
    selectedItemAllowance,
    data,
    valueVariableCost,
    selectedBM,
    setSelectedBM,
    setSelectedRequestBM,
    selectedContractType,
    setSelectedContractType,
    // setValueVariableCost,
    clientList,
    orderList,
    positionList,
    selectedClient,
    setSelectedClient,
    selectedOrder,
    setSelectedOrder,
    selectedPosition,
    setSelectedPosition,
    newData,
    contractList,
    selectedContractId,
    setSelectedContractId,
    AdditionalFilterType,
    errorDate,
    setErrorDate,
  ]);

  const { employeeId } = useParams();

  const [showSuccess, setShowSuccess] = useState(false);

  const dataForm = useMemo(() => {
    return {
      employeeId: employeeId,
      candidateId: data?.id,
      selectedContractId: selectedContractId,
      contractTemplateID: selectedContractId?.id,
      candidateAllowances: allowances,
      candidateContractValues: newData,
      // allAdditionalTemplates: additionals,
      additionalContractValues: additionalValues,
      c2cAdditionalValues: C2CData,
      contractSource: selectedContractId?.name,
      source: selectedContractId?.source,
      variableCost: valueVariableCost?.length > 0 ? valueVariableCost : [],
      remark: form?.remark,
      company: "PERMATA INDONESIA",
      selectedClient: selectedClient,
      selectedOrder: selectedOrder,
      selectedPosition: selectedPosition,
    };
  }, [
    employeeId,
    C2CData,
    newData,
    additionalValues,
    allowances,
    form?.remark,
    data?.id,
    selectedContractId,
    // values,
    valueVariableCost,
    selectedClient,
    selectedOrder,
    selectedPosition,
  ]);

  useEffect(() => {
    sessionStorage.setItem("extendDataForm", JSON.stringify(dataForm));
  }, [dataForm]);

  const handleSubmit = async (e, action) => {
    e.preventDefault();

    let formToSend = {
      positionID: selectedPosition?.id,
      contractTemplateID: selectedContractId?.id,
      action: action,
      candidateAllowances: allowances,
      candidateContractValues: newData,
      // allAdditionalTemplates: additionals,
      additionalContractValues: additionalValues,
      c2cAdditionalValues: C2CData,
      contractSource: selectedContractId?.name,
      source: selectedContractId?.source,
      variableCost: valueVariableCost?.length > 0 ? valueVariableCost : null,
      remark: null,
      company: "PERMATA INDONESIA",
      contractType: selectedContractType,
      employeeId: employeeId,
    };

    try {
      if (action === "PREVIEW") {
        setPreviewing(true);
        // setLoadingPdf(true)
        setPreview(true);
        // console.log(formToSend);
        const { response } = await generateContractExtend(data?.id, formToSend);

        // console.log(response?.filledTemplate);
        setHtmlString(response?.filledTemplate);
        setPreviewing(false);
      } else {
        setOpenModalRequestApproval(false);
        setSaving(true);
        // console.log(formToSend);
        await generateContractExtend(data?.id, formToSend);
        // await requestApprovalBm(data?.id, selectedBM);
        await requestApprovalBm(data?.id, selectedRequestBM);
        setSaving(false);
        setShowSuccess(true);
      }
    } catch (err) {
      setPreviewing(false);
      setSaving(false);
      console.log(err, "error while submitting the form");
    }
  };

  const disabledButtonSelectedContractID = useMemo(() => {
    if (selectedContractId === null) {
      return true;
    } else {
      return false;
    }
  }, [selectedContractId]);

  const disabledButtonStep1 = useMemo(() => {
    if (values?.length === 0 || values === undefined) {
      return true;
    } else {
      for (let i = 0; i < values?.length; i++) {
        if (
          values[i].value === "" ||
          values[i].value === null ||
          values[i].value === undefined ||
          values[i].value === "Invalid Date"
        ) {
          if (
            values[i].code !== "{CONTRACT_STARTDATE}" &&
            values[i].code !== "{CONTRACT_ENDDATE}"
          ) {
            return true;
          }
        }
      }
      return false;
    }
  }, [values]);

  const disabledButtonValues = useMemo(() => {
    if (values?.length === 0 || values === undefined) {
      return true;
    } else {
      for (let i = 0; i < values?.length; i++) {
        if (
          values[i].value === "" ||
          values[i].value === null ||
          values[i].value === undefined ||
          values[i].value === "Invalid Date"
        ) {
          return true;
        }
      }
      return false;
    }
  }, [values]);

  const disabledC2CAdditional = useMemo(() => {
    if (C2CData?.length === 0 || C2CData === undefined) {
      return false;
    } else {
      for (let i = 0; i < C2CData?.length; i++) {
        for (let j = 0; j < C2CData[i]?.params?.length; j++) {
          if (
            C2CData[i].params[j].value === "" ||
            C2CData[i].params[j].value === null ||
            C2CData[i].params[j].value === undefined ||
            C2CData[i].params[j].value === "Invalid Date"
          ) {
            return true;
          }
        }
      }
      return false;
    }
  }, [C2CData]);

  const disabledAllowances = useMemo(() => {
    if (allowances?.length === 0 || !allowances) {
      return false;
    } else {
      for (const elem of allowances) {
        if (
          !elem?.value ||
          elem?.value === "::" ||
          !elem?.value?.split("::")[0]
        ) {
          return true;
        }
      }
      return false;
    }
  }, [allowances]);

  const disablePrevious = useMemo(() => {
    if (currentStep === 1) {
      return true;
    }
    return false;
  }, [currentStep]);

  const disableNext = useMemo(() => {
    switch (currentStep) {
      case 1:
        if (!disabledButtonSelectedContractID && !disabledButtonStep1) {
          return false;
        } else return true;
      case 3:
        if (!disabledButtonSelectedContractID && !disabledAllowances) {
          return false;
        } else return true;
      case 2:
        if (
          !disabledButtonSelectedContractID &&
          !disabledButtonValues &&
          !errorDate
        ) {
          return false;
        } else return true;
      case 4:
        return true;
      default:
        return false;
    }
  }, [
    errorDate,
    currentStep,
    disabledButtonSelectedContractID,
    disabledButtonValues,
    disabledAllowances,
    disabledButtonStep1,
  ]);

  return (
    <div className={Styles.contractContainer}>
      <div className={Styles.header}>
        <div className={Styles.left}>
          {/* <img src={Images.AVA_DEFAULT} alt="" />
            <div>
              <span>{data?.name}</span>
              <span>{data?.phoneNumber} • {data?.recruiterID}</span>
            </div> */}
          <StackedProfile
            profilePicture={data?.profilePicture || Images.AVA_DEFAULT}
            title={toTitleCase(data?.name)}
            label={`${data?.phoneNumber} • ${data?.employeeId}`}
            extraTitle={<CompletionIndicatorIcons times={1} />}
          />
        </div>
        <div className={Styles.right}>
          <button
            onClick={(e) => {
              handleSubmit(e, "PREVIEW");
            }}
            disabled={
              disabledButtonSelectedContractID === true ||
              disabledButtonValues === true ||
              disabledC2CAdditional === true ||
              disabledAllowances === true ||
              errorDate
            }
            className={`${Styles.button} ${
              disabledButtonSelectedContractID === true ||
              disabledButtonValues === true ||
              disabledC2CAdditional === true ||
              disabledAllowances === true ||
              errorDate
                ? Styles.disabledButton
                : ""
            }`}
          >
            <span className={Styles.hideOnMobile}>Preview Kontrak</span>
            {loadingPdf || previewing ? (
              <Spinner />
            ) : (
              <Icon icon={"eye-visible"} size={20} />
            )}
          </button>
          <button
            onClick={(e) => {
              // handleSubmit(e, 'SAVE');
              setOpenModalRequestApproval(true);
            }}
            disabled={
              saving ||
              disabledButtonSelectedContractID === true ||
              disabledButtonValues === true ||
              disabledC2CAdditional === true ||
              disabledAllowances === true ||
              preview === false ||
              errorDate
            }
            className={`${Styles.buttonSave} ${
              disabledButtonSelectedContractID === true ||
              disabledButtonValues === true ||
              disabledC2CAdditional === true ||
              disabledAllowances === true ||
              preview === false ||
              saving ||
              errorDate
                ? Styles.disabledButton
                : ""
            }`}
          >
            <span className={Styles.hideOnMobile}>Minta Persetujuan BM</span>
            <Icon icon={"send-solid"} size={20} />
          </button>
        </div>
      </div>
      <div className={Styles.content}>
        <div className={Styles.left}>
          <div className={Styles.nameClientBox}>
            <Icon icon={"building-office"} size={24} color={"#1571DE"} />
            <div className={Styles.nameClient}>
              <span className={Styles.titleClient}>Klien</span>
              <span className={Styles.nameText}>
                {data?.ExpectedPosition?.OrderScheme?.Client?.name || "-"}
              </span>
            </div>
          </div>
          <div className={Styles.top}>
            <h4>Detail Kontrak</h4>
            <div className={Styles.stepperWrapper}>
              <Stepper
                type="filled"
                onClick={handleClickStep}
                maxStep={maxStep}
                setMaxstep={setMaxStep}
                options={stepsOptions}
                currentStep={currentStep}
              />
            </div>

            <div className={Styles.navs}>
              <button disabled={disablePrevious} onClick={onClickPrevious}>
                Kembali
              </button>
              <button disabled={disableNext} onClick={onClickNext}>
                Lanjut
              </button>
            </div>

            {errorDate && +currentStep === 2 && (
              <div className={Styles.errorDate}>
                <div>
                  <Icon icon={"alert-solid"} size={16} color={"#F5610D"} />
                </div>
                <span>
                  Tanggal berakhir kontrak TKO tidak boleh lebih dari tanggal
                  <span>
                    {" "}
                    PKS :{" "}
                    {selectedOrder?.clientContracts?.endDate
                      ? moment(
                          new Date(selectedOrder?.clientContracts?.endDate)
                        ).format("DD MMMM YYYY")
                      : "-"}
                  </span>
                  , Silahkan untuk mengganti tanggal tersebut
                </span>
              </div>
            )}
          </div>
          {currentStep === 4 && (
            <div className={Styles.searchAdditionalBox}>
              <div className={Styles.searchWrapper}>
                <OutlinedSearchBar
                  value={searchTerm}
                  setValue={setSearchTerm}
                  placeholder={"Cari nama template kontrak"}
                />
              </div>
            </div>
          )}
          <div className={Styles.bottom}>
            <div>{content}</div>
          </div>
        </div>
        <div className={Styles.right}>
          {!pdfUrl ? (
            loadingPdf ? (
              <div className={Styles.loadingWrapper}>
                <LoadingAnimation />
              </div>
            ) : (
              <div className={Styles.contractWrapper}>
                <img src={Images.KONTRAK} alt="" />
                <h4>Kontrak Belum Ada</h4>
                <span>
                  Kontrak akan muncul ketika verifikasi kedua dilakukan
                </span>
              </div>
            )
          ) : (
            <div className={Styles.pdfWrapper}>
              <embed
                src={`${pdfUrl}#navpanes=0`}
                type="application/pdf"
              ></embed>
            </div>
          )}
        </div>
      </div>
      {width <= 768 && preview && (
        <div className={Styles.imagesContainer}>
          <div className={Styles.button}>
            <button onClick={() => setPreview(false)}>
              <Icon icon={"arrow-left"} size={24} />
            </button>
            <span>Preview Contract</span>
          </div>
          {loadingPdf || previewing ? (
            <div className={Styles.loadingWrapper}>
              <LoadingAnimation />
            </div>
          ) : (
            images?.map((each, i) => <img src={each} key={i} alt="" />)
          )}
        </div>
      )}

      <div style={{ visibility: showSuccess ? "visible" : "hidden" }}>
        <ModalWrapper show={showSuccess} forceCenter>
          <SentContractExtendContractToBmModal
            onClickBack={() => navigate("/data/contracts")}
          />
        </ModalWrapper>
      </div>

      {openModalRequestApproval && (
        <RequestApprovalExtendContractBmModal
          bmList={bmList}
          selectedBM={selectedRequestBM}
          setSelectedBM={setSelectedRequestBM}
          showModal={openModalRequestApproval}
          onClickBack={() => {
            setOpenModalRequestApproval(false);
          }}
          submit={(e) => {
            handleSubmit(e, "SAVE");
          }}
        />
      )}
    </div>
  );
};
