import Dropdown from "@Atom/DropDown";
import Icon from "@Atom/Icon";
import Input from "@Atom/Input";
import InputDate from "@Atom/InputDate";
import LoadingAnimation from "@Atom/LoadingAnimation";
import ModalWrapper from "@Atom/ModalWrapper";
import { allFilledObjChecker } from "@Helpers/allFilledObjChecker";
import DoneModal from "@Molecule/_modal/DoneModal";
import OfferingAddAllowanceModal from "@Molecule/_modal/OfferingAddAllowanceModal";
import { getSyncOffering, sendOffering } from "@Services/officer/recruitment";
import Images from "@Theme/Images";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import Styles from "./styles.module.scss";

// const a = [
//   "Uang makan",
//   "Tunjangan transportasi",
//   "Lembur",
//   "Tunjangan komunikasi",
//   "Tunjangan BBM",
//   "Tunjangan skills/keahlian",
//   "Tunjangan operasional",
//   "Tunjangan posisi/jabatan",
//   "Tunjangan service kendaraan",
//   "Tunjangan kehadiran",
//   "Tunjangan kesehatan",
//   "Tunjangan kedisiplinan",
//   "Tunjangan kerajinan",
//   "Tunjangan tidak tetap lainnya",
//   "Tunjangan insentif/shift",
//   "Kos",
//   "Rumah",
//   "Pekerjaan",
// ];

export default function OfferingFormPage({ showInterviewRO, handleClose }) {
  const [offeringForm, setOfferingForm] = useState({
    recruiterID: localStorage.getItem("id") || "",
    contract: {
      startDate: "",
      endDate: "",
      baseSalary: "",
      denom: "",
    },
    allowances: [],
  });
  const [showAllowancesModal, setShowAllowancesModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [errorDate, setErrorDate] = useState("");
  // const [errorStartDate, setErrorStartDate] = useState("");
  const [search, setSearch] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [isError, setIsError] = useState(false);

  const [isLoadingData, setIsLoadingData] = useState(false);
  const [dataOffering, setDataOffering] = useState(null);

  const refetchDataOfffering = useCallback(async () => {
    try {
      setIsLoadingData(true);
      const { response } = await getSyncOffering(
        showInterviewRO?.data?.candidateId,
        ""
      );
      //   setDataEvaluation(response);
      setDataOffering(response);
      setIsLoadingData(false);
      setOfferingForm((prev) => ({
        ...prev,
        contract: {
          startDate: response?.offering?.startDate || "",
          endDate: response?.offering?.endDate || "",
          baseSalary: response?.offering?.CandidateAllowanceOfferings?.find(
            (item) => item?.paymentType === "SALARY"
          )
            ? response?.offering?.CandidateAllowanceOfferings?.find(
                (item) => item?.paymentType === "SALARY"
              )?.value
            : "",
          denom: response?.offering?.CandidateAllowanceOfferings?.find(
            (item) => item?.paymentType === "SALARY"
          )
            ? response?.offering?.CandidateAllowanceOfferings?.find(
                (item) => item?.paymentType === "SALARY"
              )?.denom
            : "",
        },

        allowances:
          response?.offering?.CandidateAllowanceOfferings?.filter(
            (el) => el?.paymentType !== "SALARY"
          )?.length > 0
            ? response?.offering?.CandidateAllowanceOfferings?.filter(
                (el) => el?.paymentType !== "SALARY"
              )?.map((obj) => {
                return {
                  label: obj?.label,
                  value: obj?.value,
                  denom: obj?.denom,
                };
              })
            : [],
      }));
    } catch (err) {
      setIsLoadingData(false);
      console.log(err);
    }
  }, [showInterviewRO?.data?.candidateId]);

  //   console.log(offeringForm);

  useEffect(() => {
    if (showInterviewRO?.name === "KIRIM OFFERING") {
      refetchDataOfffering();
    }
  }, [showInterviewRO, refetchDataOfffering]);

  const addMonths = (date, months) => {
    const result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  };

  useEffect(() => {
    if (
      offeringForm?.contract?.endDate &&
      dataOffering?.clientContract?.endDate
    ) {
      const offeringEndDate = new Date(offeringForm.contract.endDate);
      const clientContractEndDate = new Date(
        dataOffering.clientContract.endDate
      );

      // Validasi otomatis saat komponen di-mount atau data berubah
      if (offeringEndDate > clientContractEndDate) {
        setErrorDate("*Tanggal kontrak lebih besar dari tanggal PKS");
        setOfferingForm((prev) => ({
          ...prev,
          contract: {
            ...prev.contract,
            endDate: prev.contract.endDate,
          },
        }));
      } else {
        if (!offeringForm?.contract?.startDate) {
          setOfferingForm((prev) => ({
            ...prev,
            contract: {
              ...prev.contract,
              endDate: "",
            },
          }));
        } else {
          if (showInterviewRO?.data?.contractType?.toLowerCase() === "pkwt") {
            if (
              new Date(offeringForm?.contract?.startDate) >
              new Date(offeringForm?.contract?.endDate)
            ) {
              setOfferingForm((prev) => ({
                ...prev,
                contract: {
                  ...prev.contract,
                  endDate: "",
                },
              }));
            }
          }
        }
        setErrorDate("");
      }
    }
  }, [
    showInterviewRO,
    offeringForm?.contract?.endDate,
    dataOffering?.clientContract?.endDate,
    offeringForm?.contract?.startDate,
  ]); // Dependencies untuk memicu useEffect

  const formatDate = useCallback(() => {
    if (
      showInterviewRO?.data?.contractType?.toLowerCase() === "pkm" &&
      !dataOffering?.offering
    ) {
      if (offeringForm?.contract?.startDate) {
        setOfferingForm((prev) => ({
          ...prev,
          contract: {
            startDate: offeringForm?.contract?.startDate,
            endDate: addMonths(offeringForm?.contract?.startDate, 3) || "",
            baseSalary: "",
            denom: "",
          },
        }));
      }
    }
  }, [showInterviewRO, offeringForm?.contract?.startDate, dataOffering]);

  useEffect(() => {
    formatDate();
  }, [formatDate]);

  useEffect(() => {
    const offeringStartDate = new Date(offeringForm?.contract?.startDate);
    const clientContractStartDate = new Date(
      dataOffering?.clientContract?.startDate
    );
    const clientContractEndDate = new Date(
      dataOffering?.clientContract?.endDate
    );

    // Validasi apakah startDate valid dan dalam rentang yang diperbolehkan
    if (
      offeringForm?.contract?.startDate &&
      dataOffering?.clientContract?.startDate &&
      dataOffering?.clientContract?.endDate &&
      !isNaN(offeringStartDate.getTime()) &&
      !isNaN(clientContractStartDate.getTime()) &&
      !isNaN(clientContractEndDate.getTime())
    ) {
      if (offeringStartDate < clientContractStartDate) {
        setOfferingForm((prev) => ({
          ...prev,
          contract: {
            ...prev.contract,
            startDate: "",
          },
        }));
      } else if (offeringStartDate > clientContractEndDate) {
        setOfferingForm((prev) => ({
          ...prev,
          contract: {
            ...prev.contract,
            startDate: "",
          },
        }));
      } else {
        setOfferingForm((prev) => ({
          ...prev,
          contract: {
            ...prev.contract,
            startDate: prev.contract.startDate,
          },
        }));
      }
    }
  }, [
    offeringForm?.contract?.startDate,
    dataOffering?.clientContract?.startDate,
    dataOffering?.clientContract?.endDate,
  ]);

  const disableSubmitOffering = useMemo(() => {
    // Jika sedang dalam proses submit, tombol harus disabled
    if (isSubmitting) {
      return true;
    }

    // Cek apakah semua field di offeringForm?.contract sudah terisi
    if (!allFilledObjChecker(offeringForm?.contract)) {
      return true;
    }

    // Cek apakah ada errorDate
    if (errorDate) {
      return true;
    }

    // Cek apakah ada allowances yang tidak lengkap
    for (let i = 0; i < offeringForm?.allowances?.length; i++) {
      const a = offeringForm?.allowances[i];
      if (!a?.value || !a?.denom) {
        return true;
      }
    }

    // Jika semua validasi lolos, tombol tidak disabled
    return false;
  }, [offeringForm, isSubmitting, errorDate]); // Tambahkan errorDate sebagai dependency

  const disableEndDate = useMemo(() => {
    if (!offeringForm?.contract?.startDate) {
      return true;
    }

    return false;
  }, [offeringForm]);

  const handleSubmitOffering = async (e) => {
    try {
      e.preventDefault();
      setIsSubmitting(true);
      await sendOffering(showInterviewRO?.data?.candidateId, {
        recruiterID: offeringForm?.recruiterID,
        contract: {
          startDate: offeringForm?.contract?.startDate
            ? moment(new Date(offeringForm?.contract?.startDate)).format(
                "YYYY-MM-DD"
              )
            : "",
          endDate: offeringForm?.contract?.endDate
            ? moment(new Date(offeringForm?.contract?.endDate)).format(
                "YYYY-MM-DD"
              )
            : "",
          baseSalary: offeringForm?.contract?.baseSalary || "",
          denom: offeringForm?.contract?.denom || "",
        },
        allowances: offeringForm?.allowances || [],
      });
      setIsSent(true);
      setIsSubmitting(false);
      //   setSyncTrigger(makeRandomString(5));
      //   setCurrentStep(3);
      //   setOfferingMode(false);
    } catch (err) {
      const errorText = err?.response?.data?.error;
      setIsError(errorText);
      console.log(err);
      setIsSubmitting(false);
    }
  };

  return isLoadingData ? (
    <div
      style={{
        // placeItems: 'center'
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <LoadingAnimation />
    </div>
  ) : (
    <div className={Styles.containerModal}>
      <div className={Styles.header}>
        <div className={Styles.top}>
          <div>
            <div className={Styles.iconArrow} onClick={handleClose}>
              <Icon icon={"arrow-left"} size={24} color={"#0A0A0A"} />
            </div>

            <div className={Styles.router}>
              <span>Rekrutmen</span>
              <div>
                <Icon icon={"arrow-right"} size={18} color={"#0A0A0A"} />
              </div>
              <span>Kirim Offering</span>
            </div>
          </div>

          <div>Kirim Offering</div>
        </div>

        {errorDate ? (
          <div className={Styles.errorDate}>
            <div>
              <Icon icon={"alert-solid"} size={14} color={"#F5610D"} />
            </div>
            <span>
              {"Tanggal berakhir kontrak TKO tidak boleh lebih dari tanggal"}{" "}
              <span>
                PKS :{" "}
                {dataOffering.clientContract.endDate
                  ? moment(
                      new Date(dataOffering.clientContract.endDate)
                    ).format("DD MMMM YYYY")
                  : "-"}
              </span>
              , Silahkan untuk mengganti tanggal tersebut
            </span>
          </div>
        ) : null}
      </div>

      <div className={Styles.contents}>
        <div>
          <div className={Styles.inputSection}>
            <div>
              <div className={Styles.images}>
                <img src={Images.AVA_DEFAULT} alt="" />
              </div>

              <div className={Styles.nameCandidate}>
                <span>
                  {showInterviewRO?.data?.name || "-"}
                  <div className={Styles.dots}></div>
                  {showInterviewRO?.data?.position || "-"}
                </span>
                <span>
                  +{showInterviewRO?.data?.phoneNumber || "-"}
                  <div>
                    <Icon icon={"whatsapp"} size={20} color={"#27BB83"} />
                  </div>
                </span>
              </div>
            </div>

            <div>
              <span>Tanggal Kontrak</span>
              <div className={Styles.range}>
                <div className={Styles.searchWrapper}>
                  <InputDate
                    value={offeringForm?.contract?.startDate}
                    placeholder={"Tanggal mulai kontrak"}
                    min={dataOffering?.clientContract?.startDate}
                    max={dataOffering?.clientContract?.endDate}
                    setValue={(newVal) => {
                      const newForm = { ...offeringForm };
                      newForm.contract.startDate = newVal;
                      // if (
                      //   showInterviewRO?.data?.contractType?.toLowerCase() ===
                      //   "pkwt"
                      // ) {
                      //   if (
                      //     new Date(newVal) >
                      //     new Date(offeringForm?.contract?.endDate)
                      //   ) {
                      //     newForm.contract.endDate = "";
                      //   }
                      // }
                      setOfferingForm(newForm);
                    }}
                  />
                </div>

                <div className={Styles.separator}>-</div>

                <div className={Styles.searchWrapper}>
                  <InputDate
                    value={
                      disableEndDate ? "" : offeringForm?.contract?.endDate
                    }
                    setValue={(newVal) => {
                      const newForm = { ...offeringForm };
                      newForm.contract.endDate = newVal;
                      setOfferingForm(newForm);
                    }}
                    placeholder={"Tanggal berakhir kontrak"}
                    disabled={disableEndDate}
                    min={offeringForm?.contract?.startDate}
                    isError={errorDate}
                    errorText={errorDate}
                  />
                </div>
              </div>
            </div>

            <div>
              <span>Gaji Pokok/Bantuan Operasional</span>
              <div className={Styles.requirements}>
                <div className={Styles.searchWrapper}>
                  <Input
                    value={offeringForm?.contract?.baseSalary}
                    onChange={(newVal) => {
                      const newForm = { ...offeringForm };
                      newForm.contract.baseSalary = newVal;
                      setOfferingForm(newForm);
                    }}
                    placeholder={"Gaji"}
                    isCurrency
                  />
                </div>

                <div className={Styles.searchWrapper}>
                  <Dropdown
                    autoClose
                    value={offeringForm?.contract?.denom}
                    setValue={(newVal) => {
                      const newForm = { ...offeringForm };
                      newForm.contract.denom = newVal;
                      setOfferingForm(newForm);
                    }}
                    placeholder={"Harian/mingguan/bulanan"}
                    options={[
                      { name: "Harian" },
                      { name: "Mingguan" },
                      { name: "Bulanan" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <div>
              <div className={Styles.headerAllowances}>
                <span>Tunjangan</span>
                {!!offeringForm?.allowances?.length && (
                  <button
                    onClick={() => setShowAllowancesModal(true)}
                    className={`${Styles.addAllowance}`}
                  >
                    <span>Tambah Tunjangan</span>
                    <Icon icon={"circle-plus"} size={20} />
                  </button>
                )}
              </div>

              {!offeringForm?.allowances?.length ? (
                <div className={Styles.empty}>
                  <span>Belum ada tunjangan ditambahkan</span>
                  <button
                    onClick={() => setShowAllowancesModal(true)}
                    className={`${Styles.addAllowance}`}
                  >
                    <span>Tambah Tunjangan</span>
                    <Icon icon={"circle-plus"} size={20} />
                  </button>
                </div>
              ) : (
                <div className={Styles.fieldsWrapper}>
                  {offeringForm?.allowances?.map((o, oI) => (
                    <div key={oI} className={Styles.each}>
                      <span>{o?.label}</span>
                      <div className={Styles.fields}>
                        <div>
                          <div>
                            <Input
                              value={o?.value}
                              onChange={(newVal) => {
                                setOfferingForm((prev) => {
                                  return {
                                    ...prev,
                                    allowances: prev?.allowances?.map((obj) => {
                                      return {
                                        ...obj,
                                        value:
                                          obj?.label === o?.label
                                            ? newVal
                                            : obj?.value,
                                      };
                                    }),
                                  };
                                });
                              }}
                              placeholder={"000"}
                              isCurrency
                            />
                          </div>

                          <div>
                            <Dropdown
                              value={o?.denom}
                              setValue={(newVal) => {
                                setOfferingForm((prev) => {
                                  return {
                                    ...prev,
                                    allowances: prev?.allowances?.map((obj) => {
                                      return {
                                        ...obj,
                                        denom:
                                          obj?.label === o?.label
                                            ? newVal
                                            : obj?.denom,
                                      };
                                    }),
                                  };
                                });
                              }}
                              autoClose
                              placeholder={"Harian/mingguan/bulanan"}
                              options={[
                                {
                                  name: "Harian",
                                },
                                {
                                  name: "Mingguan",
                                },
                                {
                                  name: "Bulanan",
                                },
                              ]}
                            />
                          </div>
                        </div>
                        <button
                          onClick={() => {
                            setOfferingForm((prev) => {
                              return {
                                ...prev,
                                allowances: prev?.allowances?.filter(
                                  (obj) => obj?.label !== o?.label
                                ),
                              };
                            });
                          }}
                        >
                          <Icon icon={"trash"} size={24} />
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className={Styles.buttoneSection}>
            <div>
              {isError ? <span>*{isError}</span> : null}

              <div
                onClick={disableSubmitOffering ? null : handleSubmitOffering}
                className={`${disableSubmitOffering ? Styles.disabled : ""}`}
              >
                {isSubmitting ? "Loading..." : "Kirim"}
              </div>
            </div>
          </div>
        </div>
      </div>

      {showAllowancesModal && (
        <ModalWrapper
          show={showAllowancesModal}
          handleClose={() => setShowAllowancesModal(false)}
        >
          <OfferingAddAllowanceModal
            options={dataOffering?.allowances || []}
            selectedOptions={offeringForm?.allowances?.map((obj) => obj?.label)}
            isLoadingData={isLoadingData}
            search={search}
            setSearch={setSearch}
            onSubmit={(arr) => {
              setOfferingForm((prev) => {
                return {
                  ...prev,
                  allowances: [
                    ...prev?.allowances,
                    ...arr?.map((obj) => {
                      return {
                        label: obj,
                        value: "",
                        denom: "",
                      };
                    }),
                  ],
                };
              });
              setShowAllowancesModal(false);
            }}
            onClose={() => setShowAllowancesModal(false)}
          />
        </ModalWrapper>
      )}

      {isSent && (
        <DoneModal
          title={"Terkirim!"}
          description={"Offering telah dikirimkan"}
          show={isSent}
          doneButton={true}
          doneTitle={"Ok"}
          handleDone={() => {
            setIsSent(false);
            handleClose();
          }}
        />
      )}
    </div>
  );
}
