import Styles from "./style.module.scss";
import Icon from "@Atom/Icon";

const RejectSuccessModal = ({ setShowReject, stage }) => {
  return (
    <div className={Styles.container}>
      <div className={Styles.header}>
        <div className={Styles.iconWrapper}>
          <Icon icon="shield-exclamation" size="48" className={Styles.icon} />
        </div>
      </div>
      <div className={Styles.content}>
        <div className={Styles.textWrapper}>
          <div className={Styles.title}>Reject Verifikasi Berhasil</div>
          {stage === "REGISTER" ? (
            <div className={Styles.text}>
              Verifikasi pertama tidak disetujui, kandidat otomatis akan masuk
              ke step <span className={Styles.span}>Decline</span>
            </div>
          ) : (
            <div className={Styles.text}>
              Verifikasi tidak disetujui, kandidat otomatis akan ditandai dengan status <span className={Styles.span}>“Reject”</span>
            </div>
          )}
        </div>
      </div>
      <div className={Styles.buttons}>
        <div
          className={Styles.secondaryButton}
          onClick={() => setShowReject(0)}
        >
          <div className={Styles.text}>Kembali</div>
        </div>
      </div>
    </div>
  );
};

export default RejectSuccessModal;
