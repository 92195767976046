import AutocompleteField from "@Molecule/AutocompleteField";
import InputField, { InputTitle } from "@Molecule/InputField";
import Styles from "./style.module.scss";
import InputDateField from "@Molecule/InputDateField";
// import BMSelector from "@Organism/BusinessSupport/Candidates/CandidateDetails/BMSelector";
// import ContractTypeSelector from "@Organism/BusinessSupport/Candidates/CandidateDetails/ContractTypeSelector";
import AutocompleteV2 from "@Atom/AutocompleteV2";
import { useEffect, useState } from "react";
// import moment from "moment";

export default function FirstStep({
  values,
  setValues,
  sync,
  selectedContractId,
  setSelectedContractId,
  contractList,
  selectedBM,
  setSelectedBM,
  selectedContractType,
  setSelectedContractType,
  selectedClient,
  setSelectedClient,
  selectedPosition,
  setSelectedPosition,
  selectedOrder,
  setSelectedOrder,
}) {
  const handleChangeValues = (newVal, code) => {
    setValues(
      values?.map((obj) => {
        if (obj?.code === code) {
          return {
            ...obj,
            value: newVal,
          };
        }
        return obj;
      })
    );
  };
  // const handleChangeContractId = (newVal) => {
  //   const data = contractList?.contractTemplates?.find(
  //     (el) => el?.name === newVal
  //   );

  //   // console.log(contractList?.contractTemplates);

  //   setSelectedContractId(data);
  // };
  const [valueSearch, setValueSearch] = useState(
    selectedContractId?.name || ""
  );

  useEffect(() => {
    if (valueSearch) {
      const data = contractList?.contractTemplates?.find(
        (obj) => obj?.name?.toLowerCase() === valueSearch?.toLowerCase()
        // (obj) => obj?.name?.toLowerCase()?.includes(valueSearch?.toLowerCase())
      );
      setSelectedContractId(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSelectedContractId, valueSearch]);

  // console.log(values);

  return (
    <div className={Styles.container}>
      <div>
        <InputTitle title={"Klien"} />
        <AutocompleteV2
          value={selectedClient?.name || ""}
          setValue={(newValue) => {
            setSelectedClient((prev) => ({
              ...prev,
              name: newValue,
            }));
          }}
          placeholder={"Pilih Klien"}
          options={[]}
          viewOnly
        />
      </div>

      <div>
        <InputTitle title={"FLB - Nomor Dokumen"} />
        <AutocompleteV2
          value={selectedOrder?.docNumber || ""}
          onClickOption={(newValue) => {
            setSelectedOrder((prev) => ({
              ...prev,
              docNumber: newValue,
            }));
          }}
          viewOnly
          placeholder={"Pilih FLB"}
          options={[]}
          withLabel
          valueLabel={selectedContractType}
        />
      </div>

      <div>
        <InputTitle title={"Posisi"} />
        <AutocompleteV2
          value={selectedPosition?.name || ""}
          setValue={(newValue) => {
            setSelectedPosition((prev) => ({
              ...prev,
              name: newValue,
            }));
          }}
          placeholder={"Pilih Posisi"}
          viewOnly
          options={[]}
          withCustomLabel
          customValueLabel={
            <div className={Styles.labelCustom}>
              <span>Bussiness Manager</span>
              <span>{selectedBM?.name || "-"}</span>
            </div>
          }
        />
      </div>
      {/* <div>
        <BMSelector
          selectedBM={selectedBM}
          setSelectedBM={setSelectedBM}
          data={contractList?.businessManager}
        />
      </div>
      <div>
        <ContractTypeSelector
          selectedContractType={selectedContractType}
          setSelectedContractType={setSelectedContractType}
        />
      </div> */}

      <div>
        <InputTitle title={"Template Kontrak"} required />
        <AutocompleteV2
          value={valueSearch}
          setValue={setValueSearch}
          placeholder={"Selecte template"}
          options={
            contractList?.contractTemplates
              ?.filter((f) => f.name.includes(selectedContractType))
              .map((obj) => obj?.name)
              ?.map((obj) => {
                return {
                  name: obj,
                };
              }) || []
          }
          optionsAtTop
        />

        {/* <AutocompleteField
          title={"Template Kontrak"}
          value={selectedContractId?.name}
          setValue={(newVal) => handleChangeContractId(newVal)}
          searchOptions={
            contractList?.contractTemplates?.filter((f) => f.name.includes(selectedContractType))
              .map((obj) => obj?.name) || []
          }
          optionsAtTop
        /> */}
      </div>
      {valueSearch &&
        values?.length > 0 &&
        values
          ?.filter(
            (obj) =>
              obj?.code !== "{CONTRACT_STARTDATE}" &&
              obj?.code !== "{CONTRACT_ENDDATE}"
          )
          // eslint-disable-next-line array-callback-return
          ?.map((v, i) => {
            if (v?.code?.toLowerCase()?.includes("dob")) {
              return (
                <InputDateField
                  required
                  key={i}
                  title={v?.label}
                  value={v?.value}
                  setValue={(newVal) => handleChangeValues(newVal, v?.code)}
                />
              );
            } else if (v?.code?.toLowerCase()?.includes("pks")) {
              return (
                <AutocompleteField
                  required
                  key={i}
                  title={v?.label}
                  value={v?.value}
                  setValue={(newVal) => handleChangeValues(newVal, v?.code)}
                  searchOptions={sync?.PKSlist?.map((obj) => obj?.value)}
                />
              );
            } else if (v?.code?.toLowerCase()?.includes("position")) {
              // return (
              //     <InputField
              //         key={i}
              //         title={v?.label}
              //         value={v?.value?.toUpperCase()}
              //         onChange={(e) => handleChangeValues(e?.target?.value?.toUpperCase(), v?.code)}
              //         disabled
              //     />
              // );
            } else if (!v?.code?.toLowerCase()?.includes("salary")) {
              return (
                <InputField
                  required
                  key={i}
                  title={v?.label}
                  value={v?.value}
                  onChange={(e) =>
                    handleChangeValues(e?.target?.value, v?.code)
                  }
                  isCurrency={
                    v?.code?.toLowerCase()?.includes("salary") ? true : false
                  }
                />
              );
            } else {
              return <div key={i} />;
            }
          })}
    </div>
  );
}

//<div className={Styles.container}>
//   {
//     values?.map((v, i) => (
//       <InputField
//         title={v?.label}
//         value={v?.value}
//         onChange={(e) => handleChangeValues(e?.target?.value, v?.code)}
//       />
//     ))
//   }
//   <AutocompleteField
//     title={'Vendor'}
//     value={form?.vendor}
//     setValue={(newVal) => handleChangeForm(newVal, 'vendor')}
//     searchOptions={[
//       "vendor 1",
//       "vendor 2",
//     ]}
//   />
//   <InputField
//     title={'NIK/Sales Code'}
//     placeholder={'NIK/Sales Code'}
//     value={form?.salesCode}
//     onChange={(e) => handleChangeForm(e?.target?.value, 'salesCode')}
//   />
//   <AutocompleteField
//     title={'Client'}
//     value={form?.client}
//     setValue={(newVal) => handleChangeForm(newVal, 'client')}
//     searchOptions={[
//       "client 1",
//       "client 2",
//     ]}
//   />
//   <AutocompleteField
//     title={'PKS'}
//     value={form?.pks}
//     setValue={(newVal) => handleChangeForm(newVal, 'pks')}
//     searchOptions={[
//       "pks 1",
//       "pks 2",
//     ]}
//   />
//   <AutocompleteField
//     title={'Posisi'}
//     value={form?.position}
//     setValue={(newVal) => handleChangeForm(newVal, 'position')}
//     searchOptions={[
//       "position 1",
//       "position 2",
//     ]}
//   />
//   <AutocompleteField
//     title={'Jabatan'}
//     value={form?.jobTitle}
//     setValue={(newVal) => handleChangeForm(newVal, 'jobTitle')}
//     searchOptions={[
//       "job 1",
//       "job 2",
//     ]}
//   />
//   <AutocompleteField
//     title={'UMK'}
//     value={form?.umk}
//     setValue={(newVal) => handleChangeForm(newVal, 'umk')}
//     searchOptions={[
//       "umk 1",
//       "umk 2",
//     ]}
//   />
//   <AutocompleteField
//     title={'Lokasi'}
//     value={form?.location}
//     setValue={(newVal) => handleChangeForm(newVal, 'location')}
//     searchOptions={[
//       "location 1",
//       "location 2",
//     ]}
//   />
//   <AutocompleteField
//     title={'Template Kontrak'}
//     value={form?.template}
//     setValue={(newVal) => handleChangeForm(newVal, 'template')}
//     searchOptions={[
//       "template 1",
//       "template 2",
//     ]}
//   />
//   <InputField
//     title={'Alamat penempatan'}
//     value={form?.placement}
//     onChange={(e) => handleChangeForm(e?.target?.value, 'placement')}
//     placeholder={'Alamat penempatan'}
//   />
// </div>
