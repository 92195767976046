import InputField from "@Molecule/InputField";
import Styles from "./styles.module.scss";
import { useEffect, useState } from "react";
import InputDateField from "@Molecule/InputDateField";
import AutocompleteField from "@Molecule/AutocompleteField";
import CheckboxField from "@Molecule/CheckboxField";
import { validateEmail } from "@Helpers/validateEmail";

export default function FirstStep({
  options,
  dataStepOne,
  setDataStepone,
  disableInput,
  isPhoneVerified,
  setIsPhoneVerified,
  loadingPhoneVerify,
  onClickPhoneVerification,
  disablePhoneVerification,
  errorField,
}) {
  const [name, setName] = useState(dataStepOne?.name);
  const [birthPlace, setBirthPlace] = useState(dataStepOne?.birthPlace);
  const [gender, setGender] = useState(dataStepOne?.gender);
  const [religion, setReligion] = useState(dataStepOne?.religion);
  const [bloodType, setBloodType] = useState(dataStepOne?.bloodType);
  const [birthDateTemp, setBirthDateTemp] = useState(
    dataStepOne?.birthDateTemp
  );
  const [height, setHeight] = useState(dataStepOne?.height);
  const [weight, setWeight] = useState(dataStepOne?.weight);
  const [maritalStatus, setMaritalStatus] = useState(
    dataStepOne?.maritalStatus
  );
  const [KK, setKK] = useState(dataStepOne?.KK);
  const [NIK, setNIK] = useState(dataStepOne?.NIK);
  const [lifetimeIdCard, setLifetimeIdCard] = useState(
    dataStepOne?.lifetimeIdCard
  );
  const [idCardPeriodTemp, setIdCardPeriodTemp] = useState(
    dataStepOne?.idCardPeriodTemp
  );
  const [noTaxNumber, setNoTaxNumber] = useState(dataStepOne?.noTaxNumber);
  const [taxNumber, setTaxNumber] = useState(dataStepOne?.taxNumber);
  const [email, setEmail] = useState(dataStepOne?.email);
  const [phoneNumber, setPhoneNumber] = useState(dataStepOne?.phoneNumber);
  const [biologicalMotherName, setBiologicalMotherName] = useState(
    dataStepOne?.biologicalMotherName
  );
  const [idCardAddress, setIdCardAddress] = useState(
    dataStepOne?.idCardAddress
  );
  const [idCardRt, setIdCardRt] = useState(dataStepOne?.idCardRt);
  const [idCardRw, setIdCardRw] = useState(dataStepOne?.idCardRw);
  const [idCardWard, setIdCardWard] = useState(dataStepOne?.idCardWard);
  const [idCardSubdistrict, setIdCardSubdistrict] = useState(
    dataStepOne?.idCardSubdistrict
  );
  const [idCardRegencyTemp, setIdCardRegencyTemp] = useState(
    dataStepOne?.idCardRegencyTemp
  );
  const [idCardProvinceTemp, setIdCardProvinceTemp] = useState(
    dataStepOne?.idCardProvinceTemp
  );
  const [idCardPostal, setIdCardPostal] = useState(dataStepOne?.idCardPostal);
  const [isDomicileTheSame, setIsDomicileTheSame] = useState(
    dataStepOne?.isDomicileTheSame
  );
  const [domicileAddress, setDomicileAddress] = useState(
    dataStepOne?.domicileAddress
  );
  const [domicileRt, setDomicileRt] = useState(dataStepOne?.domicileRt);
  const [domicileRw, setDomicileRw] = useState(dataStepOne?.domicileRw);
  const [domicileWard, setDomicileWard] = useState(dataStepOne?.domicileWard);
  const [domicileSubdistrict, setDomicileSubdistrict] = useState(
    dataStepOne?.domicileSubdistrict
  );
  const [domicileRegencyTemp, setDomicileRegencyTemp] = useState(
    dataStepOne?.domicileRegencyTemp
  );
  const [domicileProvinceTemp, setDomicileProvinceTemp] = useState(
    dataStepOne?.domicileProvinceTemp
  );
  const [domicilePostal, setDomicilePostal] = useState(
    dataStepOne?.domicilePostal
  );
  const [educationTemp, setEducationTemp] = useState(
    dataStepOne?.educationTemp
  );
  const [institution, setInstitution] = useState(dataStepOne?.institution);
  const [faculty, setFaculty] = useState(dataStepOne?.faculty);
  const [gpa, setGpa] = useState(dataStepOne?.gpa);
  const [emergencyContactName, setEmergencyContactName] = useState(
    dataStepOne?.emergencyContactName
  );
  const [
    emergencyContactRelationshipTemp,
    setEmergencyContactRelationshipTemp,
  ] = useState(dataStepOne?.emergencyContactRelationshipTemp);
  const [emergencyPhoneNumber, setEmergencyPhoneNumber] = useState(
    dataStepOne?.emergencyPhoneNumber
  );
  const [emergencyAddress, setEmergencyAddress] = useState(
    dataStepOne?.emergencyAddress
  );

  const [sameHouseFamilyContactName, setSameHouseFamilyContactName] = useState(
    dataStepOne?.sameHouseFamilyContactName
  );
  const [
    sameHouseFamilyContactRelationshipTemp,
    setSameHouseFamilyContactRelationshipTemp,
  ] = useState(dataStepOne?.sameHouseFamilyContactRelationshipTemp);
  const [
    sameHouseFamilyContactPhoneNumber,
    setSameHouseFamilyContactPhoneNumber,
  ] = useState(dataStepOne?.sameHouseFamilyContactPhoneNumber);
  const [sameHouseFamilyContactAddress, setSameHouseFamilyContactAddress] =
    useState(dataStepOne?.sameHouseFamilyContactAddress);

  const [differentHouseFamilyContactName, setDifferentHouseFamilyContactName] =
    useState(dataStepOne?.differentHouseFamilyContactName);
  const [
    differentHouseFamilyContactRelationshipTemp,
    setDifferentHouseFamilyContactRelationshipTemp,
  ] = useState(dataStepOne?.differentHouseFamilyContactRelationshipTemp);
  const [
    differentHouseFamilyContactPhoneNumber,
    setDifferentHouseFamilyContactPhoneNumber,
  ] = useState(dataStepOne?.differentHouseFamilyContactPhoneNumber);
  const [
    differentHouseFamilyContactAddress,
    setDifferentHouseFamilyContactAddress,
  ] = useState(dataStepOne?.differentHouseFamilyContactAddress);

  const [bankNameTemp, setBankNameTemp] = useState(dataStepOne?.bankNameTemp);
  const [bankAccountNumber, setBankAccountNumber] = useState(
    dataStepOne?.bankAccountNumber
  );
  const [roCode, setRoCode] = useState(dataStepOne?.roCode);
  const [nearestHealthFacility, setNearestHealthFacility] = useState(
    dataStepOne?.nearestHealthFacility
  );
  const [fbUsername, setFbUsername] = useState(dataStepOne?.fbUsername);
  const [igUsername, setIgUsername] = useState(dataStepOne?.igUsername);
  const [twitterUsername, setTwitterUsername] = useState(
    dataStepOne?.twitterUsername
  );
  const [linkedinUsername, setLinkedinUsername] = useState(
    dataStepOne?.linkedinUsername
  );

  useEffect(() => {
    setName(dataStepOne?.name || "");
    setBirthPlace(dataStepOne?.birthPlace || "");
    setGender(dataStepOne?.gender || "");
    setReligion(dataStepOne?.religion || "");
    setBloodType(dataStepOne?.bloodType || "");
    setBirthDateTemp(dataStepOne?.birthDateTemp || "");
    setHeight(dataStepOne?.height || "");
    setWeight(dataStepOne?.weight || "");
    setMaritalStatus(dataStepOne?.maritalStatus || "");
    setKK(dataStepOne?.KK || "");
    setNIK(dataStepOne?.NIK || "");
    setLifetimeIdCard(dataStepOne?.lifetimeIdCard || false);
    setIdCardPeriodTemp(dataStepOne?.idCardPeriodTemp || "");
    setNoTaxNumber(dataStepOne?.noTaxNumber || false);
    setTaxNumber(dataStepOne?.taxNumber || "");
    setEmail(dataStepOne?.email || "");
    setPhoneNumber(dataStepOne?.phoneNumber || "");
    setBiologicalMotherName(dataStepOne?.biologicalMotherName || "");
    setIdCardAddress(dataStepOne?.idCardAddress || "");
    setIdCardRt(dataStepOne?.idCardRt || "");
    setIdCardRw(dataStepOne?.idCardRw || "");
    setIdCardWard(dataStepOne?.idCardWard || "");
    setIdCardSubdistrict(dataStepOne?.idCardSubdistrict || "");
    setIdCardRegencyTemp(dataStepOne?.idCardRegencyTemp || "");
    setIdCardProvinceTemp(dataStepOne?.idCardProvinceTemp || "");
    setIdCardPostal(dataStepOne?.idCardPostal || "");
    setIsDomicileTheSame(dataStepOne?.isDomicileTheSame || false);
    setDomicileAddress(dataStepOne?.domicileAddress || "");
    setDomicileRt(dataStepOne?.domicileRt || "");
    setDomicileRw(dataStepOne?.domicileRw || "");
    setDomicileWard(dataStepOne?.domicileWard || "");
    setDomicileSubdistrict(dataStepOne?.domicileSubdistrict || "");
    setDomicileRegencyTemp(dataStepOne?.domicileRegencyTemp || "");
    setDomicileProvinceTemp(dataStepOne?.domicileProvinceTemp || "");
    setDomicilePostal(dataStepOne?.domicilePostal || "");
    setEducationTemp(dataStepOne?.educationTemp || "");
    setInstitution(dataStepOne?.institution || "");
    setFaculty(dataStepOne?.faculty || "");
    setGpa(dataStepOne?.gpa || "");
    setEmergencyContactName(dataStepOne?.emergencyContactName || "");
    setEmergencyContactRelationshipTemp(
      dataStepOne?.emergencyContactRelationshipTemp || ""
    );
    setEmergencyPhoneNumber(dataStepOne?.emergencyPhoneNumber || "");
    setEmergencyAddress(dataStepOne?.emergencyAddress || "");
    setSameHouseFamilyContactName(
      dataStepOne?.sameHouseFamilyContactName || ""
    );
    setSameHouseFamilyContactRelationshipTemp(
      dataStepOne?.sameHouseFamilyContactRelationshipTemp || ""
    );
    setSameHouseFamilyContactPhoneNumber(
      dataStepOne?.sameHouseFamilyContactPhoneNumber || ""
    );
    setSameHouseFamilyContactAddress(
      dataStepOne?.sameHouseFamilyContactAddress || ""
    );
    setDifferentHouseFamilyContactName(
      dataStepOne?.differentHouseFamilyContactName || ""
    );
    setDifferentHouseFamilyContactRelationshipTemp(
      dataStepOne?.differentHouseFamilyContactRelationshipTemp || ""
    );
    setDifferentHouseFamilyContactPhoneNumber(
      dataStepOne?.differentHouseFamilyContactPhoneNumber || ""
    );
    setDifferentHouseFamilyContactAddress(
      dataStepOne?.differentHouseFamilyContactAddress || ""
    );
    setBankNameTemp(dataStepOne?.bankNameTemp || "");
    setBankAccountNumber(dataStepOne?.bankAccountNumber || "");
    setRoCode(dataStepOne?.roCode || "");
    setNearestHealthFacility(dataStepOne?.nearestHealthFacility || "");
    setFbUsername(dataStepOne?.fbUsername || "");
    setIgUsername(dataStepOne?.igUsername || "");
    setTwitterUsername(dataStepOne?.twitterUsername || "");
    setLinkedinUsername(dataStepOne?.linkedinUsername || "");
  }, [dataStepOne]);

  useEffect(() => {
    const data = {
      name: name || "",
      birthPlace: birthPlace || "",
      gender: gender || "",
      religion: religion || "",
      bloodType: bloodType || "",
      birthDateTemp: birthDateTemp || "",
      height: height || "",
      weight: weight || "",
      maritalStatus: maritalStatus || "",
      KK: KK || "",
      NIK: NIK || "",
      lifetimeIdCard: lifetimeIdCard || false,
      idCardPeriodTemp: idCardPeriodTemp || "",
      noTaxNumber: noTaxNumber || false,
      taxNumber: taxNumber || "",
      email: email || "",
      phoneNumber: phoneNumber || "",
      biologicalMotherName: biologicalMotherName || "",
      idCardAddress: idCardAddress || "",
      idCardRt: idCardRt || "",
      idCardRw: idCardRw || "",
      idCardWard: idCardWard || "",
      idCardSubdistrict: idCardSubdistrict || "",
      idCardRegencyTemp: idCardRegencyTemp || "",
      idCardProvinceTemp: idCardProvinceTemp || "",
      idCardPostal: idCardPostal || "",
      isDomicileTheSame: isDomicileTheSame || false,
      domicileAddress: domicileAddress || "",
      domicileRt: domicileRt || "",
      domicileRw: domicileRw || "",
      domicileWard: domicileWard || "",
      domicileSubdistrict: domicileSubdistrict || "",
      domicileRegencyTemp: domicileRegencyTemp || "",
      domicileProvinceTemp: domicileProvinceTemp || "",
      domicilePostal: domicilePostal || "",
      educationTemp: educationTemp || "",
      institution: institution || "",
      faculty: faculty || "",
      gpa: gpa || "",
      emergencyContactName: emergencyContactName || "",
      emergencyContactRelationshipTemp: emergencyContactRelationshipTemp || "",
      emergencyPhoneNumber: emergencyPhoneNumber || "",
      emergencyAddress: emergencyAddress || "",
      sameHouseFamilyContactName: sameHouseFamilyContactName || "",
      sameHouseFamilyContactRelationshipTemp:
        sameHouseFamilyContactRelationshipTemp || "",
      sameHouseFamilyContactPhoneNumber:
        sameHouseFamilyContactPhoneNumber || "",
      sameHouseFamilyContactAddress: sameHouseFamilyContactAddress || "",
      differentHouseFamilyContactName: differentHouseFamilyContactName || "",
      differentHouseFamilyContactRelationshipTemp:
        differentHouseFamilyContactRelationshipTemp || "",
      differentHouseFamilyContactPhoneNumber:
        differentHouseFamilyContactPhoneNumber || "",
      differentHouseFamilyContactAddress:
        differentHouseFamilyContactAddress || "",
      bankNameTemp: bankNameTemp || "",
      bankAccountNumber: bankAccountNumber || "",
      roCode: roCode || "",
      nearestHealthFacility: nearestHealthFacility || "",
      fbUsername: fbUsername || "",
      igUsername: igUsername || "",
      twitterUsername: twitterUsername || "",
      linkedinUsername: linkedinUsername || "",
    };
    setDataStepone(data);
  }, [
    setDataStepone,
    name,
    birthPlace,
    gender,
    religion,
    bloodType,
    birthDateTemp,
    height,
    weight,
    maritalStatus,
    KK,
    NIK,
    lifetimeIdCard,
    idCardPeriodTemp,
    noTaxNumber,
    taxNumber,
    email,
    phoneNumber,
    biologicalMotherName,
    idCardAddress,
    idCardRt,
    idCardRw,
    idCardWard,
    idCardSubdistrict,
    idCardRegencyTemp,
    idCardProvinceTemp,
    idCardPostal,
    isDomicileTheSame,
    domicileAddress,
    domicileRt,
    domicileRw,
    domicileWard,
    domicileSubdistrict,
    domicileRegencyTemp,
    domicileProvinceTemp,
    domicilePostal,
    educationTemp,
    institution,
    faculty,
    gpa,
    emergencyContactName,
    emergencyContactRelationshipTemp,
    emergencyPhoneNumber,
    emergencyAddress,
    sameHouseFamilyContactName,
    sameHouseFamilyContactRelationshipTemp,
    sameHouseFamilyContactPhoneNumber,
    sameHouseFamilyContactAddress,
    differentHouseFamilyContactName,
    differentHouseFamilyContactRelationshipTemp,
    differentHouseFamilyContactPhoneNumber,
    differentHouseFamilyContactAddress,
    bankNameTemp,
    bankAccountNumber,
    roCode,
    nearestHealthFacility,
    fbUsername,
    igUsername,
    twitterUsername,
    linkedinUsername,
  ]);

  useEffect(() => {
    if (lifetimeIdCard === true) {
      setDataStepone((f) => ({
        ...f,
        idCardPeriodTemp: "",
      }));
      setIdCardPeriodTemp("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lifetimeIdCard, setDataStepone]);

  useEffect(() => {
    if (noTaxNumber === true) {
      setDataStepone((f) => ({
        ...f,
        taxNumber: "",
      }));
      setTaxNumber("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noTaxNumber, setDataStepone]);

  useEffect(() => {
    if (isDomicileTheSame === true) {
      setDomicileAddress("");
      setDomicileRt("");
      setDomicileRw("");
      setDomicileWard("");
      setDomicileSubdistrict("");
      setDomicileRegencyTemp("");
      setDomicileProvinceTemp("");
      setDomicilePostal("");
      setDataStepone((f) => ({
        ...f,
        domicileAddress: "",
        domicileRt: "",
        domicileRw: "",
        domicileWard: "",
        domicileSubdistrict: "",
        domicileRegencyTemp: "",
        domicileProvinceTemp: "",
        domicilePostal: "",
      }));
    }
  }, [isDomicileTheSame, setDataStepone]);

  return (
    <div className={Styles.container}>
      <div className={Styles.section}>
        <span>Personal Data</span>
        <div className={Styles.formSection}>
          <InputField
            title={"Nomor KTP (16 Digit)"}
            placeholder={"Nomor KTP"}
            value={NIK}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re?.test(e.target.value)) {
                if (e.target.value.length < 17) {
                  setNIK(e.target.value);
                  setIsPhoneVerified(false);
                }
              }
            }}
            required
            disabled
            // disabled={disableInput}
            isError={errorField && (!NIK || NIK?.length < 16)}
            errorText={"*Nomor KK belum diisi atau belum 16 digit"}
          />
          <InputField
            type="phoneVerification"
            value={phoneNumber}
            title="Nomor Whatsapp"
            placeholder={"8***"}
            onChange={(e) => {
              setPhoneNumber(e.target.value);
              setIsPhoneVerified(false);
            }}
            required
            onClick={onClickPhoneVerification}
            isLoading={loadingPhoneVerify}
            isVerified={isPhoneVerified}
            disabled={disablePhoneVerification}
            isError={
              !disablePhoneVerification &&
              errorField &&
              (!phoneNumber || !isPhoneVerified)
            }
            errorText={"*Nomor HP belum diisi atau di verifikasi"}
          />
          <InputField
            title={"Nama"}
            placeholder={"Nama sesuai KTP"}
            value={name?.toUpperCase()}
            onChange={(e) => setName(e.target.value?.toUpperCase())}
            required
            disabled
            isError={!disableInput && errorField && !name}
            errorText={"*Nama belum diisi"}
          />
          <InputField
            title={"Tempat Lahir"}
            placeholder={"Tempat lahir"}
            required
            value={birthPlace?.toUpperCase()}
            onChange={(e) => setBirthPlace(e.target.value?.toUpperCase())}
            disabled={disableInput}
            isError={!disableInput && errorField && !birthPlace}
            errorText={"*Tempat lahir belum diisi"}
          />

          <InputDateField
            title={"Tanggal Lahir"}
            placeholder={"Pilih tanggal"}
            iconColor={"#1571DE"}
            value={birthDateTemp}
            setValue={setBirthDateTemp}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !birthDateTemp}
            errorText={"*Tanggal lahir belum diisi"}
          />

          <AutocompleteField
            title={"Jenis Kelamin"}
            placeholder={"Pilih salah satu"}
            searchOptions={options?.genders}
            value={gender}
            setValue={setGender}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !gender}
            errorText={"*Jenis kelamin belum diisi"}
          />

          <AutocompleteField
            title={"Agama"}
            placeholder={"Pilih agama"}
            searchOptions={options?.religions}
            value={religion}
            setValue={setReligion}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !religion}
            errorText={"*Agama belum diisi"}
          />

          <AutocompleteField
            title={"Golongan Darah"}
            placeholder={"Pilih salah satu"}
            searchOptions={options?.bloodTypes}
            value={bloodType}
            setValue={setBloodType}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !bloodType}
            errorText={"*Golongan darah belum diisi"}
          />

          <InputField
            title={"Tinggi Badan"}
            placeholder={"Tinggi badan anda"}
            value={height}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re?.test(e.target.value)) {
                setHeight(e.target.value);
              }
            }}
            floatingEndAdornment={
              <span className={Styles.floatingText}>Cm</span>
            }
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !height}
            errorText={"*Tinggi badan belum diisi"}
          />

          <InputField
            title={"Berat Badan"}
            placeholder={"Berat badan anda"}
            value={weight}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re?.test(e.target.value)) {
                setWeight(e.target.value);
              }
            }}
            floatingEndAdornment={
              <span className={Styles.floatingText}>Kg</span>
            }
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !weight}
            errorText={"*Berat badan belum diisi"}
          />

          <AutocompleteField
            title={"Status Kawin"}
            placeholder={"Pilih salah satu"}
            searchOptions={options?.maritalStatus}
            value={maritalStatus}
            setValue={setMaritalStatus}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !maritalStatus}
            errorText={"*Status Kawin belum diisi"}
          />

          <InputField
            title={"Nomor Kartu Keluarga (16 Digit)"}
            placeholder={"Nomor KK"}
            value={KK}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re?.test(e.target.value)) {
                if (e.target.value.length < 17) {
                  setKK(e.target.value);
                }
              }
            }}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && (!KK || KK?.length < 16)}
            errorText={"*Nomor KK belum diisi atau belum 16 digit"}
          />

          <div className={Styles.stackedField}>
            <InputDateField
              title={"Masa Berlaku KTP"}
              placeholder={"Pilih tanggal"}
              value={idCardPeriodTemp || ""}
              setValue={setIdCardPeriodTemp}
              iconColor={"#1571DE"}
              disabled={lifetimeIdCard || disableInput}
              required
              isError={
                !disableInput &&
                errorField &&
                !idCardPeriodTemp &&
                !lifetimeIdCard
              }
              errorText={"*Masa berlaku KTP belum diisi"}
            />
            <CheckboxField
              title="Seumur hidup"
              value={lifetimeIdCard}
              setValue={setLifetimeIdCard}
              required
              disabled={disableInput}
            />
          </div>

          <div className={Styles.stackedField}>
            <InputField
              title={"Nomor NPWP"}
              placeholder={"Nomor NPWP"}
              value={taxNumber}
              disabled={noTaxNumber || disableInput}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === "" || re?.test(e.target.value)) {
                  setTaxNumber(e.target.value);
                }
              }}
              required
              isError={
                !disableInput && errorField && !taxNumber && !noTaxNumber
              }
              errorText={"*Nomor NPWP belum diisi"}
            />
            <CheckboxField
              title="Tidak memiliki NPWP"
              value={noTaxNumber}
              setValue={setNoTaxNumber}
              required
              disabled={disableInput}
            />
          </div>

          <InputField
            title={"Email"}
            placeholder={"example@mail.com"}
            value={email}
            onChange={(e) => setEmail(e.target.value?.toLowerCase()?.trim())}
            required
            disabled={disableInput}
            isError={
              !disableInput && errorField && (!email || !validateEmail(email))
            }
            errorText={"*Email belum diisi atau tidak sesuai format"}
          />

          <InputField
            title={"Nama Ibu Kandung"}
            placeholder={"Nama ibu kandung anda"}
            value={biologicalMotherName?.toUpperCase()}
            onChange={(e) =>
              setBiologicalMotherName(e.target.value?.toUpperCase())
            }
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !biologicalMotherName}
            errorText={"*Nama ibu belum diisi"}
          />
        </div>
      </div>

      <div className={Styles.section}>
        <span>Alamat</span>
        <div className={Styles.desc}>Alamat KTP</div>
        <div className={Styles.formAddress}>
          <InputField
            title="Alamat "
            type={"address"}
            value={idCardAddress?.toUpperCase()}
            placeholder={"Tulis alamat anda"}
            onChange={(e) => setIdCardAddress(e.target.value?.toUpperCase())}
            hideIcon
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !idCardAddress}
            errorText={"*Alamat belum diisi"}
          />
        </div>

        <div className={Styles.formSection}>
          <InputField
            title={"RT"}
            placeholder={"000"}
            value={idCardRt}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                setIdCardRt(e.target.value);
              }
            }}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !idCardRt}
            errorText={"*RT belum diisi"}
          />
          <InputField
            title={"RW"}
            placeholder={"000"}
            value={idCardRw}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                setIdCardRw(e.target.value);
              }
            }}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !idCardRw}
            errorText={"*RW belum diisi"}
          />

          <InputField
            title={"Kelurahan"}
            placeholder={"Tulis kelurahan anda"}
            value={idCardWard?.toUpperCase()}
            onChange={(e) => setIdCardWard(e.target.value.toUpperCase())}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !idCardWard}
            errorText={"*Kelurahan belum diisi"}
          />

          <InputField
            title={"Kecamatan"}
            placeholder={"Tulis kecamatan anda"}
            value={idCardSubdistrict?.toUpperCase()}
            onChange={(e) =>
              setIdCardSubdistrict(e.target.value?.toUpperCase())
            }
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !idCardSubdistrict}
            errorText={"*Kecamatan belum diisi"}
          />

          <AutocompleteField
            title={"Kabupaten/Kota"}
            placeholder={"Pilih kabupaten"}
            searchOptions={options?.cities}
            value={idCardRegencyTemp}
            setValue={setIdCardRegencyTemp}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !idCardRegencyTemp}
            errorText={"*Kabupaten/Kota belum diisi"}
          />
          <AutocompleteField
            title={"Provinsi"}
            placeholder={"Pilih provinsi"}
            searchOptions={options?.provinces}
            value={idCardProvinceTemp}
            setValue={setIdCardProvinceTemp}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !idCardProvinceTemp}
            errorText={"*Provinsi belum diisi"}
          />

          <InputField
            title={"Kode Pos"}
            placeholder={"000"}
            value={idCardPostal}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                setIdCardPostal(e.target.value);
              }
            }}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !idCardPostal}
            errorText={"*Kode Pos belum diisi"}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "."].includes(evt.key) &&
              evt.preventDefault()
            }
            type="number"
          />
        </div>
        <div className={Styles.desc}>Alamat Domisili</div>
        <div className={Styles.checkboxWrapper}>
          <CheckboxField
            title={"Alamat domisili sama dengan alamat KTP"}
            value={isDomicileTheSame}
            setValue={setIsDomicileTheSame}
            disabled={disableInput}
          />
        </div>

        <div className={Styles.formAddress}>
          <InputField
            title="Alamat Domisili"
            type={"address"}
            value={domicileAddress?.toUpperCase()}
            placeholder={"Tulis alamat anda"}
            onChange={(e) => setDomicileAddress(e.target.value?.toUpperCase())}
            hideIcon
            required
            disabled={isDomicileTheSame || disableInput}
            isError={
              !disableInput &&
              errorField &&
              !domicileAddress &&
              !isDomicileTheSame
            }
            errorText={"*Alamat belum diisi"}
          />
        </div>

        <div className={Styles.formSection}>
          <InputField
            title={"RT"}
            placeholder={"000"}
            value={domicileRt}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                setDomicileRt(e.target.value);
              }
            }}
            required
            disabled={isDomicileTheSame || disableInput}
            isError={
              !disableInput && errorField && !domicileRt && !isDomicileTheSame
            }
            errorText={"*RT belum diisi"}
          />
          <InputField
            title={"RW"}
            placeholder={"000"}
            value={domicileRw}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                setDomicileRw(e.target.value);
              }
            }}
            required
            disabled={isDomicileTheSame || disableInput}
            isError={
              !disableInput && errorField && !domicileRw && !isDomicileTheSame
            }
            errorText={"*RW belum diisi"}
          />

          <InputField
            title={"Kelurahan"}
            placeholder={"Tulis kelurahan anda"}
            value={domicileWard?.toUpperCase()}
            onChange={(e) => setDomicileWard(e.target.value?.toUpperCase())}
            required
            disabled={isDomicileTheSame || disableInput}
            isError={
              !disableInput && errorField && !domicileWard && !isDomicileTheSame
            }
            errorText={"*Kelurahan belum diisi"}
          />

          <InputField
            title={"Kecamatan"}
            placeholder={"Tulis kecamatan anda"}
            value={domicileSubdistrict?.toUpperCase()}
            onChange={(e) =>
              setDomicileSubdistrict(e.target.value?.toUpperCase())
            }
            required
            disabled={isDomicileTheSame || disableInput}
            isError={
              !disableInput &&
              errorField &&
              !domicileSubdistrict &&
              !isDomicileTheSame
            }
            errorText={"*Kecamatan belum diisi"}
          />

          <AutocompleteField
            title={"Kabupaten/Kota"}
            placeholder={"Pilih kabupaten"}
            searchOptions={options?.cities}
            value={domicileRegencyTemp}
            setValue={setDomicileRegencyTemp}
            iconColor={"#1571DE"}
            required
            disabled={isDomicileTheSame || disableInput}
            isError={
              !disableInput &&
              errorField &&
              !domicileRegencyTemp &&
              !isDomicileTheSame
            }
            errorText={"*Kabupaten/Kota belum diisi"}
          />
          <AutocompleteField
            title={"Provinsi"}
            placeholder={"Pilih provinsi"}
            searchOptions={options?.provinces}
            value={domicileProvinceTemp}
            setValue={setDomicileProvinceTemp}
            iconColor={"#1571DE"}
            required
            disabled={isDomicileTheSame || disableInput}
            isError={
              !disableInput &&
              errorField &&
              !domicileProvinceTemp &&
              !isDomicileTheSame
            }
            errorText={"*Provinsi belum diisi"}
          />

          <InputField
            title={"Kode Pos"}
            placeholder={"000"}
            value={domicilePostal}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                setDomicilePostal(e.target.value);
              }
            }}
            required
            disabled={isDomicileTheSame || disableInput}
            isError={
              !disableInput &&
              errorField &&
              !domicilePostal &&
              !isDomicileTheSame
            }
            errorText={"*Kode Pos belum diisi"}
            onKeyDown={(evt) =>
              ["e", "E", "+", "-", "."].includes(evt.key) &&
              evt.preventDefault()
            }
            type="number"
          />
        </div>
      </div>

      <div className={Styles.section}>
        <span>Pendidikan Terakhir</span>
        <div className={Styles.formSection}>
          <AutocompleteField
            title={"Jenis Pendidikan"}
            placeholder={"Pilih jenis pendidikan"}
            searchOptions={options?.educations}
            value={educationTemp}
            setValue={setEducationTemp}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !educationTemp}
            errorText={"*Jenis Pendidikan belum diisi"}
          />
          <InputField
            title={"Nama Sekolah/Universitas"}
            placeholder={"Nama Sekolah/Universitas"}
            value={institution?.toUpperCase()}
            onChange={(e) => setInstitution(e.target.value?.toUpperCase())}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !institution}
            errorText={"*Nama belum diisi"}
          />
          <InputField
            title={"Fakultas/Jurusan"}
            placeholder={"Fakultas/Jurusan"}
            value={faculty?.toUpperCase()}
            onChange={(e) => setFaculty(e.target.value?.toUpperCase())}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !faculty}
            errorText={"*Fakultas/Jurusan belum diisi"}
          />
          <InputField
            title={"IPK/Nilai Akhir"}
            placeholder={"0.00"}
            value={gpa}
            onChange={(e) => {
              const allowedCharacters = /^[0-9.]*$/;
              if (allowedCharacters.test(e.target.value)) {
                setGpa(e.target.value);
              }
            }}
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !gpa}
            errorText={"*IPK/Nilai Akhir belum diisi"}
          />
        </div>
      </div>

      <div className={Styles.section}>
        <span>Kontak</span>
        <div className={Styles.desc}>Kontak Darurat</div>
        <div className={Styles.formSection}>
          <InputField
            title={"Nama"}
            placeholder={"Nama pemiliki kontak"}
            value={emergencyContactName?.toUpperCase()}
            onChange={(e) =>
              setEmergencyContactName(e.target.value?.toUpperCase())
            }
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !emergencyContactName}
            errorText={"*Nama belum diisi"}
          />
          <AutocompleteField
            title={"Hubungan Dengan Pemilik Kontak"}
            placeholder={"Pilih"}
            searchOptions={options?.relationships}
            value={emergencyContactRelationshipTemp}
            setValue={setEmergencyContactRelationshipTemp}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={
              !disableInput && errorField && !emergencyContactRelationshipTemp
            }
            errorText={"*Hubungan belum diisi"}
          />
          <InputField
            title={"Nomor HP"}
            placeholder={"8***"}
            value={emergencyPhoneNumber}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (
                e.target.value === "" ||
                (re.test(e.target.value) && e.target.value.startsWith("8"))
              ) {
                setEmergencyPhoneNumber(e.target.value);
              }
            }}
            floatingStartAdornment={
              <span className={Styles.floatingText}>+62</span>
            }
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !emergencyPhoneNumber}
            errorText={"*Nomor HP belum diisi"}
          />
        </div>
        <div className={Styles.formAddress}>
          <InputField
            title="Alamat "
            type={"address"}
            value={emergencyAddress?.toUpperCase()}
            placeholder={"Tulis alamat pemilik kontak"}
            onChange={(e) => setEmergencyAddress(e.target.value?.toUpperCase())}
            hideIcon
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !emergencyAddress}
            errorText={"*Alamat belum diisi"}
          />
        </div>

        <div className={Styles.desc}>Kontak Keluarga Serumah</div>
        <div className={Styles.formSection}>
          <InputField
            title={"Nama"}
            placeholder={"Nama pemiliki kontak"}
            value={sameHouseFamilyContactName?.toUpperCase()}
            onChange={(e) =>
              setSameHouseFamilyContactName(e.target.value?.toUpperCase())
            }
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !sameHouseFamilyContactName}
            errorText={"*Nama belum diisi"}
          />
          <AutocompleteField
            title={"Hubungan Dengan Pemilik Kontak"}
            placeholder={"Pilih"}
            searchOptions={options?.relationships}
            value={sameHouseFamilyContactRelationshipTemp}
            setValue={setSameHouseFamilyContactRelationshipTemp}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={
              !disableInput &&
              errorField &&
              !sameHouseFamilyContactRelationshipTemp
            }
            errorText={"*Hubungan belum diisi"}
          />
          <InputField
            title={"Nomor HP"}
            placeholder={"8***"}
            value={sameHouseFamilyContactPhoneNumber}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (
                e.target.value === "" ||
                (re.test(e.target.value) && e.target.value.startsWith("8"))
              ) {
                setSameHouseFamilyContactPhoneNumber(e.target.value);
              }
            }}
            floatingStartAdornment={
              <span className={Styles.floatingText}>+62</span>
            }
            required
            disabled={disableInput}
            isError={
              !disableInput && errorField && !sameHouseFamilyContactPhoneNumber
            }
            errorText={"*Nomor HP belum diisi"}
          />
        </div>
        <div className={Styles.formAddress}>
          <InputField
            title="Alamat "
            type={"address"}
            value={sameHouseFamilyContactAddress?.toUpperCase()}
            placeholder={"Tulis alamat pemilik kontak"}
            onChange={(e) =>
              setSameHouseFamilyContactAddress(e.target.value?.toUpperCase())
            }
            hideIcon
            required
            disabled={disableInput}
            isError={
              !disableInput && errorField && !sameHouseFamilyContactAddress
            }
            errorText={"*Alamat belum diisi"}
          />
        </div>

        <div className={Styles.desc}>Kontak Keluarga Tidak Serumah</div>
        <div className={Styles.formSection}>
          <InputField
            title={"Nama"}
            placeholder={"Nama pemiliki kontak"}
            value={differentHouseFamilyContactName?.toUpperCase()}
            onChange={(e) =>
              setDifferentHouseFamilyContactName(e.target.value?.toUpperCase())
            }
            required
            disabled={disableInput}
            isError={
              !disableInput && errorField && !differentHouseFamilyContactName
            }
            errorText={"*Nama belum diisi"}
          />
          <AutocompleteField
            title={"Hubungan Dengan Pemilik Kontak"}
            placeholder={"Pilih"}
            searchOptions={options?.relationships}
            value={differentHouseFamilyContactRelationshipTemp}
            setValue={setDifferentHouseFamilyContactRelationshipTemp}
            iconColor={"#1571DE"}
            required
            disabled={disableInput}
            isError={
              !disableInput &&
              errorField &&
              !differentHouseFamilyContactRelationshipTemp
            }
            errorText={"*Hubungan belum diisi"}
          />
          <InputField
            title={"Nomor HP"}
            placeholder={"8***"}
            value={differentHouseFamilyContactPhoneNumber}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (
                e.target.value === "" ||
                (re.test(e.target.value) && e.target.value.startsWith("8"))
              ) {
                setDifferentHouseFamilyContactPhoneNumber(e.target.value);
              }
            }}
            floatingStartAdornment={
              <span className={Styles.floatingText}>+62</span>
            }
            required
            disabled={disableInput}
            isError={
              !disableInput &&
              errorField &&
              !differentHouseFamilyContactPhoneNumber
            }
            errorText={"*Nomor HP belum diisi"}
          />
        </div>
        <div className={Styles.formAddress}>
          <InputField
            title="Alamat "
            type={"address"}
            value={differentHouseFamilyContactAddress?.toUpperCase()}
            placeholder={"Tulis alamat pemilik kontak"}
            onChange={(e) =>
              setDifferentHouseFamilyContactAddress(
                e.target.value?.toUpperCase()
              )
            }
            hideIcon
            required
            disabled={disableInput}
            isError={
              !disableInput && errorField && !differentHouseFamilyContactAddress
            }
            errorText={"*Alamat belum diisi"}
          />
        </div>
      </div>

      <div className={Styles.section}>
        <span>Data Lainnya</span>
        <div className={Styles.formSection}>
          <AutocompleteField
            title={"Bank"}
            placeholder={"Pilih bank"}
            searchOptions={options?.banks}
            value={bankNameTemp}
            setValue={setBankNameTemp}
            iconColor={"#1571DE"}
            required
            disabled
            isError={!disableInput && errorField && !bankNameTemp}
            errorText={"*Nama Bank belum diisi"}
          />
          <InputField
            title={"Nomor Rekening"}
            placeholder={"000"}
            value={bankAccountNumber}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === "" || re.test(e.target.value)) {
                setBankAccountNumber(e.target.value);
              }
            }}
            required
            disabled
            isError={!disableInput && errorField && !bankAccountNumber}
            errorText={"*Nomor belum diisi"}
          />
          <InputField
            title={"Kode RO"}
            placeholder={"000"}
            value={roCode}
            onChange={(e) => setRoCode(e.target.value)}
            required
            // readOnly
            disabled
          />
          <InputField
            title={"Klinik/Puskesmas Terdekat"}
            placeholder={"Tulis nama klinik/puskesmas"}
            value={nearestHealthFacility?.toUpperCase()}
            onChange={(e) =>
              setNearestHealthFacility(e.target.value?.toUpperCase())
            }
            required
            disabled={disableInput}
            isError={!disableInput && errorField && !nearestHealthFacility}
            errorText={"*Faskes belum diisi"}
          />
        </div>
        <div className={Styles.desc}>Sosial Media</div>
        <div className={Styles.formSection}>
          <InputField
            title={"Facebook"}
            placeholder={"Usename"}
            value={fbUsername}
            onChange={(e) => setFbUsername(e.target.value)}
            disabled={disableInput}
          />
          <InputField
            title={"Instagram"}
            placeholder={"Usename"}
            value={igUsername}
            onChange={(e) => setIgUsername(e.target.value)}
            disabled={disableInput}
          />
          <InputField
            title={"X"}
            placeholder={"Usename"}
            value={twitterUsername}
            onChange={(e) => setTwitterUsername(e.target.value)}
            disabled={disableInput}
          />
          <InputField
            title={"Linkedin"}
            placeholder={"Usename"}
            value={linkedinUsername}
            onChange={(e) => setLinkedinUsername(e.target.value)}
            disabled={disableInput}
          />
        </div>
      </div>
    </div>
  );
}
