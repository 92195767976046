import AutocompleteV2 from "@Atom/AutocompleteV2";
import CheckboxJobPosting from "@Atom/CheckboxJobPosting";
import Input from "@Atom/Input";
import { capitalizedFirstLetter } from "@Helpers/capitalizedFirstLetter";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Styles from "./style.module.scss";

const FilterSlider = function ({
  show,
  type,
  onOutsideClick = () => {},
  dataSync = null,
  selectedButtonStepContract,
  // setStageFilter,
  // setSearchFilter,
  setClientFilter = () => {},
  setRoSearchFilter = () => {},
  setStatusFilter = () => {},
  statusFilter,
  setRegionFilter = () => {},
  regionFilter,
  clientFilter,
  roSearchFilter,
  placementFilter,
  setPlacementFilter = () => {},
  positionNameFilter,
  setPossitionNameFilter = () => {},
  isLoadingSync,
  selectedClient,
  setSelecetedClient = () => {},
  selectedRo,
  setSelectedRo = () => {},
  placementObj,
  setPlacementObj = () => {},
}) {
  const ref = useRef();
  // const query = useQuery();
  // const navigate = useNavigate();
  // const { pathname } = useLocation();

  // --- CANDIDATE LIST ---
  const [clientSearch, setClienteSearch] = useState("");
  const [optionClient, setOptionClient] = useState(null);

  const [roSearch, setRoSearch] = useState("");
  const [optionRo, setOptionRo] = useState(null);

  const [status, setStatus] = useState([]);
  const [region, setRegion] = useState([]);

  const [placement, setPlacement] = useState("");
  const [optionPlacement, setOptionPlacement] = useState(null);

  const [positionName, setPositionName] = useState("");

  const handleClickOutside = useCallback(
    (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onOutsideClick();
      }
    },
    [onOutsideClick]
  );

  useEffect(() => {
    setStatus(statusFilter || []);
    setRegion(regionFilter || []);
    setClienteSearch(clientFilter || "");
    setRoSearch(roSearchFilter || "");
    setPlacement(placementFilter || "");
    setPositionName(positionNameFilter || "");
    setOptionClient(selectedClient || null);
    setOptionRo(selectedRo || null);
    setOptionPlacement(placementObj || null);
  }, [
    statusFilter,
    regionFilter,
    clientFilter,
    roSearchFilter,
    placementFilter,
    positionNameFilter,
    selectedClient,
    selectedRo,
    placementObj,
  ]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const statusOptions = useMemo(() => {
    if (type === "adendum") {
      if (selectedButtonStepContract === "Menunggu Approval BM") {
        return [
          {
            title: "Menunggu Approval BM",
            query: "Menunggu Approval BM",
            disabled: false,
            readOnly: false,
          },
          {
            title: "Perbaikan Kontrak",
            query: "Perbaikan Kontrak",
            disabled: false,
            readOnly: false,
          },
        ];
      } else if (selectedButtonStepContract === "TTD Kontrak") {
        return [];
      } else if (selectedButtonStepContract === "Verifikasi Kedua") {
        return [
          {
            title: "Menunggu Verifikasi",
            query: "Menunggu Verifikasi",
            disabled: false,
            readOnly: false,
          },
          {
            title: "Perbaikan Data",
            query: "Perbaikan Data",
            disabled: false,
            readOnly: false,
          },
          {
            title: "Gagal Inject",
            query: "Gagal Inject",
            disabled: false,
            readOnly: false,
          },
        ];
      } else if (selectedButtonStepContract === "Joined") {
        return [];
      }
    } else {
      if (selectedButtonStepContract === "Verifikasi Pertama") {
        return [
          {
            title: "Menunggu Verifikasi",
            query: "Menunggu Verifikasi",
            disabled: false,
            readOnly: false,
          },
          {
            title: "Perbaikan Data",
            query: "Perbaikan Data",
            disabled: false,
            readOnly: false,
          },
        ];
      } else if (selectedButtonStepContract === "Generate Kontrak") {
        return [
          {
            title: "Belum Generate",
            query: "Belum Generate",
            disabled: false,
            readOnly: false,
          },
          {
            title: "Menunggu Approval BM",
            query: "Menunggu Approval BM",
            disabled: false,
            readOnly: false,
          },
          {
            title: "Perbaikan Kontrak",
            query: "Perbaikan Kontrak",
            disabled: false,
            readOnly: false,
          },
        ];
      } else if (selectedButtonStepContract === "TTD Kontrak") {
        return [
          {
            title: "TTD Kontrak",
            query: "TTD Kontrak",
            disabled: true,
            readOnly: true,
          },
        ];
      } else if (selectedButtonStepContract === "Verifikasi Kedua") {
        return [
          {
            title: "Gagal Inject",
            query: "Gagal Inject",
            disabled: false,
            readOnly: false,
          },
          {
            title: "Menunggu Verifikasi",
            query: "Menunggu Verifikasi",
            disabled: false,
            readOnly: false,
          },
          {
            title: "Perbaikan Data",
            query: "Perbaikan Data",
            disabled: false,
            readOnly: false,
          },
        ];
      } else {
        return [
          {
            title: "Joined",
            query: "Joined",
            disabled: true,
            readOnly: true,
          },
        ];
      }
    }
  }, [selectedButtonStepContract, type]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const regionOptions = [
    {
      title: "HEAD OFFICE",
      query: "HEAD OFFICE",
      disabled: false,
      readOnly: false,
    },
    {
      title: "JAKARTA 1",
      query: "JAKARTA 1",
      disabled: false,
      readOnly: false,
    },
    {
      title: "JAKARTA 2",
      query: "JAKARTA 2",
      disabled: false,
      readOnly: false,
    },
    {
      title: "JAKARTA 3",
      query: "JAKARTA 3",
      disabled: false,
      readOnly: false,
    },
    {
      title: "JAKARTA 4",
      query: "JAKARTA 4",
      disabled: false,
      readOnly: false,
    },
    {
      title: "JAKARTA 5",
      query: "JAKARTA 5",
      disabled: false,
      readOnly: false,
    },
    {
      title: "JAKARTA 6",
      query: "JAKARTA 6",
      disabled: false,
      readOnly: false,
    },
    {
      title: "JAWA TIMUR",
      query: "JAWA TIMUR",
      disabled: false,
      readOnly: false,
    },
    {
      title: "JAWA TENGAH",
      query: "JAWA TENGAH",
      disabled: false,
      readOnly: false,
    },
    {
      title: "JAWA BARAT",
      query: "JAWA BARAT",
      disabled: false,
      readOnly: false,
    },
    {
      title: "KALIMANTAN",
      query: "KALIMANTAN",
      disabled: false,
      readOnly: false,
    },
    {
      title: "SUMATERA",
      query: "SUMATERA",
      disabled: false,
      readOnly: false,
    },
    {
      title: "SULAMPA",
      query: "SULAMPA",
      disabled: false,
      readOnly: false,
    },
    {
      title: "BALI NUSRA",
      query: "BALI NUSRA",
      disabled: false,
      readOnly: false,
    },
    // {
    //     title: 'REGIONAL TESTING',
    //     query: 'REGIONAL TESTING',
    //     disabled: false,
    //     readOnly: false,
    // },
  ];

  // const stageOptions = useMemo(() => {
  //   return [
  //     {
  //       title: "Registered",
  //       query: "REGISTER",
  //       disabled: status !== "BS-REGISTERED" && status !== "DECLINED",
  //       readOnly: status === "BS-REGISTERED",
  //     },
  //     {
  //       title: "Verifikasi Pertama",
  //       query: "FIRST-VERIFICATION",
  //       disabled: status !== "BS-PRE-CONTRACT" && status !== "DECLINED",
  //     },
  //     {
  //       title: "Verifikasi Kedua",
  //       query: "SECOND-VERIFICATION",
  //       disabled: status !== "BS-PRE-CONTRACT" && status !== "DECLINED",
  //     },
  //     {
  //       title: "Generate Kontrak",
  //       query: "GENERATE-CONTRACT",
  //       disabled: status !== "BS-PRE-CONTRACT" && status !== "DECLINED",
  //     },
  //     {
  //       title: "Tanda Tangan Kontrak",
  //       query: "SIGN-CONTRACT",
  //       disabled: status !== "BS-PRE-CONTRACT" && status !== "DECLINED",
  //     },
  //     {
  //       title: "Selesai & Masuk ke HR System",
  //       query: "DONE",
  //       disabled: status !== "BS-CONTRACT" && status !== "DECLINED",
  //       readOnly: status === "BS-CONTRACT",
  //     },
  //   ];
  // }, [status]);

  const handleSave = useCallback(() => {
    switch (type) {
      case "candidate":
        setClientFilter(clientSearch || "");
        setRoSearchFilter(roSearch || "");
        setStatusFilter(status || []);
        setPlacementFilter(placement || "");
        setPossitionNameFilter(positionName || "");
        setPlacementObj(optionPlacement || null);
        setSelecetedClient(optionClient || null);
        setSelectedRo(optionRo || null);
        onOutsideClick();
        break;
      case "extend":
        setClientFilter(clientSearch || "");
        setRoSearchFilter(roSearch || "");
        setStatusFilter(status || []);
        setRegionFilter(region || []);
        setPlacementFilter(placement || "");
        setPossitionNameFilter(positionName || "");
        setPlacementObj(optionPlacement || null);
        setSelecetedClient(optionClient || null);
        setSelectedRo(optionRo || null);
        onOutsideClick();
        break;
      case "completeextend":
        setClientFilter(clientSearch || "");
        // setRoSearchFilter(roSearch || "");
        // setStatusFilter(status || []);
        setRegionFilter(region || []);
        // setPlacementFilter(placement || "");
        // setPossitionNameFilter(positionName || "");
        // setPlacementObj(optionPlacement || null);
        setSelecetedClient(optionClient || null);
        // setSelectedRo(optionRo || null);
        onOutsideClick();
        break;
      case "adendum":
        setClientFilter(clientSearch || "");
        setPossitionNameFilter(positionName || "");
        setSelecetedClient(optionClient || null);
        setStatusFilter(status || []);
        setRegionFilter(region || []);
        onOutsideClick();
        break;
      default:
        return false;
    }
  }, [
    type,
    setClientFilter,
    clientSearch,
    setRoSearchFilter,
    roSearch,
    setStatusFilter,
    status,
    setRegionFilter,
    region,
    setPlacementFilter,
    placement,
    setPossitionNameFilter,
    positionName,
    setPlacementObj,
    optionPlacement,
    setSelecetedClient,
    optionClient,
    onOutsideClick,
    setSelectedRo,
    optionRo,
  ]);

  const template = useMemo(() => {
    switch (type) {
      case "candidate":
        return [
          {
            title: "Nama Klien",
            component: (
              <AutocompleteV2
                value={clientSearch}
                setValue={setClienteSearch}
                setSelected={setOptionClient}
                // onChange={(e) => setRoSearch(e?.target?.value)}
                placeholder={"Nama Klien"}
                options={dataSync?.clients || []}
                type={"recruitment"}
                isLoading={isLoadingSync}
              />
            ),
          },
          {
            title: "Recruitment Officer",
            component: (
              <AutocompleteV2
                value={roSearch}
                setValue={setRoSearch}
                // onChange={(e) => setRoSearch(e?.target?.value)}
                placeholder={"Recruitment officer"}
                options={dataSync?.recruitmentOfficers || []}
                isLoading={isLoadingSync}
                type={"recruitment"}
                setSelected={setOptionRo}
              />
            ),
            // <></>
          },
          {
            title: "Posisi",
            component: (
              <Input
                value={positionName}
                onChange={(e) => setPositionName(e?.target?.value)}
                placeholder={"Posisi"}
              />
            ),
          },
          {
            title: "Lokasi Kerja",
            component: (
              <AutocompleteV2
                value={placement}
                setValue={setPlacement}
                // onChange={(e) => setRoSearch(e?.target?.value)}
                placeholder={"Lokasi kerja"}
                options={dataSync?.cities || []}
                type={"recruitment"}
                setSelected={setOptionPlacement}
                isLoading={isLoadingSync}
              />
            ),
            // <></>
          },
          {
            title: "Status",
            component: (
              <div className={Styles.statusOptions}>
                {statusOptions?.map((each, i) => (
                  <div key={i}>
                    <CheckboxJobPosting
                      // type="radio-section"
                      checked={
                        status?.find((obj) => obj === each?.query)
                          ? true
                          : false
                      }
                      onChange={() => {
                        const found = status?.find(
                          (obj) => obj === each?.query
                        );
                        if (found) {
                          setStatus(
                            status?.filter((obj) => obj !== each?.query)
                          );
                        } else {
                          setStatus([...status, each?.query]);
                        }
                      }}
                      readOnly={each?.readOnly}
                      disabled={each?.disabled}
                    />
                    <span>{capitalizedFirstLetter(each?.title)}</span>
                  </div>
                ))}
              </div>
            ),
          },
        ];
      case "extend":
        return [
          {
            title: "Nama Klien",
            component: (
              <AutocompleteV2
                value={clientSearch}
                setValue={setClienteSearch}
                setSelected={setOptionClient}
                // onChange={(e) => setRoSearch(e?.target?.value)}
                placeholder={"Nama Klien"}
                options={dataSync?.clients || []}
                type={"recruitment"}
                isLoading={isLoadingSync}
              />
            ),
          },
          {
            title: "Posisi",
            component: (
              <Input
                value={positionName}
                onChange={(e) => setPositionName(e?.target?.value)}
                placeholder={"Posisi"}
              />
            ),
          },
          {
            title: "Region",
            component: (
              <div className={Styles.regionOptions}>
                {regionOptions?.map((each, i) => (
                  <div key={i}>
                    <CheckboxJobPosting
                      // type="radio-section"
                      checked={
                        region?.find((obj) => obj === each?.query)
                          ? true
                          : false
                      }
                      onChange={() => {
                        const found = region?.find(
                          (obj) => obj === each?.query
                        );
                        if (found) {
                          setRegion(
                            region?.filter((obj) => obj !== each?.query)
                          );
                        } else {
                          setRegion([...region, each?.query]);
                        }
                      }}
                      readOnly={each?.readOnly}
                      disabled={each?.disabled}
                    />
                    <span>{capitalizedFirstLetter(each?.title)}</span>
                  </div>
                ))}
              </div>
            ),
          },
          {
            title: "Status",
            component: (
              <div className={Styles.statusOptions}>
                {statusOptions?.map((each, i) => (
                  <div key={i}>
                    <CheckboxJobPosting
                      // type="radio-section"
                      checked={
                        status?.find((obj) => obj === each?.query)
                          ? true
                          : false
                      }
                      onChange={() => {
                        const found = status?.find(
                          (obj) => obj === each?.query
                        );
                        if (found) {
                          setStatus(
                            status?.filter((obj) => obj !== each?.query)
                          );
                        } else {
                          setStatus([...status, each?.query]);
                        }
                      }}
                      readOnly={each?.readOnly}
                      disabled={each?.disabled}
                    />
                    <span>{capitalizedFirstLetter(each?.title)}</span>
                  </div>
                ))}
              </div>
            ),
          },
        ];
      case "completeextend":
        return [
          {
            title: "Nama Klien",
            component: (
              <AutocompleteV2
                value={clientSearch}
                setValue={setClienteSearch}
                setSelected={setOptionClient}
                // onChange={(e) => setRoSearch(e?.target?.value)}
                placeholder={"Nama Klien"}
                options={dataSync?.clients || []}
                type={"recruitment"}
                isLoading={isLoadingSync}
              />
            ),
          },
          {
            title: "Region",
            component: (
              <div className={Styles.regionOptions}>
                {regionOptions?.map((each, i) => (
                  <div key={i}>
                    <CheckboxJobPosting
                      // type="radio-section"
                      checked={
                        region?.find((obj) => obj === each?.query)
                          ? true
                          : false
                      }
                      onChange={() => {
                        const found = region?.find(
                          (obj) => obj === each?.query
                        );
                        if (found) {
                          setRegion(
                            region?.filter((obj) => obj !== each?.query)
                          );
                        } else {
                          setRegion([...region, each?.query]);
                        }
                      }}
                      readOnly={each?.readOnly}
                      disabled={each?.disabled}
                    />
                    <span>{capitalizedFirstLetter(each?.title)}</span>
                  </div>
                ))}
              </div>
            ),
          },
        ];
      case "adendum":
        if (statusOptions?.length > 0) {
          return [
            {
              title: "Nama Klien",
              component: (
                <AutocompleteV2
                  value={clientSearch}
                  setValue={setClienteSearch}
                  setSelected={setOptionClient}
                  // onChange={(e) => setRoSearch(e?.target?.value)}
                  placeholder={"Nama Klien"}
                  options={[
                    {
                      name: "tasa",
                      code: "tasa 23",
                    },
                  ]}
                  type={"recruitment"}
                  isLoading={false}
                />
              ),
            },
            {
              title: "Posisi",
              component: (
                <Input
                  value={positionName}
                  onChange={(e) => setPositionName(e?.target?.value)}
                  placeholder={"Posisi"}
                />
              ),
            },
            {
              title: "Region",
              component: (
                <div className={Styles.regionOptions}>
                  {regionOptions?.map((each, i) => (
                    <div key={i}>
                      <CheckboxJobPosting
                        // type="radio-section"
                        checked={
                          region?.find((obj) => obj === each?.query)
                            ? true
                            : false
                        }
                        onChange={() => {
                          const found = region?.find(
                            (obj) => obj === each?.query
                          );
                          if (found) {
                            setRegion(
                              region?.filter((obj) => obj !== each?.query)
                            );
                          } else {
                            setRegion([...region, each?.query]);
                          }
                        }}
                        readOnly={each?.readOnly}
                        disabled={each?.disabled}
                      />
                      <span>{capitalizedFirstLetter(each?.title)}</span>
                    </div>
                  ))}
                </div>
              ),
            },
            {
              title: "Status",
              component: (
                <div className={Styles.statusOptions}>
                  {statusOptions?.map((each, i) => (
                    <div key={i}>
                      <CheckboxJobPosting
                        // type="radio-section"
                        checked={
                          status?.find((obj) => obj === each?.query)
                            ? true
                            : false
                        }
                        onChange={() => {
                          const found = status?.find(
                            (obj) => obj === each?.query
                          );
                          if (found) {
                            setStatus(
                              status?.filter((obj) => obj !== each?.query)
                            );
                          } else {
                            setStatus([...status, each?.query]);
                          }
                        }}
                        readOnly={each?.readOnly}
                        disabled={each?.disabled}
                      />
                      <span>{capitalizedFirstLetter(each?.title)}</span>
                    </div>
                  ))}
                </div>
              ),
            },
          ];
        } else {
          return [
            {
              title: "Nama Klien",
              component: (
                <AutocompleteV2
                  value={clientSearch}
                  setValue={setClienteSearch}
                  setSelected={setOptionClient}
                  // onChange={(e) => setRoSearch(e?.target?.value)}
                  placeholder={"Nama Klien"}
                  options={[]}
                  type={"recruitment"}
                  isLoading={false}
                />
              ),
            },
            {
              title: "Posisi",
              component: (
                <Input
                  value={positionName}
                  onChange={(e) => setPositionName(e?.target?.value)}
                  placeholder={"Posisi"}
                />
              ),
            },
            {
              title: "Region",
              component: (
                <div className={Styles.regionOptions}>
                  {regionOptions?.map((each, i) => (
                    <div key={i}>
                      <CheckboxJobPosting
                        // type="radio-section"
                        checked={
                          region?.find((obj) => obj === each?.query)
                            ? true
                            : false
                        }
                        onChange={() => {
                          const found = region?.find(
                            (obj) => obj === each?.query
                          );
                          if (found) {
                            setRegion(
                              region?.filter((obj) => obj !== each?.query)
                            );
                          } else {
                            setRegion([...region, each?.query]);
                          }
                        }}
                        readOnly={each?.readOnly}
                        disabled={each?.disabled}
                      />
                      <span>{capitalizedFirstLetter(each?.title)}</span>
                    </div>
                  ))}
                </div>
              ),
            },
          ];
        }

      default:
        return [];
    }
  }, [
    clientSearch,
    dataSync,
    roSearch,
    status,
    statusOptions,
    region,
    regionOptions,
    type,
    placement,
    positionName,
    isLoadingSync,
  ]);

  return (
    <div ref={ref} className={`${Styles.container} ${show ? Styles.show : ""}`}>
      <div className={Styles.header}>
        <h3>Filter</h3>
        {statusFilter?.length > 0 ||
        regionFilter?.length > 0 ||
        clientFilter ||
        roSearchFilter ||
        placementFilter ||
        positionNameFilter ? (
          <span
            onClick={() => {
              setStatusFilter([]);
              setRegionFilter([]);
              setClientFilter("");
              setRoSearchFilter("");
              setPlacementFilter("");
              setPossitionNameFilter("");
              setSelecetedClient(null);
              setSelectedRo(null);
              setPlacementObj(null);
              onOutsideClick();
            }}
          >
            Reset
          </span>
        ) : null}
      </div>
      {template?.map((each, i) => (
        <div key={i} className={Styles.each}>
          <span>{each?.title}</span>
          {each?.component}
        </div>
      ))}

      <div className={Styles.footer}>
        <button onClick={handleSave}>Terapkan</button>
      </div>
    </div>
  );
};

export default FilterSlider;
