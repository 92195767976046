import { useEffect, useMemo, useState } from "react";
import Styles from "./styles.module.scss";
import SearchBar from "@Atom/SearchBar";
import CustomTable from "@Molecule/CustomTable";
import Images from "@Theme/Images";
import { useLocation, useNavigate } from "react-router-dom";
import useQuery from "@Hooks/useQuery";
import { useDebounce } from "@Hooks/useDebounce";
import moment from "moment";
import { fileBaseUrl } from "@Config/api";

export default function CancelProcessList({ sync, data }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const query = useQuery();
  const [searchName, setSearchName] = useState(query?.get("n") || "");
  const [valueIdCandiate, setValueIdCandidate] = useState(
    query?.get("id") || query?.get("j")
      ? {
          name: query?.get("n") || "",
          ExpectedPosition: {
            name: query?.get("j") || "",
          },
          id: +query?.get("id") || "",
        }
      : null
  );
  const [limit, setLimit] = useState(+query?.get("l") || 10);
  const [page, setPage] = useState(+query?.get("p") || 1);

  const debouncedSearch = useDebounce(searchName, 500);

  // console.log(data, "test");

  useEffect(() => {
    navigate(
      `${pathname}?l=${limit || 10}&p=${page || 1}&id=${
        valueIdCandiate?.id || ""
      }&n=${debouncedSearch}&j=${valueIdCandiate?.ExpectedPosition?.name || ""}`
    );
  }, [navigate, pathname, limit, page, debouncedSearch, valueIdCandiate]);

  const candidatesTemplate = useMemo(() => {
    return {
      data:
        data?.data?.length > 0
          ? data?.data?.map((el) => {
              return {
                id: el?.id,
                name: el?.name || null,
                phoneNumber: el?.phoneNumber || null,
                client: el?.ExpectedPosition?.OrderScheme?.Client?.name || null,
                position: el?.ExpectedPosition?.name || "-",
                typeContract: el?.ExpectedPosition?.contractType || "-",
                cancelDate: el.canceledAt
                  ? moment(new Date(el?.canceledAt)).format("DD MMMM YYYY")
                  : null,
                employeementStatus: el?.employeementStatus || null,
                step: el?.step || null,
                candidateDocuments: el?.CandidateDocuments?.url
                  ? fileBaseUrl + el?.CandidateDocuments?.url
                  : null,
              };
            })
          : [],
      columns: [
        {
          name: "name",
          label: "Nama",
          renderData: (row) => (
            <div className={Styles.profileBoxRow}>
              <div className={Styles.images}>
                <img
                  src={
                    row?.candidateDocuments
                      ? row?.candidateDocuments
                      : Images.AVA_DEFAULT
                  }
                  alt=""
                />
              </div>
              <div className={Styles.profile}>
                <span>{row?.name || "-"}</span>
                <span>{row?.phoneNumber || "-"}</span>
              </div>
            </div>
          ),
        },
        {
          name: "client",
          label: "Klien",
          renderData: (row) => (
            <div className={Styles.clientBoxRow}>{row?.client || "-"}</div>
          ),
        },
        {
          name: "position",
          label: "Posisi",
        },
        {
          name: "typeContract",
          label: "Tipe Kontrak",
        },
        {
          name: "cancelDate",
          label: "Tgl Dibatalkan",
        },
        {
          name: "lastProcess",
          label: "Proses Terakhir",
          renderData: (row) => (
            <div className={Styles.lastPositionRow}>
              {row?.employeementStatus?.toLowerCase() === "extend"
                ? "Proses Perubahan"
                : "Proses New Join"}{" "}
              {row?.step && "-"} {row?.step || ""}
            </div>
          ),
        },
      ],
    };
  }, [data]);

  useEffect(() => {
    const previousPage = page;
    if (data?.totalPage === 1 || data?.totalPage === 0) {
      setPage(1);
    } else {
      const validPage = Math.max(1, Math.min(previousPage, data?.totalPage));
      setPage(validPage);
    }
  }, [page, data]);

  return (
    <div className={Styles.container}>
      <div className={Styles.stickyTop}>
        <div className={Styles.header}>
          <span>Daftar Proses Kandidat/TKO yang DIbatalkan</span>

          <div className={Styles.searchbar}>
            <SearchBar
              placeholder={"Cari Nama Kandidat"}
              value={searchName}
              onChange={setSearchName}
              isRecruitment={true}
              isBS={true}
              options={sync || []}
              type={"none"}
              isLoadingFilter={query?.get("n") !== searchName}
              onClickOption={() => {}}
              setValueIdCandidate={setValueIdCandidate}
              valueIdCandidate={valueIdCandiate}
            />
          </div>
        </div>
      </div>
      <div className={Styles.tableWrapper}>
        <CustomTable
          data={candidatesTemplate?.data || []}
          columns={candidatesTemplate?.columns}
          value={limit}
          setValue={setLimit}
          selectedPage={page}
          setSelectedPage={setPage}
          totalPage={data?.totalPage || 1}
          isV2
          noContainerBorder
          isLoading={
            +query?.get("l") !== +limit ||
            +query?.get("p") !== +page ||
            (valueIdCandiate && +valueIdCandiate?.id !== +query.get("id"))
          }
          // activeController={stage ? null : (status || 'BS-REGISTERED')}
          // setActiveController={setStatus}
          // searchTerm={search}
          // setSearchTerm={setSearch}
          // noStickyPagination={windowSize[0] <= 480 ? true : false}
          stickyTop
          totalData={data?.totalData || 0}
          isBs={true}
        />
      </div>
    </div>
  );
}
