/* eslint-disable no-unused-vars */
import Icon from "@Atom/Icon";
import PinMaps from "@Atom/Maps";
import ModalWrapper from "@Atom/ModalWrapper";
import { fileBaseUrl, fileBaseUrlC2C } from "@Config/api";
import convertHtmlToPdf from "@Helpers/convertHtmlToPdf";
import Switcher from "@Molecule/Switcher";
import VerificationButtons from "@Molecule/VerificationButtons";
import SecondVerificationModal from "@Molecule/_modal/BusinessSupport/SecondVerificationModal";
import NotesRemarkDec from "@Molecule/_modal/NotesRemarkDecline";
import PreviewImageModal from "@Molecule/_modal/PreviewImageModal";
import { getExtendCandidateDetail } from "@Services/support/candidate";
import Images from "@Theme/Images";
import moment from "moment";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import Styles from "./styles.module.scss";

const PDFJS = window.pdfjsLib;

function useIsInViewport(ref) {
  const [isIntersecting, setIsIntersecting] = useState(false);

  const observer = useMemo(
    () =>
      new IntersectionObserver(([entry]) =>
        setIsIntersecting(entry?.isIntersecting)
      ),
    []
  );

  useEffect(() => {
    observer?.observe(ref.current);

    return () => {
      observer?.disconnect();
    };
  }, [ref, observer]);

  return isIntersecting;
}

export default function PendingContractDetailLayout({
  setShowAccept,
  setShowReject,
  defaultData,
  trigger,
  setTrigger,
  stage,
  setStage,
  defaultSync,
  setCandidateId,
}) {
  const { employeeId } = useParams();
  const [data, setData] = useState(defaultData);
  const [sync, setSync] = useState(defaultSync);
  const [showSecondVerification, setShowSecondVerification] = useState(false);

  useEffect(() => {
    // setStage(data?.stage);
    setCandidateId(data?.id);
  }, [data, setCandidateId]);

  const refetchData = useCallback(async () => {
    if (employeeId) {
      const { response } = await getExtendCandidateDetail(employeeId);
      setData(response);
    }
  }, [employeeId]);

  useEffect(() => {
    refetchData();
  }, [refetchData, trigger]);

  return (
    <DetailsMode
      setShowAccept={setShowAccept}
      setShowReject={setShowReject}
      data={data}
      sync={sync}
      showSecondVerification={showSecondVerification}
      setShowSecondVerification={setShowSecondVerification}
      setTrigger={setTrigger}
    />
  );
}

const DetailsMode = ({
  setShowAccept,
  setShowReject,
  data,
  sync,
  showSecondVerification,
  setShowSecondVerification,
  setTrigger,
}) => {
  const [mobileMode, setMobileMode] = useState("details");
  const detailsTemplate = useMemo(() => {
    return [
      {
        title: "Personal",
        details: [
          {
            name: "Application ID",
            value: data?.ContractDocument?.employeeId,
          },
          {
            name: "No. Kartu Keluarga",
            value: data?.CandidateComprehensive?.familyId,
          },
          {
            name: "No. KTP",
            value: data?.CandidateComprehensive?.personId,
          },
          {
            name: "Masa berlaku",
            value: data?.CandidateComprehensive?.personIdPeriod?.includes("-")
              ? moment(
                  new Date(data?.CandidateComprehensive?.personIdPeriod)
                )?.format("DD-MM-YYYY")
              : data?.CandidateComprehensive?.personIdPeriod,
          },
          {
            name: "Nama",
            value: data?.name,
          },
          {
            name: "Tempat lahir",
            value: data?.CandidateComprehensive?.placeOfBirth,
          },
          {
            name: "Tanggal lahir",
            value: data?.CandidateComprehensive?.dateOfBirth
              ? moment(
                  new Date(data?.CandidateComprehensive?.dateOfBirth)
                )?.format("DD-MM-YYYY")
              : "-",
          },
          {
            name: "Jenis kelamin",
            value: data?.CandidateComprehensive?.gender,
          },
          {
            name: "Nama ibu",
            value: data?.CandidateComprehensive?.motherName,
          },
          {
            name: "Agama",
            value: data?.CandidateComprehensive?.religion,
          },
          {
            name: "Status pernikahan",
            value: data?.CandidateComprehensive?.maritalStatus,
          },
          {
            name: "No. Handphone",
            value: data?.CandidateComprehensive?.phoneNumber,
          },
          {
            name: "Email",
            value: data?.CandidateComprehensive?.email,
          },
          {
            name: "Golongan darah",
            value: data?.CandidateComprehensive?.bloodType,
          },
          {
            name: "Tinggi badan",
            value: data?.CandidateComprehensive?.bodyHeight,
          },
          {
            name: "Berat badan",
            value: data?.CandidateComprehensive?.bodyWeight,
          },
        ],
      },
      {
        title: "Alamat KTP",
        details: [
          {
            name: "Alamat",
            value: data?.CandidateComprehensive?.address,
          },
          {
            name: "RT",
            value: data?.CandidateComprehensive?.neighborhood
              ? data?.CandidateComprehensive?.neighborhood?.split("/")[0]
              : "",
          },
          {
            name: "RW",
            value: data?.CandidateComprehensive?.neighborhood
              ? data?.CandidateComprehensive?.neighborhood?.split("/")[1]
              : "",
          },
          {
            name: "Kelurahan",
            value: data?.CandidateComprehensive?.subDistrict,
          },
          {
            name: "Kecamatan",
            value: data?.CandidateComprehensive?.district,
          },
          {
            name: "Kota",
            value: data?.CandidateComprehensive?.city,
          },
          {
            name: "Provinsi",
            value: data?.CandidateComprehensive?.province,
          },
          {
            name: "Kode POS",
            value: data?.CandidateComprehensive?.postal,
          },
        ],
      },
      {
        title: "Alamat Domisili",
        details: [
          {
            name: "Alamat",
            value: data?.CandidateComprehensive?.domicileAddress,
          },
          {
            name: "RT",
            value: data?.CandidateComprehensive?.domicileNeighbourhood
              ? data?.CandidateComprehensive?.domicileNeighbourhood?.split(
                  "/"
                )[0]
              : "",
          },
          {
            name: "RW",
            value: data?.CandidateComprehensive?.domicileNeighbourhood
              ? data?.CandidateComprehensive?.domicileNeighbourhood?.split(
                  "/"
                )[1]
              : "",
          },
          {
            name: "Kelurahan",
            value: data?.CandidateComprehensive?.domicileSubDistrict,
          },
          {
            name: "Kecamatan",
            value: data?.CandidateComprehensive?.domicileDistrict,
          },
          {
            name: "Kota",
            value: data?.CandidateComprehensive?.domicileCity,
          },
          {
            name: "Provinsi",
            value: data?.CandidateComprehensive?.domicileProvince,
          },
          {
            name: "Kode POS",
            value: data?.CandidateComprehensive?.domicilePostal,
          },
        ],
      },
      {
        title: "Pendidikan",
        details: [
          {
            name: "Pendidikan terakhir",
            value: data?.CandidateComprehensive?.lastEducationType,
          },
          {
            name: "Nama Sekolah / Universitas",
            value: data?.CandidateComprehensive?.lastEducationName,
          },
          {
            name: "Fakultas / Jurusan",
            value: data?.CandidateComprehensive?.lastEducationFaculty,
          },
          {
            name: "IPK",
            value: data?.CandidateComprehensive?.lastEducationGrade,
          },
        ],
      },
      {
        title: "Kontak Darurat",
        details: [
          {
            name: "Nama",
            value: data?.CandidateComprehensive?.emergencyContactName,
          },
          {
            name: "Hubungan",
            value: data?.CandidateComprehensive?.emergencyContactRelation,
          },
          {
            name: "Telp / HP",
            value: data?.CandidateComprehensive?.emergencyContactNumber,
          },
        ],
      },
      {
        title: "Kontak Serumah",
        details: [
          {
            name: "Nama",
            value:
              data?.CandidateFamilyMembers?.length > 0 &&
              data?.CandidateFamilyMembers[1]
                ? data?.CandidateFamilyMembers[1]?.name
                : "-",
          },
          {
            name: "Hubungan",
            value:
              data?.CandidateFamilyMembers?.length > 0 &&
              data?.CandidateFamilyMembers[1]
                ? data?.CandidateFamilyMembers[1].relation
                : "-",
          },
          {
            name: "Telp / HP",
            value:
              data?.CandidateFamilyMembers?.length > 0 &&
              data?.CandidateFamilyMembers[1]
                ? data?.CandidateFamilyMembers[1].phoneNumber
                : "-",
          },
        ],
      },
      {
        title: "Kontak Tidak Serumah",
        details: [
          {
            name: "Nama",
            value:
              data?.CandidateFamilyMembers?.length > 0 &&
              data?.CandidateFamilyMembers[2]
                ? data?.CandidateFamilyMembers[2]?.name
                : "-",
          },
          {
            name: "Hubungan",
            value:
              data?.CandidateFamilyMembers?.length > 0 &&
              data?.CandidateFamilyMembers[2]
                ? data?.CandidateFamilyMembers[2].relation
                : "-",
          },
          {
            name: "Telp / HP",
            value:
              data?.CandidateFamilyMembers?.length > 0 &&
              data?.CandidateFamilyMembers[2]
                ? data?.CandidateFamilyMembers[2].phoneNumber
                : "-",
          },
        ],
      },
      {
        title: "Others",
        details: [
          {
            name: "RO code",
            value: data?.recruiterID,
          },
          {
            name: "Nama bank",
            value: data?.CandidateComprehensive?.BankName,
          },
          {
            name: "No. Rekening",
            value: data?.CandidateComprehensive?.BankNumber,
          },
          {
            name: "No. NPWP",
            value: data?.CandidateComprehensive?.taxId,
          },
          {
            name: "Status pajak",
            value: "-",
          },
          {
            name: "Catatan",
            value: "-",
          },
          {
            name: "Eligible RO Incentive",
            value: "-",
          },
        ],
      },
      {
        title: "Sosial Media",
        details: [
          {
            name: "Facebook",
            value: data?.CandidateComprehensive?.facebook || "-",
          },
          {
            name: "Twitter",
            value: data?.CandidateComprehensive?.twitter || "-",
          },
          {
            name: "Instagram",
            value: data?.CandidateComprehensive?.instagram || "-",
          },
          {
            name: "Linkedin",
            value: data?.CandidateComprehensive?.linkedin || "-",
          },
        ],
      },
    ];
  }, [data]);

  const [pdfUrl, setPdfUrl] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [loadingPdf, setLoadingPdf] = useState(false);

  // console.log(data, "sync candidate detail kali");

  useEffect(() => {
    const getPdfUrl = async () => {
      setLoadingPdf(true);
      const res = await convertHtmlToPdf(
        data?.ContractDocument?.template,
        "CONTRACT",
        {
          ...data,
          ...data.ContractDocument,
          position: data?.ExpectedPosition?.name,
          image: data?.ContractDocument?.image
            ? fileBaseUrl + data?.ContractDocument?.image
            : "",
          initials: data?.ContractDocument?.initials
            ? fileBaseUrl + data?.ContractDocument?.initials
            : "",
          signature: data?.ContractDocument?.signature
            ? fileBaseUrl + data?.ContractDocument?.signature
            : "",
          contractType: data?.ExpectedPosition?.contractType,
        }
      );
      setPdfUrl(res);
      setLoadingPdf(false);
    };
    if (data?.ContractDocument?.template) {
      getPdfUrl();
    }
  }, [data, data?.ContractDocument?.template, sync]);

  const ref = useRef(null);
  const ref2 = useRef(null);

  const isInViewPort = useIsInViewport(ref);
  const isInViewPort2 = useIsInViewport(ref2);

  const [height, setHeight] = useState(0);
  const elementRef = useRef(null);

  const activeRef = useRef();
  const inactiveRef = useRef();

  const isActiveInViewPort = useIsInViewport(activeRef);

  useLayoutEffect(() => {
    const handleResize = () => {
      setHeight(elementRef.current.offsetHeight);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {}, [isInViewPort, isInViewPort2, isActiveInViewPort]);

  const [activeSwitch, setActiveSwitch] = useState("Dokumen");

  const switcherOptions = [
    {
      title: "Dokumen",
      onSwitch: () => {
        setActiveSwitch("Dokumen");
      },
    },
    {
      title: "Kontrak",
      onSwitch: () => {
        setActiveSwitch("Kontrak");
      },
    },
  ];

  const images = useMemo(() => {
    const arr = [];
    if (data?.CandidateDocuments?.length) {
      const filtered = data?.CandidateDocuments?.filter(
        (obj) =>
          !obj?.url?.toLowerCase().includes("pdf-pdf") &&
          !obj?.url?.toLowerCase().includes(".pdf")
      );

      for (let i = 0; i < filtered?.length; i++) {
        arr.push({
          id: filtered[i].id,
          type: filtered[i].type,
          src:
            filtered[i]?.source?.toLowerCase() === "c2c"
              ? fileBaseUrlC2C + filtered[i].url
              : filtered[i].url?.includes("http")
              ? filtered[i].url
              : fileBaseUrl + filtered[i].url,
          url:
            filtered[i]?.source?.toLowerCase() === "c2c"
              ? fileBaseUrlC2C + filtered[i].url
              : filtered[i].url?.includes("http")
              ? filtered[i].url
              : fileBaseUrl + filtered[i].url,
        });
      }
    }

    return arr;
  }, [data, fileBaseUrl, fileBaseUrlC2C]);

  const [images2, setImages2] = useState([]);

  const allImages = useMemo(() => {
    return images.concat(images2);
  }, [images, images2]);

  useEffect(() => {
    const arr = [];
    if (data?.CandidateDocuments?.length) {
      const filtered = data?.CandidateDocuments?.filter(
        (obj) =>
          obj?.url?.toLowerCase().includes("pdf-pdf") ||
          obj?.url?.toLowerCase().includes(".pdf")
      );
      for (let i = 0; i < filtered?.length; i++) {
        const each = {
          id: filtered[i].id,
          type: filtered[i].type,
          url:
            filtered[i]?.source?.toLowerCase() === "c2c"
              ? fileBaseUrlC2C + filtered[i].url
              : filtered[i].url?.includes("http")
              ? filtered[i].url
              : fileBaseUrl + filtered[i].url,
          // src: innerArr[i]
        };
        const url =
          filtered[i]?.source?.toLowerCase() === "c2c"
            ? fileBaseUrlC2C + filtered[i].url
            : filtered[i].url?.includes("http")
            ? filtered[i].url
            : fileBaseUrl + filtered[i].url;
        if (url) {
          const loadDefaultImages = async () => {
            const imagesArr = [];
            if (url === undefined) {
              imagesArr.push(null);
            } else {
              const uri = url;
              var pdf = await PDFJS.getDocument({ url: uri }).promise;
              const canvas = document.createElement("canvas");
              for (let i = 0; i < pdf.numPages; i++) {
                const page = await pdf.getPage(i + 1);
                const viewport = page.getViewport({ scale: 1 });
                const context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                await page.render({
                  canvasContext: context,
                  viewport: viewport,
                }).promise;
                imagesArr.push(canvas.toDataURL());
              }
              canvas.remove();
            }
            const first = imagesArr?.length ? imagesArr[0] : "";
            each.src = first;
          };
          loadDefaultImages();
        } else {
          // setImages2([])
        }
        arr.push(each);
      }
    }
    setImages2(arr);
  }, [data, fileBaseUrl, fileBaseUrlC2C]);

  const [images3, setImages3] = useState([]);

  useEffect(() => {
    const arr = [];
    if (data?.CandidateDocuments?.length) {
      const filtered = data?.CandidateDocuments?.filter(
        (obj) =>
          obj?.url?.toLowerCase().includes("pdf-pdf") ||
          obj?.url?.toLowerCase().includes(".pdf")
      );
      for (let i = 0; i < filtered?.length; i++) {
        const each = {
          id: filtered[i].id,
          type: filtered[i].type,
          images: [],
          // src: innerArr[i]
        };
        const url =
          filtered[i]?.source?.toLowerCase() === "c2c"
            ? fileBaseUrlC2C + filtered[i].url
            : filtered[i].url?.includes("http")
            ? filtered[i].url
            : fileBaseUrl + filtered[i].url;
        if (url) {
          const loadDefaultImages = async () => {
            const imagesArr = [];
            if (url === undefined) {
              imagesArr.push(null);
            } else {
              const uri = url;
              var pdf = await PDFJS.getDocument({ url: uri }).promise;
              const canvas = document.createElement("canvas");
              for (let i = 0; i < pdf.numPages; i++) {
                const page = await pdf.getPage(i + 1);
                const viewport = page.getViewport({ scale: 1 });
                const context = canvas.getContext("2d");
                canvas.height = viewport.height;
                canvas.width = viewport.width;
                await page.render({
                  canvasContext: context,
                  viewport: viewport,
                }).promise;
                imagesArr.push(canvas.toDataURL());
              }
              canvas.remove();
            }
            each.images = imagesArr;
          };
          loadDefaultImages();
        } else {
          // setImages2([])
        }
        arr.push(each);
      }
    }
    setImages3(arr);
  }, [data, fileBaseUrlC2C, fileBaseUrl]);

  const spreadedImages3 = useMemo(() => {
    const arr = [];
    for (let i = 0; i < images3?.length; i++) {
      let each = {
        id: images3[i]?.id,
        type: images3[i].type,
      };
      // const imagesArr = images
      if (images3[i]?.images) {
        for (let j = 0; j < images3[i]?.images?.length; j++) {
          each.src = images3?.images[j];
          arr.push(each);
        }
      }
    }
    return arr;
  }, [images3]);

  useEffect(() => {}, [spreadedImages3]);

  const [translateAmount, setTranslateAmount] = useState(0);

  const [selectedImage, setSelectedImage] = useState(
    allImages?.length ? allImages[0] : null
  );
  const selectedImageIndex = allImages.findIndex(
    (i) => i.id === selectedImage?.id
  );

  const onNextImage = () => {
    if (allImages[selectedImageIndex + 1]) {
      setSelectedImage(allImages[selectedImageIndex + 1]);
      if (!isInViewPort2) {
        setTranslateAmount(translateAmount - 100);
      }
    }
    // else {
    //   setSelectedImage(images[0])
    //   setTranslateAmount(0)
    // }
  };

  const onPreviousImage = () => {
    if (allImages[selectedImageIndex - 1]) {
      setSelectedImage(allImages[selectedImageIndex - 1]);
      if (translateAmount < 0) {
        setTranslateAmount(translateAmount + 100);
      }
    }
    //  else {
    //   setSelectedImage(images[images?.length - 1])
    //   setTranslateAmount(0)
    // }
  };

  let imageAngle = 0;

  const rotateImage = () => {
    imageAngle += 90;
    var div = document.getElementById("image"),
      angle = imageAngle;
    if (div && div.style) {
      div.style.webkitTransform = "rotate(" + angle + "deg)";
      div.style.mozTransform = "rotate(" + angle + "deg)";
      div.style.msTransform = "rotate(" + angle + "deg)";
      div.style.oTransform = "rotate(" + angle + "deg)";
      div.style.transform = "rotate(" + angle + "deg)";
    }
    // rotated = !rotated;
  };

  const resetRotation = useCallback(() => {
    var div = document.getElementById("image"),
      angle = 0;
    if (div && div.style) {
      div.style.webkitTransform = "rotate(" + angle + "deg)";
      div.style.mozTransform = "rotate(" + angle + "deg)";
      div.style.msTransform = "rotate(" + angle + "deg)";
      div.style.oTransform = "rotate(" + angle + "deg)";
      div.style.transform = "rotate(" + angle + "deg)";
    }
  }, []);

  useEffect(() => {
    resetRotation();
  }, [resetRotation, selectedImage?.src]);

  const [showImageModal, setShowImageModal] = useState(false);

  // console.log(data);
  const maxLength = 165;
  const [showNotes, setShowNotes] = useState(false);

  return (
    <div className={Styles.container}>
      <div
        className={`${Styles.left} ${
          mobileMode !== "details" ? Styles.hide : ""
        }`}
      >
        <div className={Styles.stickyTop}>
          <div className={Styles.profile} ref={elementRef}>
            <img src={Images.AVA_DEFAULT} alt="" />
            <div>
              <span>{data?.name}</span>
              <span>
                {data?.phoneNumber} • {data?.recruiterID}
              </span>
            </div>
            <button
              onClick={() =>
                mobileMode === "details"
                  ? setMobileMode("documents")
                  : setMobileMode("details")
              }
            >
              <Icon
                icon={
                  mobileMode === "details"
                    ? "chevron-double-right"
                    : "chevron-double-left"
                }
                size={24}
              />
            </button>
          </div>
          <div className={Styles.detailsHeader}>
            <span>Information</span>
            <span>Details</span>
          </div>
        </div>

        <div
          className={`${Styles.detailsContent}  ${
            mobileMode !== "details" ? Styles.hide : ""
          }`}
        >
          {detailsTemplate?.map((perType, i) => (
            <div key={i} className={Styles.perType}>
              <span>{perType?.title}</span>
              <div className={Styles.list}>
                {perType?.details?.map((detail, j) => (
                  <div key={j} className={Styles.perDetail}>
                    <span>{detail?.name}</span>
                    <span>
                      {detail?.value && detail?.value !== "undefined"
                        ? detail?.value
                        : "-"}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          ))}

          <div ref={ref} style={{ height: "1px", background: "transparent" }} />
          {!isInViewPort && <div className={Styles.gradientback} />}
          {/* {} */}
        </div>
        {data?.remark === null || data?.remark === "" ? null : (
          <div className={Styles.remarkBox}>
            <div className={Styles.titleNotes}>
              <Icon icon={"notes-bs"} size={20} />
              <span className={Styles.catatan}>Catatan:</span>
            </div>
            <div className={Styles.contentNotes}>
              <span className={Styles.textP}>
                {data?.remark
                  ? data?.remark?.length > maxLength
                    ? data?.remark?.substring(0, maxLength) + "..."
                    : data?.remark
                  : "-"}
              </span>
              {data?.remark?.length > maxLength ? (
                <span
                  className={Styles.detailText}
                  onClick={() => setShowNotes(true)}
                >
                  Selengkapnya
                </span>
              ) : null}
            </div>
          </div>
        )}
      </div>
      {showImageModal ? (
        <div
          className={`${Styles.previewWrapper} ${Styles.right}`}
          style={{ display: showImageModal ? "block" : "none" }}
        >
          {/* <ModalWrapper show={showImageModal} handleClose={() => setShowImageModal(false)} forceCenter> */}
          <PreviewImageModal
            url={selectedImage?.url}
            onClose={() => setShowImageModal(false)}
          />
          {/* </ModalWrapper> */}
        </div>
      ) : (
        <div
          className={`${Styles.right} ${
            mobileMode === "details" ? Styles.hide : ""
          }`}
        >
          <div
            className={Styles.previewWrapper}
            style={{ display: showImageModal ? "block" : "none" }}
          >
            {/* <ModalWrapper show={showImageModal} handleClose={() => setShowImageModal(false)} forceCenter> */}
            <PreviewImageModal
              url={selectedImage?.url}
              onClose={() => setShowImageModal(false)}
            />
            {/* </ModalWrapper> */}
          </div>
          <div className={Styles.top} style={{ height: `${height}px` }}>
            <div className={Styles.profile}>
              <img src={Images.AVA_DEFAULT} alt="" />
              <div>
                <span>{data?.name}</span>
                <span>
                  {data?.phoneNumber} • {data?.recruiterID}
                </span>
              </div>
              <button
                onClick={() =>
                  mobileMode === "details"
                    ? setMobileMode("documents")
                    : setMobileMode("details")
                }
              >
                <Icon
                  icon={
                    mobileMode === "details"
                      ? "chevron-double-right"
                      : "chevron-double-left"
                  }
                  size={24}
                />
              </button>
            </div>
            <div>
              <VerificationButtons
                verificationDates={["", ""]}
                verificationStatus={[
                  // data?.stage !== "REGISTER" ? true : false,
                  true,
                  data?.stage === "SECOND-VERIFICATION" ||
                  data?.stage === "IOS-SUCCESS"
                    ? true
                    : false,
                ]}
                onVerifyActions={[() => {}, () => {}]}
                setShowAccept={() => {
                  setShowSecondVerification(true);
                }}
                setShowReject={setShowReject}
                tag={data?.tag}
                data={data?.remark}
                secondVerifyAvailable={
                  data?.ContractDocument?.signature &&
                  data?.stage !== "IOS-SUCCESS"
                    ? true
                    : false
                }
                stage={data?.stage}
                type={"extend"}
              />
            </div>
          </div>
          <div className={Styles.bottom}>
            <div>
              <Switcher
                options={switcherOptions}
                activeSwitchTitle={activeSwitch}
              />
            </div>

            <div className={Styles.main}>
              {activeSwitch === "Dokumen" ? (
                allImages?.length ? (
                  <div className={Styles.docWrapper}>
                    <div className={Styles.mainDoc}>
                      <button
                        onClick={onPreviousImage}
                        className={`${Styles.controlButton} ${Styles.prev}`}
                      >
                        <Icon icon={"arrow-left"} size={24} />
                      </button>
                      <img id="image" src={selectedImage?.src} alt="" />
                      <button
                        onClick={onNextImage}
                        className={`${Styles.controlButton} ${Styles.next}`}
                      >
                        <Icon icon={"arrow-right"} size={24} />
                      </button>
                      <div className={Styles.imageButtons}>
                        <div
                          className={Styles.button}
                          onClick={() => rotateImage()}
                        >
                          <img src={Images.ROTATE} alt="" />
                        </div>
                        <button
                          className={Styles.button}
                          onClick={() => {
                            setShowImageModal(true);
                          }}
                        >
                          <Icon icon={"frame"} size={24} />
                        </button>
                      </div>
                    </div>
                    {selectedImage && (
                      <div className={Styles.type}>{selectedImage?.type}</div>
                    )}
                    <div className={Styles.sliderWrapper}>
                      <div
                        className={Styles.slider}
                        style={{
                          transform: `translateX(${translateAmount}px)`,
                          width: `calc(${translateAmount}px * -1 + 100%)`,
                        }}
                      >
                        {!allImages?.length && (
                          <div>
                            <div ref={activeRef} />
                            <div ref={inactiveRef} />
                          </div>
                        )}
                        {allImages?.map((image, i) => (
                          <div
                            key={i}
                            // className={Styles.thumbnail}
                            className={`${Styles.thumbnail} ${
                              image?.id === selectedImage?.id
                                ? Styles.active
                                : ""
                            }`}
                          >
                            <img
                              src={image?.src}
                              alt=""
                              ref={
                                image?.id === selectedImage?.id
                                  ? activeRef
                                  : inactiveRef
                              }
                              onClick={() => setSelectedImage(image)}
                            />
                          </div>
                        ))}
                        <div ref={ref2} className={Styles.parameter}>
                          p
                        </div>
                        {!isInViewPort2 && (
                          <div className={`${Styles.verticalGradient}`}></div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className={Styles.noDoc}>
                    <span>No Document Available</span>

                    <div ref={activeRef} />
                    <div ref={inactiveRef} />
                    <div ref={ref2} />
                  </div>
                )
              ) : data?.ContractDocument?.signature && pdfUrl ? (
                <div className={Styles.DocumentContainer}>
                  <div className={Styles.pdfWrapper}>
                    <embed
                      src={`${pdfUrl}#navpanes=0`}
                      type="application/pdf"
                    ></embed>
                  </div>
                  <div className={Styles.SignContainer}>
                    <div className={Styles.SignWrapper}>
                      <p>Signature</p>
                      <div className={Styles.Sign}>
                        <img
                          src={fileBaseUrl + data?.ContractDocument?.signature}
                          alt="signature"
                        />
                      </div>
                    </div>
                    <div className={Styles.SignWrapper}>
                      <p>Inital</p>
                      <div className={Styles.Sign}>
                        <img
                          src={fileBaseUrl + data?.ContractDocument?.initials}
                          alt="signature"
                        />
                      </div>
                    </div>
                    <div className={Styles.SignWrapper}>
                      <p>Foto Selfie</p>
                      <img
                        src={fileBaseUrl + data?.ContractDocument?.image}
                        alt="profile"
                      />
                    </div>

                    {data?.ContractDocument?.imageLatitude &&
                      data?.ContractDocument?.imageLongitude && (
                        <div className={Styles.SignWrapper}>
                          <p className={Styles.SectionTitle}>
                            Lokasi Foto Selfie
                          </p>
                          <PinMaps
                            lat={+data?.ContractDocument?.imageLatitude}
                            lng={+data?.ContractDocument?.imageLongitude}
                            mapHeight={"145px"}
                            mapWidth={"100%"}
                          />
                        </div>
                      )}

                    {data?.ContractDocument?.identityCardImage && (
                      <div className={Styles.SignWrapper}>
                        <p>Foto KTP</p>
                        <img
                          src={
                            fileBaseUrl +
                            data?.ContractDocument?.identityCardImage
                          }
                          alt="profile"
                        />
                      </div>
                    )}

                    {data?.ContractDocument?.identityCardLatitude &&
                      data?.ContractDocument?.identityCardLongitude && (
                        <div className={Styles.SignWrapper}>
                          <p className={Styles.SectionTitle}>Lokasi Foto KTP</p>
                          <PinMaps
                            lat={+data?.ContractDocument?.identityCardLatitude}
                            lng={+data?.ContractDocument?.identityCardLongitude}
                            mapHeight={"145px"}
                            mapWidth={"100%"}
                          />
                        </div>
                      )}

                    {data?.ContractDocument?.latitude &&
                      data?.ContractDocument?.longitude && (
                        <div className={Styles.SignWrapper}>
                          <p className={Styles.SectionTitle}>
                            Lokasi TTD Kontrak
                          </p>
                          <PinMaps
                            lat={+data?.ContractDocument?.latitude}
                            lng={+data?.ContractDocument?.longitude}
                            mapHeight={"145px"}
                            mapWidth={"100%"}
                          />
                        </div>
                      )}
                  </div>
                </div>
              ) : (
                <div className={Styles.contractWrapper}>
                  <div ref={ref2} />
                  <img src={Images.KONTRAK} alt="" />
                  <h4>Kontrak Belum Ada</h4>
                  <span>
                    Kontrak akan muncul ketika verifikasi kedua dilakukan
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {showSecondVerification && (
        <ModalWrapper
          show={showSecondVerification}
          handleClose={() => setShowSecondVerification(false)}
        >
          <SecondVerificationModal
            customID={data?.id}
            data={data}
            handleClose={() => setShowSecondVerification(false)}
            setTrigger={setTrigger}
            type="EXTEND"
          />
        </ModalWrapper>
      )}

      {showNotes && (
        <NotesRemarkDec
          handleClose={() => setShowNotes(false)}
          handleDone={() => setShowNotes(false)}
          data={data?.remark || "-"}
        />
      )}
    </div>
  );
};
